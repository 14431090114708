<template>
  <div class="c">
    <!-- Button trigger modal -->
    <button type="button" id="openmodal" class="btn btn-primary" data-bs-toggle="modal" data-bs-backdrop="static" data-bs-keyboard="false" data-bs-target="#exampleModalLong">
    Launch demo modal
    </button>

    <!-- Modal -->
    <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content save-content">
            <h2 class="white-title white-title-save" style="width:100%;">Résiliation d'abonnement !</h2>
            <h4 class="white-title-child white-title-child-save">
                Etes-vous sûr de vouloir nous quitter? <br>
                Votre abonnement expirera à la fin de votre période d'abonnement, soit le {{date}}. <br>
                Un email vous sera envoyé dans quelques instants. Il contient un lien pour confirmer votre résiliation.
            </h4>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary save-cancel_btn" @click="closeModal" id="close_save" data-bs-dismiss="modal">Non</button>
                <button type="button" class="btn btn-primary save-valid_btn" @click.prevent="validateModal" data-bs-dismiss="modal">Oui</button>
            </div>
        </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            title: "",
            show: false,
        }
    },
    props: ['date'],
    mounted() {
        document.getElementById('openmodal').click()
    },
    methods: {
        closeModal() {
            this.$emit('closeModal')
        },
        validateModal() {
            this.$emit('validateModal')
        },
    }
}
</script>
<style>
#openmodal {
    display: none;
}
.white-title-child-save {
    font-size: 14px!important;
    padding: 8px 0 12px 0;
    position: absolute;
    top: 46px;
}
.save-content {
    padding: 17px;
    padding-left: 25px!important;
    padding-right: 25px!important;
}
.save-content .save-cancel_btn {
    margin-right: 15px;
    padding: 5px 24px;
    border-radius: 3px;
    border: solid 1px #00c1e2;
    background: #fff;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #373563;
    font-weight: 700;
}
.save-content .save-valid_btn {
    padding: 5px 24px;
    border-radius: 3px;
    background-color: #363660;
    font-family: Prompt;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    font-weight: 550;
}
.footer{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-right: 15px;
}
.modal-body-save{
    padding-bottom: 0; 
}
</style>
