<template>
  <div class="c">
    <!-- Button trigger modal -->
    <button type="button" id="openmodal2" class="btn btn-primary" data-bs-toggle="modal" data-bs-backdrop="static" data-bs-keyboard="false" data-bs-target="#exampleModalLong2">
    Launch demo modal
    </button>

    <!-- Modal -->
    <div class="modal fade" id="exampleModalLong2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content download-content">
        
        <div class="modal-body">
            <h2 class="white-title">Export de données</h2>
            <h4 class="white-title-child">
                Choisissez le type d’export que vous souhaitez exporter
            </h4>
            <form action="">
                <div class="form-group">
                    <VueSelect 
                        :multiple="false" 
                        :options="option_content" 
                        v-model="content"
                        :customIndicator="true"
                        placeholderTitle="Contenue:"
                        placeholderContent="Tableur / Tableur + graphique"
                    />
                </div>
                
                <div class="form-group">
                    <VueSelect 
                        :multiple="false" 
                        :options="option_format" 
                        v-model="format"
                        :customIndicator="true"
                        placeholderTitle="Format:"
                        placeholderContent="PDF /EXCEL"
                    />
                </div>
                
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary cancel_btn" id="close_down"  @click="closeModal" data-bs-dismiss="modal">Annuler</button>
            <button type="button" class="btn btn-primary valid_btn" v-if="content != null && format != null" @click="validateDown">Je valide</button>
            <button type="button" class="btn btn-primary valid_btn" disabled v-if="content == null || format == null" >Je valide</button>

        </div>
        </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
    components: {
       VueSelect: require('../template/VueSelect.vue').default
    },
    data(){
        return {
            option_format: [
                {
                title: 'PDF',
                value: 'pdf',
                checked: false
                },
                {
                title: 'Excel',
                value: 'excel',
                checked: false
                }
            ],
            format: null,
            option_content: [
                {
                title: 'Tableur',
                value: 'tableur',
                checked: false
                },
                {
                title: 'Tableur + graphique',
                value: 'tableur_graphique',
                checked: false
                }
            ],
            content: null
        }
    },
    mounted() {
        document.getElementById('openmodal2').click()
    },
    beforeDestroy() {
        console.log('destroyeed')
    },
    methods: {
        validateDown() {
            document.getElementById('close_down').click()
            
            this.$emit('validateModal', {
                content: this.content,
                format: this.format
            })    
        },
        closeModal() {
            this.$emit('closeModal')
        },
    }
}
</script>
<style>
#openmodal2 {
    display: none;
}
.download-content .white-title {
    margin: 0;
}
.download-content .white-title-child {
    font-size: 14px!important;
}
.download-content .cancel_btn {
padding: 4px 24px;
  border-radius: 3px;
  border: solid 1px #00c1e2;
  background: #fff;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373563;
}
.download-content .valid_btn {
    padding: 4px 24px;
  border-radius: 3px;
  background-color: #363660;
  font-family: Prompt;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.footer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-right: 15px;
}
.download-content .modal-body {
    padding-bottom: 0;
}
.vs__selected-options {
    padding-left: 5px;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    color: #363660;
        padding-left: 6px;
}
.vs__dropdown-toggle li {
    font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.33;
  letter-spacing: normal;
  color: #807f99;
}
.modal-content{
    padding: 10px;
}
.download-content .modal-footer {
    padding-top:0;
    padding-bottom:0;
    border:none;
}
.ph1 {
      font-family: Prompt;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4b45b2;
  margin-left: 5px;
}
.download-content select  {
        margin: 10px 0;
}
.download-content .select {
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>
