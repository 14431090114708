<template>
  <div>
  
    <div class="loader" v-if="loader == true">
        <div class="lds-ripple"><div></div><div></div></div>
    </div>
    
    <div class="loader" v-if="loadPdf == true">
        <div class="white-container" style="color:#fff;width:280px;left: 45%;height:auto;min-height:auto;color:#373563;top:40%;text-align:center;">La création du PDF peut prendre quelques secondes. <br/>Merci de bien vouloir patienter... <div class="lds-ring" id="md"><div></div><div></div><div></div><div></div></div> </div>
    </div>
    <div class="container-fluid" id="the-c">
        <div class="row" v-if="fiche == null">
            <div class="white-container wt-w">
                <h2 class="white-title">Analyse de marché</h2>
                <div class="form-floating mt-3">
                    <input
                    type="text"
                    class="form-control"
                    id="adresse-bis"
                    placeholder="Adresse"
                    value=""
                    autocomplete="off"
                    autofocus
                    @keyup="up"
                    @keydown="down"
                    v-model="adresse"
                    v-bind:class="adresseError == true ? 'is-invalid' : ''"
                    />
                    <label for="adresse">Adresse</label>
                    <button class="btn clear-field" v-if="adresse != '' && hasResult == true" @click="refresh"><i aria-hidden="true" class="fa fa-close"></i></button>
                    <button class="btn clear-field bis" v-if="adresse != ''  && hasResult == false" @click="refresh"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></button>

                    <section class="ps-container zip-autocomplete ps" v-bind:class="hasResult == true ? 'hide' : ''"> 
                        <ul v-if="zipResults.length > 0">
                            <li v-for="zip in zipResults" :key="zip.id" class="cursor" @click="getData(zip)">
                                <p>{{zip.zip}}</p>
                                <p>{{zip.city}}</p>
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
        <div class="row mt-3" v-if="fiche != null">
            <div class="col-md-7" v-if="fiche != null">
                <div class="white-container wt-c">
                    <h2 class="white-title">Analyse de marché</h2>
                    
                    <div class="row pe-0 ps-0">
                        <div class="col-md-9">
                            <div class="form-floating mt-3">
                                <input
                                type="text"
                                class="form-control"
                                id="adresse"
                                placeholder="Adresse"
                                value=""
                                autocomplete="off"
                                @keyup="up"
                                @keydown="down"
                                v-model="adresse"
                                v-bind:class="adresseError == true ? 'is-invalid' : ''"
                                />
                                <label for="adresse">Adresse</label>
                                <button class="btn clear-field" v-if="adresse != ''" @click="refresh"><i aria-hidden="true" class="fa fa-close"></i></button>
                                <section class="ps-container zip-autocomplete ps" v-bind:class="hasResult == true ? 'hide' : ''"> 
                                    <ul v-if="zipResults.length > 0">
                                        <li v-for="zip in zipResults" :key="zip.id" class="cursor" @click="getData(zip)">
                                            <p>{{zip.zip}}</p>
                                            <p>{{zip.city}}</p>
                                        </li>
                                    </ul>
                                </section>
                            </div>
                        </div>
                        <div class="col-md-3 pe-0 ps-0" v-if="fiche != null">
                            <div class="flex justify-center">
                                <span class="exp">Export PDF</span>
                                <span class="square cursor" @click="exportPdf">
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M5.85714 2.4165H13.7364C14.0911 2.4165 14.4343 2.54219 14.7051 2.77124L19.4687 6.80045C19.8057 7.08545 20 7.50438 20 7.94571V20.4998C20 22.2904 19.9796 22.4165 18.1429 22.4165H5.85714C4.02045 22.4165 4 22.2904 4 20.4998V4.33317C4 2.54263 4.02045 2.4165 5.85714 2.4165Z" fill="#00C1E2"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2691 13.0705C15.5443 12.7456 15.3133 12.2474 14.8875 12.2474H12.9311V10.2397C12.9311 9.9636 12.7072 9.73975 12.4311 9.73975H11.4078C11.1316 9.73975 10.9078 9.9636 10.9078 10.2397V12.2474L8.9513 12.2474C8.52555 12.2474 8.2946 12.7456 8.5697 13.0705L11.5378 16.5762C11.7375 16.812 12.1013 16.8121 12.301 16.5762L15.2691 13.0705Z" fill="#3D42DF"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>

                    <h3 class="small-title mt-4" v-if="fiche != null">Partie INSEE</h3>

                    <div v-if="fiche != null">
                        <div class="blue-sky">La population était de {{fiche.insee.nb_habitants}} habitants.</div>    
                        <div class="blue-sky">Médiane du revenu disponible par unité de consommation</div>                
                    </div>

                    <div class="row mt-3 jcenter" v-if="fiche != null">
                        <div class="block col-md-4">
                            <div class="svg">
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="31" cy="31" r="24" fill="#FFAA00"/>
                                    <circle cx="31" cy="31" r="30" stroke="#FFAA00"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M24 23C24 21.8954 24.8954 21 26 21H36C37.1046 21 38 21.8954 38 23V40H32.5V37C32.5 36.4477 32.0523 36 31.5 36H30.5C29.9477 36 29.5 36.4477 29.5 37V40H24V23ZM27 24C27 23.4477 27.4477 23 28 23H29C29.5523 23 30 23.4477 30 24V25C30 25.5523 29.5523 26 29 26H28C27.4477 26 27 25.5523 27 25V24ZM33 23C32.4477 23 32 23.4477 32 24V25C32 25.5523 32.4477 26 33 26H34C34.5523 26 35 25.5523 35 25V24C35 23.4477 34.5523 23 34 23H33ZM27 28C27 27.4477 27.4477 27 28 27H29C29.5523 27 30 27.4477 30 28V29C30 29.5523 29.5523 30 29 30H28C27.4477 30 27 29.5523 27 29V28ZM28 31C27.4477 31 27 31.4477 27 32V33C27 33.5523 27.4477 34 28 34H29C29.5523 34 30 33.5523 30 33V32C30 31.4477 29.5523 31 29 31H28ZM32 32C32 31.4477 32.4477 31 33 31H34C34.5523 31 35 31.4477 35 32V33C35 33.5523 34.5523 34 34 34H33C32.4477 34 32 33.5523 32 33V32Z" fill="#363660"/>
                                    <rect x="32" y="27" width="3" height="3" rx="1" fill="white"/>
                                    <path opacity="0.3" d="M22 41.9881C22 40.8901 22.8901 40 23.9881 40H38.0119C39.1099 40 40 40.8901 40 41.9881C40 41.9947 39.9947 42 39.9881 42H22.0119C22.0053 42 22 41.9947 22 41.9881Z" fill="#363660"/>
                                </svg>
                            </div>
                            <div class="ref">
                                {{fiche.insee.menages_fiscaux.com.mediane.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} €
                            </div>
                            <div class="type_ref">
                                Commune
                            </div>
                        </div>
                        <!--  -->
                        <div class="block col-md-4">
                            <div class="svg">
                                 <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="31" cy="31" r="24" fill="#FFAA00"/>
                                    <path opacity="0.3" d="M37 33C35.3431 33 34 31.6569 34 30C34 28.3431 35.3431 27 37 27C38.6569 27 40 28.3431 40 30C40 31.6569 38.6569 33 37 33Z" fill="#FF0000"/>
                                    <path opacity="0.3" d="M28 30C25.7909 30 24 28.2091 24 26C24 23.7909 25.7909 22 28 22C30.2091 22 32 23.7909 32 26C32 28.2091 30.2091 30 28 30Z" fill="#FF0000"/>
                                    <path d="M19.0007 39.1992C19.3883 34.4265 23.2619 32 27.9833 32C32.7712 32 36.7049 34.2932 36.9979 39.2C37.0096 39.3955 36.9979 40 36.2467 40C32.5411 40 27.0347 40 19.7275 40C19.4767 40 18.9795 39.4592 19.0007 39.1992Z" fill="#3D42DF"/>
                                    <circle cx="31" cy="31" r="30" stroke="#FFAA00"/>
                                </svg>
                            </div>
                            <div class="ref">
                                {{fiche.insee.menages_fiscaux.dep.mediane.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} €
                            </div>
                            <div class="type_ref">
                                Département
                            </div>
                        </div>
                        <!--  -->
                        <div class="block col-md-4">
                            <div class="svg">
                               <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="31" cy="31" r="24" fill="#FFAA00"/>
                                    <circle cx="31" cy="31" r="30" stroke="#FFAA00"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M32.1101 23.4347C32.1149 23.4079 32.1264 23.3829 32.1264 23.3542C32.1264 23.2306 32.0804 23.1184 32.0037 23.0341L31.1489 21.3244V20.4792C31.1489 20.2147 30.9342 20 30.6697 20C30.4052 20 30.1905 20.2147 30.1905 20.4792V21.3244L29.2829 23.1405C29.281 23.1443 29.282 23.1491 29.2801 23.1529C29.2513 23.2143 29.2321 23.2823 29.2321 23.3551C29.2321 24.9096 29.12 26.3365 28.9293 27.6676H32.409C32.2232 26.3595 32.1139 24.9574 32.1101 23.4347Z" fill="#363660"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.2046 42.2409L38.6488 41.4732C37.7259 40.2015 36.7369 38.8177 35.8083 37.2499H36.4197C36.6842 37.2499 36.8989 37.0352 36.8989 36.7707C36.8989 36.5062 36.6842 36.2915 36.4197 36.2915H25.3989C25.1344 36.2915 24.9197 36.5062 24.9197 36.7707C24.9197 37.0352 25.1344 37.2499 25.3989 37.2499H25.5302C24.6025 38.8187 23.6125 40.2015 22.6897 41.4732L22.1338 42.2409C22.0294 42.3865 22.015 42.5801 22.0964 42.7402C22.1779 42.9002 22.3437 42.9999 22.5239 42.9999H26.3831C26.5191 42.9999 26.6485 42.9414 26.7405 42.8408C26.8316 42.7392 26.8747 42.6041 26.8603 42.468C26.844 42.3281 26.8364 42.1853 26.8364 42.0415C26.8364 39.9275 28.5556 38.2082 30.6697 38.2082C32.7838 38.2082 34.503 39.9275 34.503 42.0415C34.503 42.1853 34.4953 42.3281 34.48 42.468C34.4647 42.6041 34.5088 42.7382 34.5998 42.8408C34.6909 42.9414 34.8202 42.9999 34.9573 42.9999H38.8164C38.9966 42.9999 39.1605 42.9002 39.2429 42.7402C39.3254 42.5801 39.3101 42.3865 39.2046 42.2409Z" fill="#363660"/>
                                    <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M28.8871 35.3333L29.7362 32.7861C29.8014 32.5906 29.9844 32.4583 30.1904 32.4583H31.1488C31.3548 32.4583 31.5378 32.5906 31.603 32.7861L32.4521 35.3333H34.7569C33.9385 33.6706 33.2494 31.7674 32.7722 29.5833H33.0645C33.329 29.5833 33.5437 29.3686 33.5437 29.1041C33.5437 28.8396 33.329 28.625 33.0645 28.625H28.2738C28.0093 28.625 27.7946 28.8397 27.7946 29.1042C27.7946 29.3687 28.0093 29.5834 28.2738 29.5834H28.5661C28.0898 31.7674 27.3998 33.6707 26.5814 35.3334H28.8871V35.3333Z" fill="#363660"/>
                                </svg>
                            </div>
                            <div class="ref">
                                {{fiche.insee.menages_fiscaux.france.mediane.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} €
                            </div>
                            <div class="type_ref">
                                France
                            </div>
                        </div>

                    </div>

                    <div class="mt-3" v-if="fiche != null">
                        <div class="blue-sky night">Taux de chômage est de {{fiche.insee.nb_chomage}}%</div>    
                        <div class="blue-sky night">Le nombre de logements était de {{fiche.insee.logement}} avec les répartitions suivantes :</div>                
                    </div>


                    <div class="row mt-3 jcenter" v-if="fiche != null">
                        <div class="block col-md-4">
                            <div class="svg">
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                    <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.5334 39.1756C28.5071 37.3783 24 32.9884 24 29.5C24 25 27 22 31.5 22C36 22 39 25.75 39 29.5C39 32.4266 34.432 37.1804 32.426 39.1269C31.8989 39.6383 31.0828 39.6629 30.5334 39.1756ZM31.5 31C32.8807 31 34 29.8807 34 28.5C34 27.1193 32.8807 26 31.5 26C30.1193 26 29 27.1193 29 28.5C29 29.8807 30.1193 31 31.5 31Z" fill="#3D42DF"/>
                                </svg>
                            </div>
                            <div class="ref">
                                {{fiche.insee.res_principale}} %
                            </div>
                            <div class="type_ref">
                                Res. principales
                            </div>
                        </div>
                        <!--  -->
                        <div class="block col-md-4">
                            <div class="svg">
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                    <path opacity="0.3" d="M25 39H23C23 36.2386 25.2386 35 28 35H34C36.7614 35 39 36.2386 39 39H37C37 37.3431 35.6569 37 34 37H28C26.3431 37 25 37.3431 25 39Z" fill="#FF0000"/>
                                    <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M42 27H40.1739C39.0693 27 38.1739 27.8954 38.1739 29V31C38.1739 31.5523 37.7262 32 37.1739 32H24.8696C24.3173 32 23.8696 31.5523 23.8696 31V29C23.8696 27.8954 22.9741 27 21.8696 27H20C20 25.3431 21.3431 24 23 24H39C40.6569 24 42 25.3431 42 27Z" fill="#FF0000"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M42 29V34C42 35.6569 40.6569 37 39 37H23C21.3431 37 20 35.6569 20 34V29H21.8696V31C21.8696 32.6569 23.2127 34 24.8696 34H37.1739C38.8308 34 40.1739 32.6569 40.1739 31V29H42Z" fill="#3D42DF"/>
                                    <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                </svg>
                            </div>
                            <div class="ref">
                                {{fiche.insee.res_secondaire}} %
                            </div>
                            <div class="type_ref">
                                Res. secondaires
                            </div>
                        </div>
                        <!--  -->
                        <div class="block col-md-4">
                            <div class="svg">
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                    <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                    <path opacity="0.3" d="M33.2929 35.7071C32.9024 35.3166 32.9024 34.6834 33.2929 34.2929C33.6834 33.9024 34.3166 33.9024 34.7071 34.2929L38.7071 38.2929C39.0976 38.6834 39.0976 39.3166 38.7071 39.7071C38.3166 40.0976 37.6834 40.0976 37.2929 39.7071L33.2929 35.7071Z" fill="#FF0000"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23 30C23 33.866 26.134 37 30 37C33.866 37 37 33.866 37 30C37 26.134 33.866 23 30 23C26.134 23 23 26.134 23 30ZM35 30C35 32.7614 32.7614 35 30 35C27.2386 35 25 32.7614 25 30C25 27.2386 27.2386 25 30 25C32.7614 25 35 27.2386 35 30Z" fill="#3D42DF"/>
                                </svg>

                            </div>
                            <div class="ref">
                                {{fiche.insee.logement_vacants}} %
                            </div>
                            <div class="type_ref">
                                Logement vacants
                            </div>
                        </div>

                    </div>
                    <!--  -->
                    <div class="row mt-3 jcenter" v-if="fiche != null">
                        <div class="block col-md-4">
                            <div class="svg">
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                    <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9571 27.415C22.3626 27.7783 22 28.4249 22 29.1216V37.9999C22 39.1045 22.8954 39.9999 24 39.9999H38C39.1046 39.9999 40 39.1045 40 37.9999V29.1216C40 28.4249 39.6374 27.7783 39.0429 27.415L31.5215 22.8186C31.2013 22.623 30.7987 22.623 30.4786 22.8186L22.9571 27.415ZM29 31.9999C28.4477 31.9999 28 32.4476 28 32.9999V35.9999C28 36.5522 28.4477 36.9999 29 36.9999H33C33.5523 36.9999 34 36.5522 34 35.9999V32.9999C34 32.4476 33.5523 31.9999 33 31.9999H29Z" fill="#3D42DF"/>
                                </svg>
                            </div>
                            <div class="ref">
                                {{fiche.insee.maison}} %
                            </div>
                            <div class="type_ref">
                                Maisons
                            </div>
                        </div>
                        <!--  -->
                        <div class="block col-md-4">
                            <div class="svg">
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M24 23C24 21.8954 24.8954 21 26 21H36C37.1046 21 38 21.8954 38 23V40H32.5V37C32.5 36.4477 32.0523 36 31.5 36H30.5C29.9477 36 29.5 36.4477 29.5 37V40H24V23ZM27 24C27 23.4477 27.4477 23 28 23H29C29.5523 23 30 23.4477 30 24V25C30 25.5523 29.5523 26 29 26H28C27.4477 26 27 25.5523 27 25V24ZM33 23C32.4477 23 32 23.4477 32 24V25C32 25.5523 32.4477 26 33 26H34C34.5523 26 35 25.5523 35 25V24C35 23.4477 34.5523 23 34 23H33ZM27 28C27 27.4477 27.4477 27 28 27H29C29.5523 27 30 27.4477 30 28V29C30 29.5523 29.5523 30 29 30H28C27.4477 30 27 29.5523 27 29V28ZM28 31C27.4477 31 27 31.4477 27 32V33C27 33.5523 27.4477 34 28 34H29C29.5523 34 30 33.5523 30 33V32C30 31.4477 29.5523 31 29 31H28ZM32 32C32 31.4477 32.4477 31 33 31H34C34.5523 31 35 31.4477 35 32V33C35 33.5523 34.5523 34 34 34H33C32.4477 34 32 33.5523 32 33V32Z" fill="#3D42DF"/>
                                    <rect x="32" y="27" width="3" height="3" rx="1" fill="white"/>
                                    <path opacity="0.3" d="M22 41.9881C22 40.8901 22.8901 40 23.9881 40H38.0119C39.1099 40 40 40.8901 40 41.9881C40 41.9947 39.9947 42 39.9881 42H22.0119C22.0053 42 22 41.9947 22 41.9881Z" fill="#FF0000"/>
                                    <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                </svg>

                            </div>
                            <div class="ref">
                                 {{Math.round(fiche.insee.appartement * 100) / 100}} %
                            </div>
                            <div class="type_ref">
                                Appartements
                            </div>
                        </div>
                        <!--  -->
                        <div class="block col-md-4">
                            <div class="svg">
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                    <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                    <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M30.366 29.8228L24 36.0002L24 39.0002L27 39.0002L27 37.0002L28.9418 37.0002V35.0002L31 35.0002L33.1845 32.6412L30.366 29.8228Z" fill="#FF0000"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.7426 32.2279C33.0857 34.5711 36.8847 34.5711 39.2279 32.2279C41.571 29.8848 41.571 26.0858 39.2279 23.7426C36.8847 21.3995 33.0857 21.3995 30.7426 23.7426C28.3994 26.0858 28.3994 29.8848 30.7426 32.2279ZM35.0606 28.182C35.6464 28.7678 36.5961 28.7678 37.1819 28.182C37.7677 27.5962 37.7677 26.6464 37.1819 26.0607C36.5961 25.4749 35.6464 25.4749 35.0606 26.0607C34.4748 26.6464 34.4748 27.5962 35.0606 28.182Z" fill="#3D42DF"/>
                                </svg>
                            </div>
                            <div class="ref">
                                {{fiche.insee.part_proprietaire}} %
                            </div>
                            <div class="type_ref">
                                Parts de propriétaire
                            </div>
                        </div>

                    </div>
                    <!--  -->
                    <div class="row mt-3 jcenter" v-if="fiche != null">
                        <div class="block col-md-4">
                            <div class="svg">
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                    <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                    <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M25.5425 40.5012C25.3996 41.0347 25.7161 41.583 26.2496 41.726C26.7831 41.8689 27.3314 41.5523 27.4744 41.0189L29.0725 35.0545C29.2155 34.521 28.8989 33.9727 28.3654 33.8297C27.8319 33.6868 27.2836 34.0034 27.1407 34.5368L25.5425 40.5012ZM36.4496 40.4709C36.5926 41.0044 36.276 41.5527 35.7425 41.6956C35.2091 41.8386 34.6607 41.522 34.5178 40.9885L33.2149 36.1261C33.0719 35.5926 33.3885 35.0443 33.922 34.9013C34.4555 34.7584 35.0038 35.075 35.1467 35.6084L36.4496 40.4709Z" fill="#FF0000"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3609 20C25.125 20 24.185 21.1097 24.3881 22.3288L25.9304 31.5822C25.9705 31.8233 26.1791 32 26.4236 32H35.5764C35.8209 32 36.0295 31.8233 36.0696 31.5822L37.6119 22.3288C37.815 21.1097 36.875 20 35.6391 20H26.3609ZM24.5 33C23.6716 33 23 33.6716 23 34.5C23 35.3284 23.6716 36 24.5 36H37.5C38.3284 36 39 35.3284 39 34.5C39 33.6716 38.3284 33 37.5 33H24.5Z" fill="#3D42DF"/>
                                </svg>
                            </div>
                            <div class="ref">
                                {{fiche.insee.part_locataire}} % 
                            </div>
                            <div class="type_ref">
                                Parts de locataires
                            </div>
                        </div>
                        <!--  -->
                    
                    </div>
                    

                </div>
            </div>


            <div class="col-md-5">
                <h2 class="nt-title" v-if="fiche != null">Marche Immobilier</h2>
                <div class="white-container wt-c" v-if="fiche != null"> 
                    <!--span class="lab" v-if="fiche.immobilier[0].commune.label != undefined">{{v[0].commune.label}}</span-->

                    <div v-for="(v,k) in fiche.immobilier" :key="k">
                        <div class="block bc-w mt-3">
                            <span class="btn-float">{{v.type}}</span>
                            <div class="svg">
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="v.type == 'appartement'">
                                    <circle cx="31" cy="31" r="24" fill="#4C46B2"/>
                                    <circle cx="31" cy="31" r="30" stroke="#4C46B2"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M24 23C24 21.8954 24.8954 21 26 21H36C37.1046 21 38 21.8954 38 23V40H32.5V37C32.5 36.4477 32.0523 36 31.5 36H30.5C29.9477 36 29.5 36.4477 29.5 37V40H24V23ZM27 24C27 23.4477 27.4477 23 28 23H29C29.5523 23 30 23.4477 30 24V25C30 25.5523 29.5523 26 29 26H28C27.4477 26 27 25.5523 27 25V24ZM33 23C32.4477 23 32 23.4477 32 24V25C32 25.5523 32.4477 26 33 26H34C34.5523 26 35 25.5523 35 25V24C35 23.4477 34.5523 23 34 23H33ZM27 28C27 27.4477 27.4477 27 28 27H29C29.5523 27 30 27.4477 30 28V29C30 29.5523 29.5523 30 29 30H28C27.4477 30 27 29.5523 27 29V28ZM28 31C27.4477 31 27 31.4477 27 32V33C27 33.5523 27.4477 34 28 34H29C29.5523 34 30 33.5523 30 33V32C30 31.4477 29.5523 31 29 31H28ZM32 32C32 31.4477 32.4477 31 33 31H34C34.5523 31 35 31.4477 35 32V33C35 33.5523 34.5523 34 34 34H33C32.4477 34 32 33.5523 32 33V32Z" fill="#00C1E2"/>
                                    <rect x="32" y="27" width="3" height="3" rx="1" fill="white"/>
                                    <path opacity="0.3" d="M22 41.9881C22 40.8901 22.8901 40 23.9881 40H38.0119C39.1099 40 40 40.8901 40 41.9881C40 41.9947 39.9947 42 39.9881 42H22.0119C22.0053 42 22 41.9947 22 41.9881Z" fill="white"/>
                                </svg>
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="v.type == 'maison'">
                                    <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                    <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9571 27.415C22.3626 27.7783 22 28.4249 22 29.1216V37.9999C22 39.1045 22.8954 39.9999 24 39.9999H38C39.1046 39.9999 40 39.1045 40 37.9999V29.1216C40 28.4249 39.6374 27.7783 39.0429 27.415L31.5215 22.8186C31.2013 22.623 30.7987 22.623 30.4786 22.8186L22.9571 27.415ZM29 31.9999C28.4477 31.9999 28 32.4476 28 32.9999V35.9999C28 36.5522 28.4477 36.9999 29 36.9999H33C33.5523 36.9999 34 36.5522 34 35.9999V32.9999C34 32.4476 33.5523 31.9999 33 31.9999H29Z" fill="#3D42DF"/>
                                </svg>
                            </div>
                            <div class="ref">
                                {{v.commune.prix_m2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} €
                                <span class="type_ref">
                                    Prix m² moyen
                                </span>
                            </div>
                            <div class="type_ref">
                                de {{v.commune.prix_range[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} € à {{v.commune.prix_range[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} €
                            </div>
                        </div>

                        <div class="mt-3">
                            <div class="blue-sky flex" style="justify-content:space-between;">
                                <span >
                                    Evolution du marché des {{v.type+'s'}}                
                                </span>
                                <span class="sky-t">
                                    1 an: {{v.commune.evol_1_an}} % <span style="height: 4px;width: 4px;display:inline-block;border-radius: 0px;background: #00C1E2;"></span> 5 ans: {{v.commune.evol_5_ans}} %
                                </span>
                            </div>    
                        </div>

                        <div class="mt-3 h-auto no-down">
                          <chart v-if="v.data_chart !== null" :dataChart="v.data_chart" :id="'chart' + Math.floor(Math.random() * 99)" :width="300" :height="222" suffix="au m²"></chart>
                        </div>
                        
                        <div class="mt-3 h-auto barA no-down">
                          <chart v-if="v.data_chart_evol !== null" :dataChart="v.data_chart_evol" :id="'chart' +Math.floor(Math.random() * 99 + Math.random() * 10)" :width="300" :height="222" ></chart>
                        </div>

                        <div class="mt-3 h-auto" style="display:none;">
                          <chart v-if="v.data_chart_evol_p1 !== null" :dataChart="v.data_chart_evol_p1" :id="'chart' +Math.floor(Math.random() * 10)" :width="300" :height="222" ></chart>
                        </div>

                    </div>
                    
                </div> 
            </div>
        </div>
    </div>
    <div 
        v-if="title != null"
        class="ps-0 pe-0 pt-0 pb-0"
        id="pdf"
        style="background-color:#fff;" 
     >
        <section class="row page page-1 ps-0 pe-0 pt-0 pb-0" id="page-1" style="background-color:#fff;min-height:600px;" >
            <div class="white-container wt-c ps-0 pe-0 pt-0 pb-0" aria-label="pdf-page-1" style="background-color:#fff;background-size: cover;min-height:600px;">
                <img src="../assets/Image1.png" class="img-responsive w100" style="max-height:1151px;object-fit:contain;"/>
            </div>
            <h2 class="white-title" style="padding-left:15px;margin-left:10px;width:100%;margin-top:10px;" v-if="title != null">{{title.zip}}</h2>
            <h2 class="white-title" style="padding-left:15px;margin-left:10px;width:100%;" v-if="title != null">{{title.city}}</h2>
            
            <h2 class="white-title" style="padding-right:15px;margin-right:10px;width:98%;margin-top:10px;text-align:right;">{{user.firstname}} {{user.lastname}}</h2>
            <h3 class="white-title" style="padding-right:15px;margin-right:10px;width:98%;text-align:right;font-size:15px;">{{user.address}}</h3>
            <h3 class="white-title" style="padding-right:15px;margin-right:10px;width:98%;text-align:right;font-size:15px;">Tel : {{user.phone}}</h3>
            <h3 class="white-title" style="padding-right:15px;margin-right:10px;width:98%;text-align:right;font-size:15px;">Email : {{user.society_email}}</h3>

            <div style="max-width: 200px;margin-left: 15px;margin-top:100px;">
              <svg width="96" height="20" viewBox="0 0 96 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M29.1769 19.5863H22.6219L30.0489 12.4L22.8585 5.42468H29.8096L33.5368 9.13427L37.264 5.42468H43.8657L36.7359 12.2959L44.1105 19.5863H37.4483L33.2837 15.5343L29.1769 19.5863Z" fill="#363660"/>
                <path d="M60.5295 19.5863H52.8633L42.6361 0H49.6119L56.8546 14.4L63.6488 0H70.047L60.5295 19.5863Z" fill="#363660"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.1766 19.1534C80.2329 18.6913 80.9811 18.1863 81.4212 17.6384C81.6247 18.937 82.3702 19.5826 83.6575 19.5753H88.4383V15.7397H87.6736C87.1784 15.7397 86.9336 15.5041 86.9336 15.0301V9.60548C86.9402 9.17379 86.9043 8.7425 86.8263 8.31781C86.7143 7.87832 86.5068 7.46869 86.2184 7.11781C85.8512 6.64152 85.3698 6.2647 84.8183 6.02192C83.9887 5.67525 83.1179 5.43586 82.2271 5.30959C80.8805 5.10051 79.5188 5.00337 78.1561 5.01918H76.7037C75.3876 5.00248 74.0729 5.11351 72.7784 5.35068C71.91 5.49123 71.0679 5.76187 70.2808 6.15342C69.7471 6.44094 69.2862 6.84568 68.9329 7.33699C68.6459 7.70718 68.4389 8.13254 68.325 8.5863C68.2497 8.99012 68.2138 9.40025 68.2177 9.81096V9.86301H73.6917C73.7017 9.7475 73.721 9.63298 73.7494 9.52055C73.8209 9.37613 73.9136 9.24316 74.0245 9.12603C74.177 8.93665 74.3773 8.79104 74.6049 8.70411C74.9566 8.57863 75.3195 8.48689 75.6887 8.43014C76.2604 8.33555 76.8394 8.29154 77.4189 8.29863H77.5509C78.3808 8.27842 79.2105 8.34453 80.0266 8.49589C80.607 8.63014 80.9563 8.76438 81.0691 8.90411C81.1892 9.06106 81.2505 9.25487 81.2424 9.45205C81.2424 9.87123 80.9426 10.1178 80.3429 10.189L73.8402 10.7945C71.7093 10.9881 70.1808 11.4183 69.2548 12.0849C68.3287 12.7516 67.8656 13.7342 67.8656 15.0329V15.2164C67.8502 16.5162 68.4531 17.7466 69.4913 18.5342C70.5751 19.411 72.2365 19.8493 74.4756 19.8493H74.8195C76.6679 19.8475 78.1203 19.6155 79.1766 19.1534ZM81.2644 12.8466V13.2959H81.2534C81.2607 14.1781 80.7793 14.9616 79.8093 15.6466C78.8392 16.3315 77.5968 16.674 76.082 16.674C75.1651 16.674 74.505 16.516 74.1015 16.2C73.727 15.943 73.5005 15.5214 73.4936 15.0685V15.0329C73.4741 14.6805 73.6591 14.3482 73.9695 14.1781C74.4938 13.9308 75.0566 13.7742 75.6337 13.7151L81.2644 12.8466Z" fill="#363660"/>
                <path d="M95.7387 0V19.5863H89.8439V0H95.7387Z" fill="#363660"/>
                <g style="mix-blend-mode:multiply">
                <path d="M56.8546 14.4L60.5296 19.5863L63.9707 12.5041L58.5518 10.8082L56.8546 14.4Z" fill="url(#paint0_linear)"/>
                </g>
                <rect width="20.7239" height="4.43288" fill="#363660"/>
                <rect y="15.1562" width="20.7239" height="4.43288" fill="#363660"/>
                <rect y="7.57806" width="20.7239" height="4.43288" fill="#363660"/>
                <rect x="17.7971" y="4.43286" width="4.45066" height="4.43288" transform="rotate(180 17.7971 4.43286)" fill="#50518E"/>
                <rect x="13.3492" y="4.43286" width="4.45066" height="4.43288" transform="rotate(180 13.3492 4.43286)" fill="#E94A73"/>
                <rect x="8.89856" y="4.43286" width="4.45066" height="4.43288" transform="rotate(180 8.89856 4.43286)" fill="#363660"/>
                <rect x="4.45068" y="4.43286" width="4.45066" height="4.43288" transform="rotate(180 4.45068 4.43286)" fill="#1D1D33"/>
                <rect y="7.57806" width="4.45066" height="4.43288" fill="#50518E"/>
                <rect x="4.45068" y="7.57806" width="4.45066" height="4.43288" fill="#4CBFDE"/>
                <rect x="8.89856" y="7.57806" width="4.45066" height="4.43288" fill="#363660"/>
                <rect x="13.3492" y="7.57806" width="4.45066" height="4.43288" fill="#1D1D33"/>
                <rect x="17.7971" y="19.5863" width="4.45066" height="4.43288" transform="rotate(180 17.7971 19.5863)" fill="#F7AC25"/>
                <rect x="13.3492" y="19.5863" width="4.45066" height="4.43288" transform="rotate(180 13.3492 19.5863)" fill="#3E3F71"/>
                <rect x="8.89856" y="19.5863" width="4.45066" height="4.43288" transform="rotate(180 8.89856 19.5863)" fill="#363660"/>
                <rect x="4.45068" y="19.5863" width="4.45066" height="4.43288" transform="rotate(180 4.45068 19.5863)" fill="#1D1D33"/>
                <defs>
                <linearGradient id="paint0_linear" x1="59.7643" y1="12.0799" x2="56.382" y2="14.1988" gradientUnits="userSpaceOnUse">
                <stop stop-opacity="0.01"/>
                <stop offset="1"/>
                </linearGradient>
                </defs>
              </svg>
            </div> 
        </section>
        <section class="row page page-2 ps-0 pe-0 pt-0 pb-0" id="page-2" style="background-color:#fff;min-height:600px;">
            <div class="white-container wt-c ps-0 pe-0 pt-0 pb-0" style="background-color:#fff;min-height:600px;">
                <div class="row ps-0 pe-0" style="background-color:#fff;">
                    <div class="col-md-12 ps-0 pe-0 pt-0 pb-0" style="background-color:#fff;">
                        <div class="wt-c ps-0 pe-0 pt-0 pb-0" style="border:none;max-width:800px;margin: 0 auto;background-color:#fff;min-height:600px;">
                            <h2 class="white-title mt-5" style="padding-left:15px;margin-left:10px;width:98%;padding-right:20px;margin-right:20px;">Analyse de marché</h2>
                            <h3 class="small-title mt-4" style="background-color:#fff;padding-left:15px;margin-left:10px;width:98%;padding-right:20px;margin-right:20px;">Partie INSEE</h3>
                            <div style="background-color:#fff;padding-left:15px;margin-left:10px;width:98%;padding-right:20px;margin-right:20px;">
                                <div class="blue-sky">La population était de {{fiche.insee.nb_habitants}} habitants.</div>    
                                <div class="blue-sky">Médiane du revenu disponible par unité de consommation</div>                
                            </div>
                            <div class="row mt-3 jcenter ps-0 pe-0 pt-0 pb-0" style="background-color:#fff;">
                                <div class="block col-md-4">
                                    <div class="svg">
                                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="31" cy="31" r="24" fill="#FFAA00"/>
                                            <circle cx="31" cy="31" r="30" stroke="#FFAA00"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24 23C24 21.8954 24.8954 21 26 21H36C37.1046 21 38 21.8954 38 23V40H32.5V37C32.5 36.4477 32.0523 36 31.5 36H30.5C29.9477 36 29.5 36.4477 29.5 37V40H24V23ZM27 24C27 23.4477 27.4477 23 28 23H29C29.5523 23 30 23.4477 30 24V25C30 25.5523 29.5523 26 29 26H28C27.4477 26 27 25.5523 27 25V24ZM33 23C32.4477 23 32 23.4477 32 24V25C32 25.5523 32.4477 26 33 26H34C34.5523 26 35 25.5523 35 25V24C35 23.4477 34.5523 23 34 23H33ZM27 28C27 27.4477 27.4477 27 28 27H29C29.5523 27 30 27.4477 30 28V29C30 29.5523 29.5523 30 29 30H28C27.4477 30 27 29.5523 27 29V28ZM28 31C27.4477 31 27 31.4477 27 32V33C27 33.5523 27.4477 34 28 34H29C29.5523 34 30 33.5523 30 33V32C30 31.4477 29.5523 31 29 31H28ZM32 32C32 31.4477 32.4477 31 33 31H34C34.5523 31 35 31.4477 35 32V33C35 33.5523 34.5523 34 34 34H33C32.4477 34 32 33.5523 32 33V32Z" fill="#363660"/>
                                            <rect x="32" y="27" width="3" height="3" rx="1" fill="white"/>
                                            <path opacity="0.3" d="M22 41.9881C22 40.8901 22.8901 40 23.9881 40H38.0119C39.1099 40 40 40.8901 40 41.9881C40 41.9947 39.9947 42 39.9881 42H22.0119C22.0053 42 22 41.9947 22 41.9881Z" fill="#363660"/>
                                        </svg>
                                    </div>
                                    <div class="ref">
                                        {{fiche.insee.menages_fiscaux.com.mediane.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} €
                                    </div>
                                    <div class="type_ref">
                                        Commune
                                    </div>
                                </div>
                                <!--  -->
                                <div class="block col-md-4" style="background-color:#fff;">
                                    <div class="svg" style="background-color:#fff;">
                                         <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="31" cy="31" r="24" fill="#FFAA00"/>
                                            <path opacity="0.3" d="M37 33C35.3431 33 34 31.6569 34 30C34 28.3431 35.3431 27 37 27C38.6569 27 40 28.3431 40 30C40 31.6569 38.6569 33 37 33Z" fill="#FF0000"/>
                                            <path opacity="0.3" d="M28 30C25.7909 30 24 28.2091 24 26C24 23.7909 25.7909 22 28 22C30.2091 22 32 23.7909 32 26C32 28.2091 30.2091 30 28 30Z" fill="#FF0000"/>
                                            <path d="M19.0007 39.1992C19.3883 34.4265 23.2619 32 27.9833 32C32.7712 32 36.7049 34.2932 36.9979 39.2C37.0096 39.3955 36.9979 40 36.2467 40C32.5411 40 27.0347 40 19.7275 40C19.4767 40 18.9795 39.4592 19.0007 39.1992Z" fill="#3D42DF"/>
                                            <circle cx="31" cy="31" r="30" stroke="#FFAA00"/>
                                        </svg>
                                    </div>
                                    <div class="ref" style="background-color:#fff;">
                                        {{fiche.insee.menages_fiscaux.dep.mediane.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} €
                                    </div>
                                    <div class="type_ref" style="background-color:#fff;">
                                        Département
                                    </div>
                                </div>
                                <!--  -->
                                <div class="block col-md-4" style="background-color:#fff;">
                                    <div class="svg">
                                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="31" cy="31" r="24" fill="#FFAA00"/>
                                            <circle cx="31" cy="31" r="30" stroke="#FFAA00"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M32.1101 23.4347C32.1149 23.4079 32.1264 23.3829 32.1264 23.3542C32.1264 23.2306 32.0804 23.1184 32.0037 23.0341L31.1489 21.3244V20.4792C31.1489 20.2147 30.9342 20 30.6697 20C30.4052 20 30.1905 20.2147 30.1905 20.4792V21.3244L29.2829 23.1405C29.281 23.1443 29.282 23.1491 29.2801 23.1529C29.2513 23.2143 29.2321 23.2823 29.2321 23.3551C29.2321 24.9096 29.12 26.3365 28.9293 27.6676H32.409C32.2232 26.3595 32.1139 24.9574 32.1101 23.4347Z" fill="#363660"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M39.2046 42.2409L38.6488 41.4732C37.7259 40.2015 36.7369 38.8177 35.8083 37.2499H36.4197C36.6842 37.2499 36.8989 37.0352 36.8989 36.7707C36.8989 36.5062 36.6842 36.2915 36.4197 36.2915H25.3989C25.1344 36.2915 24.9197 36.5062 24.9197 36.7707C24.9197 37.0352 25.1344 37.2499 25.3989 37.2499H25.5302C24.6025 38.8187 23.6125 40.2015 22.6897 41.4732L22.1338 42.2409C22.0294 42.3865 22.015 42.5801 22.0964 42.7402C22.1779 42.9002 22.3437 42.9999 22.5239 42.9999H26.3831C26.5191 42.9999 26.6485 42.9414 26.7405 42.8408C26.8316 42.7392 26.8747 42.6041 26.8603 42.468C26.844 42.3281 26.8364 42.1853 26.8364 42.0415C26.8364 39.9275 28.5556 38.2082 30.6697 38.2082C32.7838 38.2082 34.503 39.9275 34.503 42.0415C34.503 42.1853 34.4953 42.3281 34.48 42.468C34.4647 42.6041 34.5088 42.7382 34.5998 42.8408C34.6909 42.9414 34.8202 42.9999 34.9573 42.9999H38.8164C38.9966 42.9999 39.1605 42.9002 39.2429 42.7402C39.3254 42.5801 39.3101 42.3865 39.2046 42.2409Z" fill="#363660"/>
                                            <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M28.8871 35.3333L29.7362 32.7861C29.8014 32.5906 29.9844 32.4583 30.1904 32.4583H31.1488C31.3548 32.4583 31.5378 32.5906 31.603 32.7861L32.4521 35.3333H34.7569C33.9385 33.6706 33.2494 31.7674 32.7722 29.5833H33.0645C33.329 29.5833 33.5437 29.3686 33.5437 29.1041C33.5437 28.8396 33.329 28.625 33.0645 28.625H28.2738C28.0093 28.625 27.7946 28.8397 27.7946 29.1042C27.7946 29.3687 28.0093 29.5834 28.2738 29.5834H28.5661C28.0898 31.7674 27.3998 33.6707 26.5814 35.3334H28.8871V35.3333Z" fill="#363660"/>
                                        </svg>
                                    </div>
                                    <div class="ref">
                                        {{fiche.insee.menages_fiscaux.france.mediane.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} €
                                    </div>
                                    <div class="type_ref">
                                        France
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3" style="background-color:#fff;padding-left:15px;margin-left:10px;width:98%;padding-right:20px;margin-right:20px;">
                                <div class="blue-sky night">Taux de chômage est de {{fiche.insee.nb_chomage}}%</div>    
                                <div class="blue-sky night">Le nombre de logements était de {{fiche.insee.logement}} avec les répartitions suivantes :</div>                
                            </div>
                            <div class="row mt-1 jcenter" style="background-color:#fff;">
                                <div class="block col-md-4" style="background-color:#fff;">
                                    <div class="svg" style="background-color:#fff;">
                                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                            <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.5334 39.1756C28.5071 37.3783 24 32.9884 24 29.5C24 25 27 22 31.5 22C36 22 39 25.75 39 29.5C39 32.4266 34.432 37.1804 32.426 39.1269C31.8989 39.6383 31.0828 39.6629 30.5334 39.1756ZM31.5 31C32.8807 31 34 29.8807 34 28.5C34 27.1193 32.8807 26 31.5 26C30.1193 26 29 27.1193 29 28.5C29 29.8807 30.1193 31 31.5 31Z" fill="#3D42DF"/>
                                        </svg>
                                    </div>
                                    <div class="ref">
                                        {{round(fiche.insee.res_principale)}} %
                                    </div>
                                    <div class="type_ref">
                                        Res. principales
                                    </div>
                                </div>
                                <!--  -->
                                <div class="block col-md-4" style="background-color:#fff;">
                                    <div class="svg">
                                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                            <path opacity="0.3" d="M25 39H23C23 36.2386 25.2386 35 28 35H34C36.7614 35 39 36.2386 39 39H37C37 37.3431 35.6569 37 34 37H28C26.3431 37 25 37.3431 25 39Z" fill="#FF0000"/>
                                            <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M42 27H40.1739C39.0693 27 38.1739 27.8954 38.1739 29V31C38.1739 31.5523 37.7262 32 37.1739 32H24.8696C24.3173 32 23.8696 31.5523 23.8696 31V29C23.8696 27.8954 22.9741 27 21.8696 27H20C20 25.3431 21.3431 24 23 24H39C40.6569 24 42 25.3431 42 27Z" fill="#FF0000"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M42 29V34C42 35.6569 40.6569 37 39 37H23C21.3431 37 20 35.6569 20 34V29H21.8696V31C21.8696 32.6569 23.2127 34 24.8696 34H37.1739C38.8308 34 40.1739 32.6569 40.1739 31V29H42Z" fill="#3D42DF"/>
                                            <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                        </svg>
                                    </div>
                                    <div class="ref">
                                        {{round(fiche.insee.res_secondaire)}} %
                                    </div>
                                    <div class="type_ref">
                                        Res. secondaires
                                    </div>
                                </div>
                                <!-- -->
                                <div class="block col-md-4" style="background-color:#fff;">
                                    <div class="svg">
                                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                            <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                            <path opacity="0.3" d="M33.2929 35.7071C32.9024 35.3166 32.9024 34.6834 33.2929 34.2929C33.6834 33.9024 34.3166 33.9024 34.7071 34.2929L38.7071 38.2929C39.0976 38.6834 39.0976 39.3166 38.7071 39.7071C38.3166 40.0976 37.6834 40.0976 37.2929 39.7071L33.2929 35.7071Z" fill="#FF0000"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M23 30C23 33.866 26.134 37 30 37C33.866 37 37 33.866 37 30C37 26.134 33.866 23 30 23C26.134 23 23 26.134 23 30ZM35 30C35 32.7614 32.7614 35 30 35C27.2386 35 25 32.7614 25 30C25 27.2386 27.2386 25 30 25C32.7614 25 35 27.2386 35 30Z" fill="#3D42DF"/>
                                        </svg>
                                    </div>
                                    <div class="ref">
                                        {{round(fiche.insee.logement_vacants)}} %
                                    </div>
                                    <div class="type_ref">
                                        Logement vacants
                                    </div>
                                </div>
                            </div>
                            <!--  -->
                            <div class="row mt-1 jcenter" style="background-color:#fff;">
                                <div class="block col-md-4" style="background-color:#fff;">
                                    <div class="svg">
                                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                            <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9571 27.415C22.3626 27.7783 22 28.4249 22 29.1216V37.9999C22 39.1045 22.8954 39.9999 24 39.9999H38C39.1046 39.9999 40 39.1045 40 37.9999V29.1216C40 28.4249 39.6374 27.7783 39.0429 27.415L31.5215 22.8186C31.2013 22.623 30.7987 22.623 30.4786 22.8186L22.9571 27.415ZM29 31.9999C28.4477 31.9999 28 32.4476 28 32.9999V35.9999C28 36.5522 28.4477 36.9999 29 36.9999H33C33.5523 36.9999 34 36.5522 34 35.9999V32.9999C34 32.4476 33.5523 31.9999 33 31.9999H29Z" fill="#3D42DF"/>
                                        </svg>
                                    </div>
                                    <div class="ref">
                                        {{round(fiche.insee.maison)}} %
                                    </div>
                                    <div class="type_ref">
                                        Maisons
                                    </div>
                                </div>
                                <!--  -->
                                <div class="block col-md-4" style="background-color:#fff;">
                                    <div class="svg">
                                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24 23C24 21.8954 24.8954 21 26 21H36C37.1046 21 38 21.8954 38 23V40H32.5V37C32.5 36.4477 32.0523 36 31.5 36H30.5C29.9477 36 29.5 36.4477 29.5 37V40H24V23ZM27 24C27 23.4477 27.4477 23 28 23H29C29.5523 23 30 23.4477 30 24V25C30 25.5523 29.5523 26 29 26H28C27.4477 26 27 25.5523 27 25V24ZM33 23C32.4477 23 32 23.4477 32 24V25C32 25.5523 32.4477 26 33 26H34C34.5523 26 35 25.5523 35 25V24C35 23.4477 34.5523 23 34 23H33ZM27 28C27 27.4477 27.4477 27 28 27H29C29.5523 27 30 27.4477 30 28V29C30 29.5523 29.5523 30 29 30H28C27.4477 30 27 29.5523 27 29V28ZM28 31C27.4477 31 27 31.4477 27 32V33C27 33.5523 27.4477 34 28 34H29C29.5523 34 30 33.5523 30 33V32C30 31.4477 29.5523 31 29 31H28ZM32 32C32 31.4477 32.4477 31 33 31H34C34.5523 31 35 31.4477 35 32V33C35 33.5523 34.5523 34 34 34H33C32.4477 34 32 33.5523 32 33V32Z" fill="#3D42DF"/>
                                            <rect x="32" y="27" width="3" height="3" rx="1" fill="white"/>
                                            <path opacity="0.3" d="M22 41.9881C22 40.8901 22.8901 40 23.9881 40H38.0119C39.1099 40 40 40.8901 40 41.9881C40 41.9947 39.9947 42 39.9881 42H22.0119C22.0053 42 22 41.9947 22 41.9881Z" fill="#FF0000"/>
                                            <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                        </svg>

                                    </div>
                                    <div class="ref">
                                        {{round(fiche.insee.appartement)}} %
                                    </div>
                                    <div class="type_ref">
                                        Appartements
                                    </div>
                                </div>
                                <!--  -->
                                <div class="block col-md-4" style="background-color:#fff;">
                                    <div class="svg">
                                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                            <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                            <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M30.366 29.8228L24 36.0002L24 39.0002L27 39.0002L27 37.0002L28.9418 37.0002V35.0002L31 35.0002L33.1845 32.6412L30.366 29.8228Z" fill="#FF0000"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.7426 32.2279C33.0857 34.5711 36.8847 34.5711 39.2279 32.2279C41.571 29.8848 41.571 26.0858 39.2279 23.7426C36.8847 21.3995 33.0857 21.3995 30.7426 23.7426C28.3994 26.0858 28.3994 29.8848 30.7426 32.2279ZM35.0606 28.182C35.6464 28.7678 36.5961 28.7678 37.1819 28.182C37.7677 27.5962 37.7677 26.6464 37.1819 26.0607C36.5961 25.4749 35.6464 25.4749 35.0606 26.0607C34.4748 26.6464 34.4748 27.5962 35.0606 28.182Z" fill="#3D42DF"/>
                                        </svg>
                                    </div>
                                    <div class="ref">
                                        {{round(fiche.insee.part_proprietaire)}} %
                                    </div>
                                    <div class="type_ref">
                                        Parts de propriétaire
                                    </div>
                                </div>

                            </div>
                            <!--  -->
                            <div class="row mt-1 jcenter" v-if="fiche != null" style="background-color:#fff;">
                                <div class="block col-md-4">
                                    <div class="svg">
                                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                            <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                            <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M25.5425 40.5012C25.3996 41.0347 25.7161 41.583 26.2496 41.726C26.7831 41.8689 27.3314 41.5523 27.4744 41.0189L29.0725 35.0545C29.2155 34.521 28.8989 33.9727 28.3654 33.8297C27.8319 33.6868 27.2836 34.0034 27.1407 34.5368L25.5425 40.5012ZM36.4496 40.4709C36.5926 41.0044 36.276 41.5527 35.7425 41.6956C35.2091 41.8386 34.6607 41.522 34.5178 40.9885L33.2149 36.1261C33.0719 35.5926 33.3885 35.0443 33.922 34.9013C34.4555 34.7584 35.0038 35.075 35.1467 35.6084L36.4496 40.4709Z" fill="#FF0000"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3609 20C25.125 20 24.185 21.1097 24.3881 22.3288L25.9304 31.5822C25.9705 31.8233 26.1791 32 26.4236 32H35.5764C35.8209 32 36.0295 31.8233 36.0696 31.5822L37.6119 22.3288C37.815 21.1097 36.875 20 35.6391 20H26.3609ZM24.5 33C23.6716 33 23 33.6716 23 34.5C23 35.3284 23.6716 36 24.5 36H37.5C38.3284 36 39 35.3284 39 34.5C39 33.6716 38.3284 33 37.5 33H24.5Z" fill="#3D42DF"/>
                                        </svg>
                                    </div>
                                    <div class="ref">
                                        {{round(fiche.insee.part_locataire)}} % 
                                    </div>
                                    <div class="type_ref">
                                        Parts de locataires
                                    </div>
                                </div>
                                <!--  -->
                            
                            </div>
                            

                        </div>
                    </div>

                </div>
            </div>
        </section>
        <section class="row page page-3 ps-0 pe-0 pt-0 pb-0" id="page-3" style="min-height:600px;" >
            <div class="white-container wt-c ps-0 pe-0 pt-0 pb-0" aria-label="pdf-page-2" style="background-color:#fff;min-height:600px;">    
                <div class="row ps-0 pe-0 pt-0 pb-0 ms-3 me-3" style="background-color:#fff;min-height:600px;">
                    <div class="col-md-12 ps-0 pe-0 pt-0 pb-0" style="background-color:#fff;">
                        <div class=" wt-c ps-0 pe-0 pt-0 pb-0" v-if="fiche != null" style="background-color:#fff;min-height:600px;"> 
                            <h2 class="nt-title mt-5 ms-2" v-if="fiche != null">Partie marche Immobilier</h2>
                            <!--span class="lab" v-if="fiche.immobilier[0].commune.label != undefined">{{v[0].commune.label}}</span-->
                            <div class="row" style="background-color:#fff;padding: 0 20px;">
                                
                                <div class="col-md-12 ps-0 pe-0" style="background-color:#fff;">
                                    <div class="block bc-w mt-3" style="background-color:#fff;">
                                        <span class="btn-float">{{fiche.immobilier[0].type}}</span>
                                        <div class="svg">
                                            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="fiche.immobilier[0].type == 'appartement'">
                                                <circle cx="31" cy="31" r="24" fill="#4C46B2"/>
                                                <circle cx="31" cy="31" r="30" stroke="#4C46B2"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 23C24 21.8954 24.8954 21 26 21H36C37.1046 21 38 21.8954 38 23V40H32.5V37C32.5 36.4477 32.0523 36 31.5 36H30.5C29.9477 36 29.5 36.4477 29.5 37V40H24V23ZM27 24C27 23.4477 27.4477 23 28 23H29C29.5523 23 30 23.4477 30 24V25C30 25.5523 29.5523 26 29 26H28C27.4477 26 27 25.5523 27 25V24ZM33 23C32.4477 23 32 23.4477 32 24V25C32 25.5523 32.4477 26 33 26H34C34.5523 26 35 25.5523 35 25V24C35 23.4477 34.5523 23 34 23H33ZM27 28C27 27.4477 27.4477 27 28 27H29C29.5523 27 30 27.4477 30 28V29C30 29.5523 29.5523 30 29 30H28C27.4477 30 27 29.5523 27 29V28ZM28 31C27.4477 31 27 31.4477 27 32V33C27 33.5523 27.4477 34 28 34H29C29.5523 34 30 33.5523 30 33V32C30 31.4477 29.5523 31 29 31H28ZM32 32C32 31.4477 32.4477 31 33 31H34C34.5523 31 35 31.4477 35 32V33C35 33.5523 34.5523 34 34 34H33C32.4477 34 32 33.5523 32 33V32Z" fill="#00C1E2"/>
                                                <rect x="32" y="27" width="3" height="3" rx="1" fill="white"/>
                                                <path opacity="0.3" d="M22 41.9881C22 40.8901 22.8901 40 23.9881 40H38.0119C39.1099 40 40 40.8901 40 41.9881C40 41.9947 39.9947 42 39.9881 42H22.0119C22.0053 42 22 41.9947 22 41.9881Z" fill="white"/>
                                            </svg>
                                            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="fiche.immobilier[0].type == 'maison'">
                                                <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                                <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9571 27.415C22.3626 27.7783 22 28.4249 22 29.1216V37.9999C22 39.1045 22.8954 39.9999 24 39.9999H38C39.1046 39.9999 40 39.1045 40 37.9999V29.1216C40 28.4249 39.6374 27.7783 39.0429 27.415L31.5215 22.8186C31.2013 22.623 30.7987 22.623 30.4786 22.8186L22.9571 27.415ZM29 31.9999C28.4477 31.9999 28 32.4476 28 32.9999V35.9999C28 36.5522 28.4477 36.9999 29 36.9999H33C33.5523 36.9999 34 36.5522 34 35.9999V32.9999C34 32.4476 33.5523 31.9999 33 31.9999H29Z" fill="#3D42DF"/>
                                            </svg>
                                        </div>
                                        <div class="ref">
                                            {{fiche.immobilier[0].commune.prix_m2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} €
                                            <span class="type_ref">
                                                Prix m² moyen
                                            </span>
                                        </div>
                                        <div class="type_ref">
                                            de {{fiche.immobilier[0].commune.prix_range[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} € à {{fiche.immobilier[0].commune.prix_range[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} €
                                        </div>
                                    </div>

                                    <div class="mt-3" style="background-color:#fff;">
                                        <div class="blue-sky flex" style="justify-content:space-between;">
                                            <span >
                                                Evolution du marché des {{fiche.immobilier[0].type}}                
                                            </span>
                                            <span class="sky-t">
                                                1 an: {{fiche.immobilier[0].commune.evol_1_an}} % <span style="height: 4px;width: 4px;display:inline-block;border-radius: 0px;background: #00C1E2;"></span> 5 ans: {{fiche.immobilier[0].commune.evol_5_ans}} %
                                            </span>
                                        </div>    
                                    </div>

                                    <div class="mt-3 h-auto no-down" style="background-color:#fff;">
                                    <chart v-if="fiche.immobilier[0].data_chart !== null" :dataChart="fiche.immobilier[0].data_chart" :id="'chart' + Math.floor(Math.random() * 99)+0" :width="300" :height="222"  suffix="au m²"></chart>
                                    </div>
                                    
                                    <div class="mt-3 h-auto no-down barA" style="background-color:#fff;">
                                    <chart v-if="fiche.immobilier[0].data_chart_evol !== null" :dataChart="fiche.immobilier[0].data_chart_evol" :id="'chart' +Math.floor(Math.random() * 99 + Math.random() * 10)+0" :width="300" :height="222" ></chart>
                                    </div>

                                    <div class="mt-3 h-auto" style="display:none;">
                                    <chart v-if="fiche.immobilier[0].data_chart_evol_p1 !== null" :dataChart="fiche.immobilier[0].data_chart_evol_p1" :id="'chart' +Math.floor(Math.random() * 10)" :width="300" :height="222" ></chart>
                                    </div>

                                </div>
                            </div>
                            
                        </div> 
                    </div>
                </div>
            </div>

        </section>
        <section class="row page page-3 ps-0 pe-0 pt-0 pb-0" id="page-3" style="min-height:600px;" >
            <div class="white-container wt-c ps-0 pe-0 pt-0 pb-0" aria-label="pdf-page-2" style="background-color:#fff;min-height:600px;">    
                <div class="row ps-0 pe-0 pt-0 pb-0 ms-3 me-3" style="background-color:#fff;min-height:600px;">
                    <div class="col-md-12 ps-0 pe-0 pt-0 pb-0" style="background-color:#fff;">
                        <div class=" wt-c ps-0 pe-0 pt-0 pb-0" v-if="fiche != null" style="background-color:#fff;min-height:600px;"> 
                            <!--span class="lab" v-if="fiche.immobilier[0].commune.label != undefined">{{v[0].commune.label}}</span-->
                            <div class="row  mt-5" style="background-color:#fff;padding: 0 20px;">
                                
                                <div class="col-md-12 ps-0 pe-0" style="background-color:#fff;">
                                    <div class="block bc-w mt-3" style="background-color:#fff;">
                                        <span class="btn-float">{{fiche.immobilier[1].type}}</span>
                                        <div class="svg">
                                            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="fiche.immobilier[1].type == 'appartement'">
                                                <circle cx="31" cy="31" r="24" fill="#4C46B2"/>
                                                <circle cx="31" cy="31" r="30" stroke="#4C46B2"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 23C24 21.8954 24.8954 21 26 21H36C37.1046 21 38 21.8954 38 23V40H32.5V37C32.5 36.4477 32.0523 36 31.5 36H30.5C29.9477 36 29.5 36.4477 29.5 37V40H24V23ZM27 24C27 23.4477 27.4477 23 28 23H29C29.5523 23 30 23.4477 30 24V25C30 25.5523 29.5523 26 29 26H28C27.4477 26 27 25.5523 27 25V24ZM33 23C32.4477 23 32 23.4477 32 24V25C32 25.5523 32.4477 26 33 26H34C34.5523 26 35 25.5523 35 25V24C35 23.4477 34.5523 23 34 23H33ZM27 28C27 27.4477 27.4477 27 28 27H29C29.5523 27 30 27.4477 30 28V29C30 29.5523 29.5523 30 29 30H28C27.4477 30 27 29.5523 27 29V28ZM28 31C27.4477 31 27 31.4477 27 32V33C27 33.5523 27.4477 34 28 34H29C29.5523 34 30 33.5523 30 33V32C30 31.4477 29.5523 31 29 31H28ZM32 32C32 31.4477 32.4477 31 33 31H34C34.5523 31 35 31.4477 35 32V33C35 33.5523 34.5523 34 34 34H33C32.4477 34 32 33.5523 32 33V32Z" fill="#00C1E2"/>
                                                <rect x="32" y="27" width="3" height="3" rx="1" fill="white"/>
                                                <path opacity="0.3" d="M22 41.9881C22 40.8901 22.8901 40 23.9881 40H38.0119C39.1099 40 40 40.8901 40 41.9881C40 41.9947 39.9947 42 39.9881 42H22.0119C22.0053 42 22 41.9947 22 41.9881Z" fill="white"/>
                                            </svg>
                                            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="fiche.immobilier[1].type == 'maison'">
                                                <circle cx="31" cy="31" r="24" fill="#00C1E2"/>
                                                <circle cx="31" cy="31" r="30" stroke="#00C1E2"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9571 27.415C22.3626 27.7783 22 28.4249 22 29.1216V37.9999C22 39.1045 22.8954 39.9999 24 39.9999H38C39.1046 39.9999 40 39.1045 40 37.9999V29.1216C40 28.4249 39.6374 27.7783 39.0429 27.415L31.5215 22.8186C31.2013 22.623 30.7987 22.623 30.4786 22.8186L22.9571 27.415ZM29 31.9999C28.4477 31.9999 28 32.4476 28 32.9999V35.9999C28 36.5522 28.4477 36.9999 29 36.9999H33C33.5523 36.9999 34 36.5522 34 35.9999V32.9999C34 32.4476 33.5523 31.9999 33 31.9999H29Z" fill="#3D42DF"/>
                                            </svg>
                                        </div>
                                        <div class="ref">
                                            {{fiche.immobilier[1].commune.prix_m2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} €
                                            <span class="type_ref">
                                                Prix m² moyen
                                            </span>
                                        </div>
                                        <div class="type_ref">
                                            de {{fiche.immobilier[1].commune.prix_range[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} € à {{fiche.immobilier[1].commune.prix_range[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} €
                                        </div>
                                    </div>

                                    <div class="mt-3" style="background-color:#fff;">
                                        <div class="blue-sky flex" style="justify-content:space-between;">
                                            <span >
                                                Evolution du marché des {{fiche.immobilier[1].type}}                
                                            </span>
                                            <span class="sky-t">
                                                1 an: {{fiche.immobilier[1].commune.evol_1_an}} % <span style="height: 4px;width: 4px;display:inline-block;border-radius: 0px;background: #00C1E2;"></span> 5 ans: {{fiche.immobilier[1].commune.evol_5_ans}} %
                                            </span>
                                        </div>    
                                    </div>

                                    <div class="mt-3 h-auto no-down" style="background-color:#fff;">
                                    <chart v-if="fiche.immobilier[1].data_chart !== null" :dataChart="fiche.immobilier[1].data_chart" :id="'chart' + Math.floor(Math.random() * 99)+1" :width="300" :height="222"  suffix="au m²"></chart>
                                    </div>
                                    
                                    <div class="mt-3 h-auto no-down barA" style="background-color:#fff;">
                                    <chart v-if="fiche.immobilier[1].data_chart_evol !== null" :dataChart="fiche.immobilier[1].data_chart_evol" :id="'chart' +Math.floor(Math.random() * 99 + Math.random() * 10)+1" :width="300" :height="222" ></chart>
                                    </div>

                                    <div class="mt-3 h-auto" style="display:none;">
                                    <chart v-if="fiche.immobilier[1].data_chart_evol_p1 !== null" :dataChart="fiche.immobilier[1].data_chart_evol_p1" :id="'chart' +Math.floor(Math.random() * 10)" :width="300" :height="222" ></chart>
                                    </div>

                                </div>
                            </div>
                            
                        </div> 
                    </div>
                </div>
            </div>

        </section>
        
        <section class="row page page-4 ps-0 pe-0 pt-0 pb-0" id="page-5" style="background-color:#fff;min-height: 1157px;height:1157px;padding-bottom:0!important;">
            <div class="white-container wt-c ps-0 pe-0 pt-0 pb-0" aria-label="pdf-page-3" style="background-color:#fff;background-size: cover;min-height:1157px;width: 100%;height: 1157px;">
                <img src="../assets/Image2.png" class="img-responsive w100" style="object-fit: cover;min-height:1157px;"/>
            </div>
        </section>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import html2PDF from 'jspdf-html2canvas';
import html2canvas from 'html2canvas'
export default {
    data() {
        return {
            adresse: '',
            adresseError: '',
            user: null,
            zipResults: [],
            fiche: null,
            hasResult: false,
            loader: false,
            typingTimer: null,
            doneTypingInterval : 500,
            title: null,
            pdf: [],
            loadPdf: false
        }
    },
    components: {
      chart : require('./template/chart/chart').default

    },
    mounted() {
        
        if(localStorage.getItem('uitoken') != null) {
            const user = JSON.parse(localStorage.getItem('uitoken'))
            this.user = user
        }
    },
    methods: {
        round(num) {
            return Math.round(num * 100) / 100
        },
        addScript(url) {
            var script = document.createElement('script');
            script.type = 'application/javascript';
            script.src = url;
            document.head.appendChild(script);
        },
        refresh() {
            this.adresse = ''
            this.hasResult = false
        },
        up() {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(this.fetchZip, this.doneTypingInterval);
        },
        down() {
            clearTimeout(this.typingTimer);
        },
        fetchZip() {
            const self = this
            self.hasResult = false
            self.zipResults = []
           axios({
                method: 'GET',
                url: `https://api.exval.wertkt.com/cities?postalCode=${this.adresse}&level=COM`,
                headers: {
                    'Authorization': this.user.access_token
                }
            }).then(function(response) {
                self.zipResults = response.data.data
            }).catch(function(err) {
                console.log(err)
            })
        },
        getData(zip) {
            const self = this
            self.loader = true
            self.fiche = null
            self.title = null
            axios({
                method: 'GET',
                    url: `https://api.exval.wertkt.com/api/v1/fiche-marche?postalCode=${zip.zip}&city=${zip.city}&level=COM`,
                    headers: {
                        'Authorization': this.user.access_token
                    }
            }).then(function(response) {
                self.fiche = response.data.data
                self.hasResult = true
                self.title = {
                    zip: zip.zip,
                    city: zip.city
                }
                self.loader = false
            }).catch(function(err) {
                console.log(err)
            })

        },
        async exportPdf() {
            await this.downloadPDF()
        },
        async printDocument() {
            const pdf = new window.jspdf.jsPDF('p', 'mm', 'a1'); // A4 size page of PDF
            const imgWidth = 1108;
            const position = 0;

            let page1 = document.querySelector('.page-1');
            let page2 = document.querySelector('.page-2');
            const [imgPage1, imgPage2] = await Promise.all([html2canvas(page1), html2canvas(page2)]);
            // Process first image
            let imgHeight = imgPage1.offsetWidth * imgWidth / imgPage1.width;
            let contentDataURL = imgPage1.toDataURL('image/png');
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            pdf.addPage();
            // Process second image
            imgHeight = imgPage2.offsetWidth * imgWidth / imgPage2.width;
            contentDataURL = imgPage2.toDataURL('image/png');
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);

            pdf.save('dashboard.pdf'); // Generated PDF
        },
        downloadPDF () {
            this.loadPdf = true
            const self = this
            var elementHTML = document.getElementsByClassName('page');
            html2PDF(elementHTML, {
                jsPDF: {
                    format: 'a4',
                },
                imageQuality: 1,
                margin: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
                html2canvas: {
                    scrollX: 0,
                    scrollY: -2,
                    scale: 1.5,
                    dpi: 72
                },
                imageType: 'image/png',
                output: `${self.title.zip}_${self.title.city}.pdf`,
                success: function(pdf) {
                    pdf.save(this.output);
                    self.loadPdf = false
                }
            });

        }
    }
}
</script>
<style scoped>
.exp {
    font-family: Prompt;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    letter-spacing: -0.186667px;
    color: #373563;
    height: 21px;
}
.wt-c {
    width: 100%;
    height: auto;
    min-height: auto;
}
#pdf {
    position: fixed;
    width: 21cm;
    height: 29.7cm;
    margin: 30mm 45mm 30mm 45mm; 
}
.justify-center {
    align-content: center;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    height: 100%;
}
.jcenter {
    justify-content: center;
}
.square {
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    border-radius: 8px;
    height: 42.08333206176758px;
    width: 40px;
    border-radius: 8px;
    position:relative;
}
.square svg {
    position: absolute;
    left: 8px;
    right: 16.67%;
    top: 8px;
    bottom: 8.33%;
}
.small-title {
    
font-family: Prompt;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 27px;

color: #363660;
}
.blue-sky {
    background: #00c0e211;
    mix-blend-mode: normal;
    border-radius: 4px;
    font-family: Prompt;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 28px;
    padding-left: 10px;
    padding-right: 10px;
    /* identical to box height, or 255% */

    margin: 5px 0;
    color: #363660;
}
.blue-sky.night {
    background: #4b45b211;
}
.block {
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    border-radius: 10px;
    height: 147px;
    width: 199px;
    border-radius: 10px;
    margin: 0 5px 0 5px;
}
.b{
    
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    border-radius: 10px;
    height: 147px;
    border-radius: 10px;
}
.block .svg ,.b .svg{
    margin: 20px auto 0 auto;
    text-align: center;
}
.block .ref,.b .ref  {
    font-family: Prompt;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    text-align: center;
    letter-spacing: -0.02px;

    color: #363660;
    margin: 0 auto;
    margin-top: 5px;
}
.block .type_ref,.b .type_ref  {
    font-family: Prompt;
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 18px;
    text-align: center;

    color: #363660;
}
.nt-title {
    font-family: Prompt;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 27px;

color: #363660;
text-align:left;
}
.lab {
    font-family: Prompt;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;

color: #4C46B2;
}
.bc-w {
    width: 100%;
    margin:0;
    position:relative;
}
.btn-float {
    background: #FFA700;
border: 1px solid #E29400;
box-sizing: border-box;
border-radius: 5px;
font-family: Prompt;
font-style: normal;
font-weight: 500;
font-size: 9px;
line-height: 14px;
/* identical to box height */

text-align: center;

color: #383366;
padding: 5px;
height: auto;
width: 75px;
position:absolute;
left: 16px;
top: 16px;
text-transform: capitalize;
}
.sky-t {
    font-family: Prompt;
font-style: normal;
font-weight: 500;
font-size: 11px;
line-height: 28px;
/* identical to box height, or 255% */

text-align: right;

color: #00C1E2;
}
.zip-autocomplete {
    position: absolute!important;
    background: #fff;
    width: 100%;
    left: 0;
    top: 50px!important;
    z-index: 10;
    -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 12%);
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%);
    max-height: 159px;
    overflow: auto;
    text-align: left;
}
.clear-field {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #999;
    padding: 0!important;
    position: absolute!important;
    right: 20px;
    z-index: 20;
    top: 10px;
}
.with-value+.clear-field {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity .5s ease,visibility .5s ease;
    -o-transition: opacity .5s ease,visibility .5s ease;
    transition: opacity .5s ease,visibility .5s ease;
}
.btn.clear-field {
    width: 16px;
    height: 16px;
    position: relative;
    outline: none;
}
.clear-field .fa {
    
    position:absolute;
    top: 4px;
    color: #383366;
}
.zip-autocomplete ul {
    list-style: none;
    padding:0;
    margin:0;
}
.zip-autocomplete ul li {
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 10px;
}
.zip-autocomplete ul li:hover {
    background: #ecececec;
}
.zip-autocomplete ul p {
    margin: 0;
    font-family: Prompt;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    /* identical to box height */

    text-align: left;

    color: #383366;
}
/* width */
.zip-autocomplete::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.zip-autocomplete::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.zip-autocomplete::-webkit-scrollbar-thumb {
  background: #383366; 
  border-radius: 10px;
}

/* Handle on hover */
.zip-autocomplete::-webkit-scrollbar-thumb:hover {
  background: #383366; 
}
.barA .bar {
    height: auto;
}
.barA .dow {
    display: none;
}
.wt-w {
    height: auto;
    min-height: initial;
    margin: 20vh auto;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-top:0;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  margin: 8px;
  border: 2px solid #383366;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #383366 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>