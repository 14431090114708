<template>
  <div>
    <notifications group="foo" />

    <div class="loader" v-if="loader == true">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>

    <div class="loader" v-if="loadPdf == true">
      <div
        class="white-container"
        style="color:#fff;width:280px;left: 45%;height:auto;min-height:auto;color:#373563;top:40%;text-align:center;"
      >
        La création du PDF peut prendre quelques secondes. <br />Merci de bien
        vouloir patienter...
        <div class="lds-ring" id="md">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div
      class="map-container"
      v-bind:class="analyseSplit == true ? 'split' : ''"
    >
      <div
        class="cursor clearPoly toolTip clearPolygon"
        @click="clearPolygon"
        v-if="drawerEnable == true"
        data-tooltip="Supprimer le tracé"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          style="position: relative;top: 10px;left: 11px;"
        >
          <g fill="none" fill-rule="evenodd">
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M0 0H16V16H0z"
                      transform="translate(-496 -120) translate(132 86) translate(360 30) translate(4 4)"
                    />
                    <path
                      fill="#363660"
                      d="M7.019 15.019l-1.154-1.154.799-1.567-1.76.606-1.538-1.538 2.07-3.608-3.608 2.07-.961-.961 5.31-3.513c1.384-.915 3.242-.587 4.228.748 1.04 1.406 1.01 3.333-.072 4.706l-3.314 4.21z"
                      transform="translate(-496 -120) translate(132 86) translate(360 30) translate(4 4)"
                    />
                    <path
                      fill="#00C1E2"
                      d="M13.069 1.384c.119.226.256.446.412.656.399.54.906.954 1.47 1.234l-2.68 2.678c-.52.52-1.365.52-1.885 0s-.52-1.365 0-1.886z"
                      transform="translate(-496 -120) translate(132 86) translate(360 30) translate(4 4) rotate(-180 12.473 3.863)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <i-map
        ref="iMap"
        @adressUpdate="adressUpdate"
        @updateCenter="updateCenter"
        @updatePoint="updatePoint"
        @savePolygon="savePolygon"
        @savePolyJson="savePolyJson"
        @clearPolygon="clearPoly"
        :drawerEnable="drawerEnable"
        :oStat="oStat"
      ></i-map>
    </div>
    <div class="container-fluid">
      <div class="row mt-3">
        <div class="col-1  ps-0 pe-0">
          <menu-item
            :save="save"
            :down="down"
            @sauvegarde="openSauvegarde"
            @download="download"
            @openSearch="openSearch"
            @openMap="openMap"
            @openAnalyse="openAnalyse"
            @openInteraction="openInteraction"
          />
          <!-- bg-last-child -->
        </div>
        <div
          class="col-md-11 ps-0"
          v-bind:class="oSearch == true ? 'oSearch' : 'oHide'"
        >
          <div class="flex-row">
            <div
              class="white-container"
              v-bind:class="offres == true ? 'noborder-right' : ''"
            >
              <div class="row">
                <div class="col col-lg-12 relative">
                  <h2 class="white-title">Analyse des références</h2>
                  <div class="rect_right cursor" v-if="offres == false">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      @click="clearForm"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  d="M0 0H16V16H0z"
                                  transform="translate(-496 -120) translate(132 86) translate(360 30) translate(4 4)"
                                />
                                <path
                                  fill="#363660"
                                  d="M7.019 15.019l-1.154-1.154.799-1.567-1.76.606-1.538-1.538 2.07-3.608-3.608 2.07-.961-.961 5.31-3.513c1.384-.915 3.242-.587 4.228.748 1.04 1.406 1.01 3.333-.072 4.706l-3.314 4.21z"
                                  transform="translate(-496 -120) translate(132 86) translate(360 30) translate(4 4)"
                                />
                                <path
                                  fill="#00C1E2"
                                  d="M13.069 1.384c.119.226.256.446.412.656.399.54.906.954 1.47 1.234l-2.68 2.678c-.52.52-1.365.52-1.885 0s-.52-1.365 0-1.886z"
                                  transform="translate(-496 -120) translate(132 86) translate(360 30) translate(4 4) rotate(-180 12.473 3.863)"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div class="simulator flex-row">
                    <div class="doted">
                      <span class="valeurVenal">
                        Valeur venale
                      </span>
                      <div>
                        <Swipper
                          :swipperValue="swipper"
                          @swipperValueChange="onSwippe"
                        />
                      </div>
                      <span class="valeurVenal active">
                        Valeur locative
                      </span>
                    </div>
                  </div>

                  <div class="chooseBetween">
                    <div class="row">
                      <div class="col col-lg-4 active">
                        <div class="pretty-checkbox mr-3">
                          <input
                            type="checkbox"
                            value="transitions"
                            id="transition"
                            @click="openTransitions"
                            :checked="transitions"
                          />
                          <label for="transition" class="type_label"
                            >Transactions</label
                          >
                        </div>
                      </div>
                      <div class="col col-lg-4">
                        <div class="pretty-checkbox mr-3">
                          <input
                            type="checkbox"
                            value="offres"
                            id="offres"
                            @click="openOffres"
                            :checked="offres"
                          />
                          <label for="offres" class="ml-2 type_label"
                            >Offres</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flow" v-if="transitions == true">
                    <div class="flow-container row mb-2 pl-0 pr-0 mqt">
                      <div class="col col-lg-6 ">
                        <p
                          class="violet prompt mb-0 align-left trans"
                          id="toolinfos-1"
                        >
                          Déduction des valeurs externes
                          <span class="infos-svg">
                            <svg
                              class="insvg"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g fill="none" fill-rule="evenodd">
                                <g fill="#363660">
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          d="M8 6.667c.368 0 .667.298.667.666v3.334c0 .368-.299.666-.667.666-.368 0-.667-.298-.667-.666V7.333c0-.368.299-.666.667-.666zm0-2c.368 0 .667.298.667.666C8.667 5.701 8.368 6 8 6c-.368 0-.667-.299-.667-.667 0-.368.299-.666.667-.666zm0 8.666c-2.94 0-5.333-2.392-5.333-5.333 0-2.94 2.392-5.333 5.333-5.333 2.94 0 5.333 2.392 5.333 5.333 0 2.94-2.392 5.333-5.333 5.333m0-12C4.318 1.333 1.333 4.318 1.333 8S4.318 14.667 8 14.667c3.681 0 6.667-2.985 6.667-6.667S11.68 1.333 8 1.333"
                                          transform="translate(-361 -244) translate(132 86) translate(24 124) translate(205 34)"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            <span class="tooltip-custom" id="toolchild-1">
                              Ce filtre permet d'exclure les données aberrantes
                            </span>
                          </span>
                        </p>
                      </div>
                      <div class="col col-lg-6 flex justifyRight">
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="1"
                            id="oui"
                            v-model="valeur"
                          />
                          <label for="oui">Oui</label>
                        </div>
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="0"
                            id="non"
                            v-model="valeur"
                          />
                          <label for="non">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="flow-container row white-bg mb-2 pl-0 pr-0 mqt">
                      <div class="col col-lg-6">
                        <p
                          class="violet prompt mb-0 align-left trans"
                          id="toolinfos-2"
                        >
                          Biens du meme type
                          <span class="infos-svg">
                            <svg
                              class="insvg"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g fill="none" fill-rule="evenodd">
                                <g fill="#363660">
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          d="M8 6.667c.368 0 .667.298.667.666v3.334c0 .368-.299.666-.667.666-.368 0-.667-.298-.667-.666V7.333c0-.368.299-.666.667-.666zm0-2c.368 0 .667.298.667.666C8.667 5.701 8.368 6 8 6c-.368 0-.667-.299-.667-.667 0-.368.299-.666.667-.666zm0 8.666c-2.94 0-5.333-2.392-5.333-5.333 0-2.94 2.392-5.333 5.333-5.333 2.94 0 5.333 2.392 5.333 5.333 0 2.94-2.392 5.333-5.333 5.333m0-12C4.318 1.333 1.333 4.318 1.333 8S4.318 14.667 8 14.667c3.681 0 6.667-2.985 6.667-6.667S11.68 1.333 8 1.333"
                                          transform="translate(-361 -244) translate(132 86) translate(24 124) translate(205 34)"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>

                            <span class="tooltip-custom" id="toolchild-2">
                              Ce filtre a pour objet d'avoir la possibilité
                              d'écarter toutes les références portant sur
                              plusieurs types de biens, par exemple une
                              transaction portant sur un appartement et un local
                              professionnel
                            </span>
                          </span>
                        </p>
                      </div>
                      <div class="col col-lg-6 flex justifyRight">
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="1"
                            id="bien_oui"
                            v-model="bien"
                          />
                          <label for="bien_oui">Oui</label>
                        </div>
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="0"
                            id="bien_non"
                            v-model="bien"
                          />
                          <label for="bien_non">Non</label>
                        </div>

                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="all"
                            id="bien_tous"
                            v-model="bien"
                          />
                          <label for="bien_tous">Tous</label>
                        </div>
                      </div>
                    </div>

                    <div class="flow-container row mqt">
                      <div class="col col-lg-6 pl-1">
                        <p
                          class="violet prompt mb-0 align-left trans"
                          id="toolinfos-4"
                        >
                          Biens uniques
                          <span class="infos-svg">
                            <svg
                              class="insvg"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g fill="none" fill-rule="evenodd">
                                <g fill="#363660">
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          d="M8 6.667c.368 0 .667.298.667.666v3.334c0 .368-.299.666-.667.666-.368 0-.667-.298-.667-.666V7.333c0-.368.299-.666.667-.666zm0-2c.368 0 .667.298.667.666C8.667 5.701 8.368 6 8 6c-.368 0-.667-.299-.667-.667 0-.368.299-.666.667-.666zm0 8.666c-2.94 0-5.333-2.392-5.333-5.333 0-2.94 2.392-5.333 5.333-5.333 2.94 0 5.333 2.392 5.333 5.333 0 2.94-2.392 5.333-5.333 5.333m0-12C4.318 1.333 1.333 4.318 1.333 8S4.318 14.667 8 14.667c3.681 0 6.667-2.985 6.667-6.667S11.68 1.333 8 1.333"
                                          transform="translate(-361 -244) translate(132 86) translate(24 124) translate(205 34)"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            <span class="tooltip-custom" id="toolchild-4">
                              La recherche portera sur l'ensemble de la commune
                              ou de l'arrondissement dans lequel se situe
                              l'adresse saisie.
                            </span>
                          </span>
                        </p>
                      </div>
                      <div class="col col-lg-6 flex justifyRight">
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="1"
                            id="unique_oui"
                            v-model="unique"
                          />
                          <label for="unique_oui">Oui</label>
                        </div>
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="0"
                            id="unique_non"
                            v-model="unique"
                          />
                          <label for="unique_non">Non</label>
                        </div>
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="all"
                            id="unique_tous"
                            v-model="unique"
                          />
                          <label for="unique_tous">Tous</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form">
                    <div class=" mt-2 mb-2">
                      <div class="row">
                        <div class="col col-lg-12">
                          <div class="form-floating w100" id="modal-container">
                            <input
                              class="form-control"
                              type="text"
                              name=""
                              value=""
                              id="typeBien"
                              v-model="dataSelected"
                              placeholder="Type de biens"
                              style="margin-bottom: 3px;"
                              @focus="toggleModalType"
                              autocomplete="off"
                            />
                            <label for="typeBien">Type de biens</label>

                            <div
                              class="col col-lg-2 flex justifyRight pa-left spec-pa"
                              @focus="toggleModalType"
                            >
                              <i style="cursor:pointer;">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                >
                                  <g fill="none" fill-rule="evenodd">
                                    <g fill="#222B45">
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <path
                                                      d="M8 10.667c-.152 0-.303-.052-.427-.155l-4-3.333c-.282-.236-.321-.656-.085-.939.235-.283.655-.32.939-.085l3.58 2.984 3.575-2.877c.287-.23.707-.185.937.101.231.287.186.706-.101.938l-4 3.218c-.122.098-.27.148-.418.148"
                                                      transform="translate(-516 -353) translate(132 86) translate(24 252) translate(16 13) translate(344 2)"
                                                    />
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </i>
                            </div>

                            <ModalTypeDeBien
                              v-if="modalType == true"
                              :options="options"
                              :open.sync="modalType"
                              @onChange="onChange"
                              @toggleModalType="toggleModalType"
                              @hideModal="hideModal"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="adresse"
                        placeholder="Adresse"
                        value=""
                        autocomplete="off"
                        v-model="adresse"
                        v-bind:class="adresseError == true ? 'is-invalid' : ''"
                      />
                      <label for="adresse">Adresse</label>
                    </div>
                    <div class="row">
                      <div class="col col-lg-6 ">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            id="postal"
                            placeholder="Code postal"
                            name="postal"
                            value=""
                            v-model="codePostal"
                            v-bind:class="codeError == true ? 'is-invalid' : ''"
                          />
                          <label for="postal">Code postal</label>
                        </div>
                      </div>
                      <div class="col col-lg-6 ps-0 ps-0">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            id="ville"
                            placeholder="Ville"
                            name="ville"
                            value=""
                            v-model="ville"
                            v-bind:class="
                              villeError == true ? 'is-invalid' : ''
                            "
                          />
                          <label for="ville">Ville</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col col-lg-6">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            id="rayon"
                            placeholder="Rayon (m)"
                            value=""
                            v-model="rayon"
                          />
                          <label for="rayon">Rayon (m)</label>
                        </div>
                      </div>
                      <div
                        class="col col-lg-6 flex ps-0"
                        style="align-items: center;"
                      >
                        <p class="limit mt-2 mb-2" id="toolinfos-3">
                          Sans limite de rayon
                          <span class="infos-svg">
                            <svg
                              class="insvg"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g fill="none" fill-rule="evenodd">
                                <g fill="#363660">
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          d="M8 6.667c.368 0 .667.298.667.666v3.334c0 .368-.299.666-.667.666-.368 0-.667-.298-.667-.666V7.333c0-.368.299-.666.667-.666zm0-2c.368 0 .667.298.667.666C8.667 5.701 8.368 6 8 6c-.368 0-.667-.299-.667-.667 0-.368.299-.666.667-.666zm0 8.666c-2.94 0-5.333-2.392-5.333-5.333 0-2.94 2.392-5.333 5.333-5.333 2.94 0 5.333 2.392 5.333 5.333 0 2.94-2.392 5.333-5.333 5.333m0-12C4.318 1.333 1.333 4.318 1.333 8S4.318 14.667 8 14.667c3.681 0 6.667-2.985 6.667-6.667S11.68 1.333 8 1.333"
                                          transform="translate(-361 -244) translate(132 86) translate(24 124) translate(205 34)"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            <span class="tooltip-custom" id="toolchild-3">
                              La recherche portera sur l'ensemble de la commune
                              ou de l'arrondissement dans lequel se situe
                              l'adresse saisie.
                            </span>
                          </span>
                        </p>
                        <div class="checkbox-switch">
                          <input
                            type="checkbox"
                            name="limit"
                            value="1"
                            v-model="sansLimit"
                          />
                          <span></span>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col col-lg-6">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            id="surfaceMin"
                            placeholder="Surface min"
                            value=""
                            v-model="surfaceMin"
                          />
                          <label for="surfaceMin">Surface min</label>
                        </div>
                      </div>
                      <div class="col col-lg-6 ps-0 ps-0">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            id="surfaceMax"
                            placeholder="Surface max"
                            value=""
                            v-model="surfaceMax"
                          />
                          <label for="surfaceMax">Surface max</label>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col col-lg-6">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            id="prixMin"
                            placeholder="Prix au m² min"
                            value=""
                            v-model="prixMin"
                          />
                          <label for="prixMin">Prix au m² min</label>
                        </div>
                      </div>
                      <div class="col col-lg-6 ps-0">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            id="prixmax"
                            placeholder="Prix au m² max"
                            value=""
                            v-model="prixMax"
                          />
                          <label for="prixmax">Prix au m² max</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row alignRight mt-4 absoluteBottom"
                v-if="offres == false"
              >
                <button class="btn-visual prompt ms-auto" @click="search">
                  Lancer ma recherche
                </button>
              </div>
            </div>
            <div
              class="white-container ml-0"
              v-if="offres == true"
              v-bind:class="offres == true ? 'noborder-left' : ''"
            >
              <div class="row">
                <div class="col col-lg-12 relative">
                  <div class="rect_right cursor" v-if="offres == true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      @click="clearForm"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  d="M0 0H16V16H0z"
                                  transform="translate(-496 -120) translate(132 86) translate(360 30) translate(4 4)"
                                />
                                <path
                                  fill="#363660"
                                  d="M7.019 15.019l-1.154-1.154.799-1.567-1.76.606-1.538-1.538 2.07-3.608-3.608 2.07-.961-.961 5.31-3.513c1.384-.915 3.242-.587 4.228.748 1.04 1.406 1.01 3.333-.072 4.706l-3.314 4.21z"
                                  transform="translate(-496 -120) translate(132 86) translate(360 30) translate(4 4)"
                                />
                                <path
                                  fill="#00C1E2"
                                  d="M13.069 1.384c.119.226.256.446.412.656.399.54.906.954 1.47 1.234l-2.68 2.678c-.52.52-1.365.52-1.885 0s-.52-1.365 0-1.886z"
                                  transform="translate(-496 -120) translate(132 86) translate(360 30) translate(4 4) rotate(-180 12.473 3.863)"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <h2 class="white-title" style="margin-top: 63px;">
                    Critères supplémentaires
                  </h2>
                  <h3 class="white-title-child">
                    concernent les <span class="tags tag-red">Offres</span>
                  </h3>

                  <div class="row">
                    <div class="col col-lg-6">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="anneemin"
                          placeholder="Année de construction min"
                          value=""
                          v-model="anneeMin"
                        />
                        <label for="anneemin">Année de construction min</label>
                      </div>
                    </div>
                    <div class="col col-lg-6 pl-0 pr-0">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="anneemax"
                          placeholder="Année de construction max"
                          value=""
                          v-model="anneeMax"
                        />
                        <label for="anneemax">Année de construction max</label>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col col-lg-6">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="etagemin"
                          placeholder="Etage min"
                          value=""
                          v-model="etageMin"
                        />
                        <label for="etagemin">Etage min</label>
                      </div>
                    </div>
                    <div class="col col-lg-6  pl-0 pr-0">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="etagemax"
                          placeholder="Etage max"
                          value=""
                          v-model="etageMax"
                        />
                        <label for="etagemax">Etage max</label>
                      </div>
                    </div>
                  </div>

                  <div class="flow-container row white-bg mb-2 ps-0 pe-0 mt-0">
                    <div class="col col-lg-6">
                      <p class="violet prompt mb-0 align-left criteria">
                        Biens du meme type
                      </p>
                      <div
                        class="col col-lg-12 flex justifyLeft ml-0 mr-0 ps-0 pe-0"
                      >
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Oui"
                            id="meme_type_oui"
                            v-model="bienType"
                          />
                          <label for="meme_type_oui"> Oui</label>
                        </div>
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Non"
                            id="meme_type_non"
                            v-model="bienType"
                          />
                          <label for="meme_type_non">Non</label>
                        </div>
                      </div>
                    </div>
                    <div class="col col-lg-6">
                      <p class="violet prompt mb-0 align-left criteria">
                        Piscine :
                      </p>
                      <div
                        class="col col-lg-12 flex justifyLeft ml-0 mr-0 ps-0 pe-0"
                      >
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Oui"
                            id="piscine_oui"
                            v-model="piscine"
                          />
                          <label for="piscine_oui">Oui</label>
                        </div>
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Non"
                            id="piscine_non"
                            v-model="piscine"
                          />
                          <label for="piscine_non">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flow-container row white-bg mb-2 ps-0 pe-0">
                    <div class="col col-lg-6">
                      <p class="violet prompt mb-0 align-left criteria">
                        Balcon :
                      </p>
                      <div
                        class="col col-lg-12 flex justifyLeft ml-0 mr-0 ps-0 pe-0"
                      >
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Oui"
                            id="balcon_oui"
                            v-model="balcon"
                          />
                          <label for="balcon_oui">Oui</label>
                        </div>
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Non"
                            id="balcon_non"
                            v-model="balcon"
                          />
                          <label for="balcon_non">Non</label>
                        </div>
                      </div>
                    </div>
                    <div class="col col-lg-6">
                      <p class="violet prompt mb-0 align-left criteria">
                        Interphone :
                      </p>
                      <div
                        class="col col-lg-12 flex justifyLeft ml-0 mr-0 ps-0 pe-0"
                      >
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Oui"
                            id="inter_oui"
                            v-model="interphone"
                          />
                          <label for="inter_oui">Oui</label>
                        </div>
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Non"
                            id="inter_non"
                            v-model="interphone"
                          />
                          <label for="inter_non">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flow-container row white-bg mb-2 ps-0 pe-0">
                    <div class="col col-lg-6">
                      <p class="violet prompt mb-0 align-left criteria">
                        Parkings / Garages :
                      </p>
                      <div
                        class="col col-lg-12 flex justifyLeft ml-0 mr-0 ps-0 pe-0"
                      >
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Oui"
                            id="parkin_oui"
                            v-model="parking"
                          />
                          <label for="parkin_oui">Oui</label>
                        </div>
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Non"
                            id="parkin_non"
                            v-model="parking"
                          />
                          <label for="parkin_non">Non</label>
                        </div>
                      </div>
                    </div>
                    <div class="col col-lg-6">
                      <p class="violet prompt mb-0 align-left criteria">
                        Cave :
                      </p>
                      <div
                        class="col col-lg-12 flex justifyLeft ml-0 mr-0 ps-0 pe-0"
                      >
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Oui"
                            id="cave_oui"
                            v-model="cave"
                          />
                          <label for="cave_oui">Oui</label>
                        </div>
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Non"
                            id="cave_non"
                            v-model="cave"
                          />
                          <label for="cave_non">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flow-container row white-bg mb-2 ps-0 pe-0">
                    <div class="col col-lg-6">
                      <p class="violet prompt mb-0 align-left criteria">
                        Terrasse :
                      </p>
                      <div
                        class="col col-lg-12 flex justifyLeft ml-0 mr-0 ps-0 pe-0"
                      >
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Oui"
                            id="terrasse_oui"
                            v-model="terrasse"
                          />
                          <label for="terrasse_oui">Oui</label>
                        </div>
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Non"
                            id="terrasse_non"
                            v-model="terrasse"
                          />
                          <label for="terrasse_non">Non</label>
                        </div>
                      </div>
                    </div>
                    <div class="col col-lg-6">
                      <p class="violet prompt mb-0 align-left criteria">
                        Jardin :
                      </p>
                      <div
                        class="col col-lg-12 flex justifyLeft ml-0 mr-0 ps-0 pe-0"
                      >
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Oui"
                            id="jardin_oui"
                            v-model="jardin"
                          />
                          <label for="jardin_oui">Oui</label>
                        </div>
                        <div class="pretty-radio me-3">
                          <input
                            type="radio"
                            value="Non"
                            id="jardin_non"
                            v-model="jardin"
                          />
                          <label for="jardin_non">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row alignRight mt-4 absoluteBottom"
                  v-if="offres == true"
                >
                  <button class="btn-visual prompt ms-auto" @click="search">
                    Lancer ma recherche
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-11 ps-0" v-if="oStat == true">
          <analyse
            @splitScreen="splitScreen"
            @closeSplit="closeSplit"
            ref="analyse"
            :data_details="data_details"
            :data_chart="data_chart"
            @openMarker="openMarker"
            @openPopup="openPopup"
            @showLoader="showLoader"
            @hideLoader="hideLoader"
          >
          </analyse>
        </div>
      </div>

      <sauvegarde
        ref="sauve"
        v-if="save == true"
        @closeModal="closeModal"
        @validateModal="validateSearchTitle"
        @createNew="validateSearch"
        :changePage="changePage"
        :router="$router"
      ></sauvegarde>
      <download
        v-if="down == true"
        @closeModal="closeDown"
        @validateModal="validateDowload"
      ></download>

      <markerpopup
        :data="dataToShownInModal"
        v-if="showPopup == true"
        @closeModalPopup="closeModalPopup"
        @desactiver="desactiver"
      ></markerpopup>

      <div
        v-if="data_chart.length !== 0"
        class="ps-0 pe-0 pt-0 pb-0"
        id="pdf"
        style="background-color:#fff;width: 21cm;margin: 30mm 45mm 30mm 45mm;top: 100%;position:fixed;top:100%;"
      >
        <section
          class="row page page-1 ps-0 pe-0 pt-0 pb-0"
          id="page-1"
          style="background-color:#fff;min-height:600px;"
        >
          <div
            class="white-container wt-c ps-0 pe-0 pt-0 pb-0"
            aria-label="pdf-page-1"
            style="background-color:#fff;background-size: cover;min-height:600px;width: 100%;height: auto;min-height: auto;"
          >
            <img
              src="../assets/Image1.png"
              class="img-responsive w100"
              style="max-height:1151px;object-fit:contain;"
            />
          </div>

          <h2
            class="white-title"
            style="padding-left:15px;margin-left:10px;width:90%;margin-top:15px;"
          >
            {{ adresse }},{{ codePostal }},{{ ville }}
          </h2>
          <h3
            class="white-title"
            style="padding-left:15px;margin-left:10px;width:90%;color: #4cbfde !important;"
          >
            {{ new Date().toLocaleDateString() }}
          </h3>

          <h2
            class="white-title"
            style="padding-right:15px;margin-right:10px;width:98%;margin-top:10px;text-align:right;"
          >
            {{ user.firstname }} {{ user.lastname }}
          </h2>
          <h3
            class="white-title"
            style="padding-right:15px;margin-right:10px;width:98%;text-align:right;font-size:15px;"
          >
            {{ user.address }}
          </h3>
          <h3
            class="white-title"
            style="padding-right:15px;margin-right:10px;width:98%;text-align:right;font-size:15px;"
          >
            Tel : {{ user.phone }}
          </h3>
          <h3
            class="white-title"
            style="padding-right:15px;margin-right:10px;width:98%;text-align:right;font-size:15px;"
          >
            Email : {{ user.society_email }}
          </h3>

          <div style="max-width: 200px;margin-left: 15px;margin-top:100px;">
            <svg
              width="96"
              height="20"
              viewBox="0 0 96 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.1769 19.5863H22.6219L30.0489 12.4L22.8585 5.42468H29.8096L33.5368 9.13427L37.264 5.42468H43.8657L36.7359 12.2959L44.1105 19.5863H37.4483L33.2837 15.5343L29.1769 19.5863Z"
                fill="#363660"
              />
              <path
                d="M60.5295 19.5863H52.8633L42.6361 0H49.6119L56.8546 14.4L63.6488 0H70.047L60.5295 19.5863Z"
                fill="#363660"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M79.1766 19.1534C80.2329 18.6913 80.9811 18.1863 81.4212 17.6384C81.6247 18.937 82.3702 19.5826 83.6575 19.5753H88.4383V15.7397H87.6736C87.1784 15.7397 86.9336 15.5041 86.9336 15.0301V9.60548C86.9402 9.17379 86.9043 8.7425 86.8263 8.31781C86.7143 7.87832 86.5068 7.46869 86.2184 7.11781C85.8512 6.64152 85.3698 6.2647 84.8183 6.02192C83.9887 5.67525 83.1179 5.43586 82.2271 5.30959C80.8805 5.10051 79.5188 5.00337 78.1561 5.01918H76.7037C75.3876 5.00248 74.0729 5.11351 72.7784 5.35068C71.91 5.49123 71.0679 5.76187 70.2808 6.15342C69.7471 6.44094 69.2862 6.84568 68.9329 7.33699C68.6459 7.70718 68.4389 8.13254 68.325 8.5863C68.2497 8.99012 68.2138 9.40025 68.2177 9.81096V9.86301H73.6917C73.7017 9.7475 73.721 9.63298 73.7494 9.52055C73.8209 9.37613 73.9136 9.24316 74.0245 9.12603C74.177 8.93665 74.3773 8.79104 74.6049 8.70411C74.9566 8.57863 75.3195 8.48689 75.6887 8.43014C76.2604 8.33555 76.8394 8.29154 77.4189 8.29863H77.5509C78.3808 8.27842 79.2105 8.34453 80.0266 8.49589C80.607 8.63014 80.9563 8.76438 81.0691 8.90411C81.1892 9.06106 81.2505 9.25487 81.2424 9.45205C81.2424 9.87123 80.9426 10.1178 80.3429 10.189L73.8402 10.7945C71.7093 10.9881 70.1808 11.4183 69.2548 12.0849C68.3287 12.7516 67.8656 13.7342 67.8656 15.0329V15.2164C67.8502 16.5162 68.4531 17.7466 69.4913 18.5342C70.5751 19.411 72.2365 19.8493 74.4756 19.8493H74.8195C76.6679 19.8475 78.1203 19.6155 79.1766 19.1534ZM81.2644 12.8466V13.2959H81.2534C81.2607 14.1781 80.7793 14.9616 79.8093 15.6466C78.8392 16.3315 77.5968 16.674 76.082 16.674C75.1651 16.674 74.505 16.516 74.1015 16.2C73.727 15.943 73.5005 15.5214 73.4936 15.0685V15.0329C73.4741 14.6805 73.6591 14.3482 73.9695 14.1781C74.4938 13.9308 75.0566 13.7742 75.6337 13.7151L81.2644 12.8466Z"
                fill="#363660"
              />
              <path d="M95.7387 0V19.5863H89.8439V0H95.7387Z" fill="#363660" />
              <g style="mix-blend-mode:multiply">
                <path
                  d="M56.8546 14.4L60.5296 19.5863L63.9707 12.5041L58.5518 10.8082L56.8546 14.4Z"
                  fill="url(#paint0_linear)"
                />
              </g>
              <rect width="20.7239" height="4.43288" fill="#363660" />
              <rect
                y="15.1562"
                width="20.7239"
                height="4.43288"
                fill="#363660"
              />
              <rect
                y="7.57806"
                width="20.7239"
                height="4.43288"
                fill="#363660"
              />
              <rect
                x="17.7971"
                y="4.43286"
                width="4.45066"
                height="4.43288"
                transform="rotate(180 17.7971 4.43286)"
                fill="#50518E"
              />
              <rect
                x="13.3492"
                y="4.43286"
                width="4.45066"
                height="4.43288"
                transform="rotate(180 13.3492 4.43286)"
                fill="#E94A73"
              />
              <rect
                x="8.89856"
                y="4.43286"
                width="4.45066"
                height="4.43288"
                transform="rotate(180 8.89856 4.43286)"
                fill="#363660"
              />
              <rect
                x="4.45068"
                y="4.43286"
                width="4.45066"
                height="4.43288"
                transform="rotate(180 4.45068 4.43286)"
                fill="#1D1D33"
              />
              <rect
                y="7.57806"
                width="4.45066"
                height="4.43288"
                fill="#50518E"
              />
              <rect
                x="4.45068"
                y="7.57806"
                width="4.45066"
                height="4.43288"
                fill="#4CBFDE"
              />
              <rect
                x="8.89856"
                y="7.57806"
                width="4.45066"
                height="4.43288"
                fill="#363660"
              />
              <rect
                x="13.3492"
                y="7.57806"
                width="4.45066"
                height="4.43288"
                fill="#1D1D33"
              />
              <rect
                x="17.7971"
                y="19.5863"
                width="4.45066"
                height="4.43288"
                transform="rotate(180 17.7971 19.5863)"
                fill="#F7AC25"
              />
              <rect
                x="13.3492"
                y="19.5863"
                width="4.45066"
                height="4.43288"
                transform="rotate(180 13.3492 19.5863)"
                fill="#3E3F71"
              />
              <rect
                x="8.89856"
                y="19.5863"
                width="4.45066"
                height="4.43288"
                transform="rotate(180 8.89856 19.5863)"
                fill="#363660"
              />
              <rect
                x="4.45068"
                y="19.5863"
                width="4.45066"
                height="4.43288"
                transform="rotate(180 4.45068 19.5863)"
                fill="#1D1D33"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="59.7643"
                  y1="12.0799"
                  x2="56.382"
                  y2="14.1988"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-opacity="0.01" />
                  <stop offset="1" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </section>
        <section
          class="row page page-3 ps-0 pe-0 pt-0 pb-0"
          id="page-3"
          style="min-height:600px;"
        >
          <div
            class="white-container wt-c ps-0 pe-0 pt-0 pb-0"
            aria-label="pdf-page-2"
            style="background-color:#fff;min-height:600px;width: 100%;height: auto;min-height: auto;"
          >
            <div
              class="row ps-0 pe-0 pt-0 pb-0 ms-3 me-3"
              style="background-color:#fff;min-height:600px;"
            >
              <div
                class="col-md-12 ps-0 pe-0 pt-0 pb-0"
                style="background-color:#fff;"
              >
                <div
                  class=" wt-c ps-0 pe-0 pt-0 pb-0"
                  style="background-color:#fff;min-height:600px;width: 100%;height: auto;min-height: auto;"
                >
                  <!--span class="lab" v-if="fiche.immobilier[0].commune.label != undefined">{{v[0].commune.label}}</span-->
                  <div
                    class="row ps-0 pe-0 pt-0 pb-0 "
                    style="background-color:#fff;margin-top:300px;"
                  >
                    <div class="flex mt-2" style="justify-content: center;">
                      <div class="rect mb-0" style="width:50%;">
                        <div class="oval">
                          <div class="oval_inner" style="left: 0.5px;">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              style="left: -1px;top: -2px;"
                            >
                              <g fill="none" fill-rule="evenodd">
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <path
                                              d="M0 0H24V24H0z"
                                              transform="translate(-294 -250) translate(132 84) translate(24 124) translate(86.904 24) translate(52 18)"
                                            />
                                            <path
                                              fill="#363660"
                                              d="M12.521 3.819l7.522 4.596c.594.363.957 1.01.957 1.707V19c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2v-8.878c0-.697.363-1.344.957-1.707L11.48 3.82c.32-.196.722-.196 1.042 0zM14 13h-4c-.552 0-1 .448-1 1v3c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-3c0-.552-.448-1-1-1z"
                                              transform="translate(-294 -250) translate(132 84) translate(24 124) translate(86.904 24) translate(52 18)"
                                            />
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <!-- Oval inner -->
                        </div>

                        <!-- Référence value -->
                        <div class="ref_value">
                          <span class="value">{{ data_details.nb_ref }}</span>
                          <span class="nb">Nombres de références</span>
                        </div>
                        <!-- end reference value-->
                      </div>
                      <div class="rect me-0  mb-0" style="width:50%;">
                        <div class="top">Moyenne</div>
                        <div class="mid row">
                          <div class="col col-lg-3">
                            <p class="val_R">N :</p>
                          </div>
                          <div class="col col-lg-8 ps-0 pe-0">
                            <p class="price">
                              {{
                                data_details.length === 0
                                  ? ""
                                  : data_details.details[1].n_value.value
                              }}
                            </p>
                            <p class="ecart">
                              ( Ecart-type :
                              {{
                                data_details.length === 0
                                  ? ""
                                  : data_details.details[1].n_value.ecart_type
                              }}
                              )
                            </p>
                          </div>
                        </div>
                        <div class="sep"></div>
                        <div class="mid row mt-0 mb-0">
                          <div class="col col-lg-3">
                            <p class="val_R">N+1:</p>
                          </div>
                          <div class="col col-lg-8 ps-0 pe-0">
                            <p class="price">
                              {{
                                data_details.length === 0
                                  ? ""
                                  : data_details.details[1].n_1_value.value
                              }}
                            </p>
                            <p class="ecart">
                              ( Ecart-type :
                              {{
                                data_details.length === 0
                                  ? ""
                                  : data_details.details[1].n_1_value
                                      .ecart_type
                              }})
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="flex mt-1" style="justify-content: center;">
                      <div class="rect  mb-0" style="width:50%;">
                        <div class="top">
                          {{
                            data_details.length === 0
                              ? "Evolution du prix/m² médian"
                              : data_details.details[5].type
                          }}
                        </div>
                        <div class="sub-top">
                          {{
                            data_details.length === 0
                              ? ""
                              : data_details.details[5].note
                          }}
                        </div>

                        <div class="mid row">
                          <div class="col col-lg-3">
                            <p class="val_R">N :</p>
                          </div>
                          <div class="col col-lg-8 ps-0 pe-0">
                            <p class="price">
                              {{
                                data_details.length === 0
                                  ? ""
                                  : data_details.details[5].n_value.value
                              }}{{
                                data_details.length === 0
                                  ? ""
                                  : data_details.details[5].n_value.unit
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="sep"></div>
                        <div class="mid row mt-2 mb-2">
                          <div class="col col-lg-3">
                            <p class="val_R">N :</p>
                          </div>
                          <div class="col col-lg-8 ps-0 pe-0">
                            <p class="price">
                              {{
                                data_details.length === 0
                                  ? ""
                                  : data_details.details[5].n_1_value.value
                              }}{{
                                data_details.length === 0
                                  ? ""
                                  : data_details.details[5].n_1_value.unit
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- end rect value -->
                      <div class="rect me-0 mb-0" style="width:50%;">
                        <div class="top">
                          {{
                            data_details.length === 0
                              ? "Evolution du prix/m² médian"
                              : data_details.details[6].type
                          }}
                        </div>
                        <div class="sub-top">
                          {{
                            data_details.length === 0
                              ? ""
                              : data_details.details[6].note
                          }}
                        </div>
                        <div class="mid row">
                          <div class="col col-lg-3">
                            <p class="val_R">N :</p>
                          </div>
                          <div class="col col-lg-8 ps-0 pe-0">
                            <p class="price">
                              {{
                                data_details.length === 0
                                  ? ""
                                  : data_details.details[6].n_value.value
                              }}{{
                                data_details.length === 0
                                  ? ""
                                  : data_details.details[6].n_value.unit
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="sep"></div>

                        <div class="mid row">
                          <div class="col col-lg-3">
                            <p class="val_R">N+1 :</p>
                          </div>
                          <div class="col col-lg-8 ps-0 pe-0">
                            <p class="price">
                              {{
                                data_details.length === 0
                                  ? ""
                                  : data_details.details[6].n_1_value.value
                              }}{{
                                data_details.length === 0
                                  ? ""
                                  : data_details.details[6].n_1_value.unit
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="row page page-3 ps-0 pe-0 pt-0 pb-0"
          id="page-3"
          style="min-height:600px;"
        >
          <div
            class="white-container wt-c ps-0 pe-0 pt-0 pb-0"
            aria-label="pdf-page-2"
            style="background-color:#fff;min-height:600px;width: 100%;height: auto;min-height: auto;"
          >
            <div
              class="row ps-0 pe-0 pt-0 pb-0 ms-3 me-3"
              style="background-color:#fff;min-height:600px;"
            >
              <div
                class="col-md-12 ps-0 pe-0 pt-0 pb-0"
                style="background-color:#fff;"
              >
                <div
                  class=" wt-c ps-0 pe-0 pt-0 pb-0"
                  style="background-color:#fff;min-height:600pxwidth: 100%;height: auto;min-height: auto;"
                >
                  <!--span class="lab" v-if="fiche.immobilier[0].commune.label != undefined">{{v[0].commune.label}}</span-->

                  <div
                    class="row ps-0 pe-0 pt-0 pb-0"
                    style="background-color:#fff;padding: 0 15px;margin-top:10px;"
                  >
                    <div class="mt-3 pt-0" style="padding: 0 20px;!important;">
                      <div
                        class="h-auto no-down me-0 mb-0 nob hpdf"
                        style="margin-right:5px;"
                      >
                        <chart
                          ref="segmentation"
                          v-if="data_chart.length !== 0"
                          :dataChart="data_chart[0]"
                          :id="'chart11000'"
                          :width="300"
                          :height="222"
                          @hoverTiks="openMarker"
                          @showLoader="showLoader"
                          @hideLoader="hideLoader"
                        ></chart>
                      </div>
                      <div
                        class="h-auto no-down me-0 mb-0 nob hpdf"
                        style="margin-right:5px;"
                      >
                        <chart
                          ref="correlation"
                          v-if="data_chart.length !== 0"
                          :dataChart="data_chart[1]"
                          :id="'chart1'"
                          :width="300"
                          :height="222"
                          @hoverTiks="openMarker"
                          @showLoader="showLoader"
                          @hideLoader="hideLoader"
                        ></chart>
                      </div>
                      <div
                        class="h-auto no-down me-0 mb-0 nob hpdf"
                        style="margin-right:5px;"
                      >
                        <chart
                          ref="surface"
                          v-if="data_chart.length !== 0"
                          :dataChart="data_chart[2]"
                          :id="'chart2'"
                          :width="1000"
                          :height="600"
                          @hoverTiks="openMarker"
                          @showLoader="showLoader"
                          @hideLoader="hideLoader"
                        ></chart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="row page page-3 ps-0 pe-0 pt-0 pb-0"
          id="page-4"
          style="min-height:600px;"
        >
          <div
            class="white-container wt-c ps-0 pe-0 pt-0 pb-0"
            aria-label="pdf-page-2"
            style="background-color:#fff;min-height:600px;width: 100%;height: auto;min-height: auto;"
          >
            <div
              class="row ps-0 pe-0 pt-0 pb-0 ms-3 me-3"
              style="background-color:#fff;min-height:600px;"
            >
              <div
                class="col-md-12 ps-0 pe-0 pt-0 pb-0"
                style="background-color:#fff;"
              >
                <div
                  class=" wt-c ps-0 pe-0 pt-0 pb-0"
                  style="background-color:#fff;min-height:600pxwidth: 100%;height: auto;min-height: auto;"
                >
                  <!--span class="lab" v-if="fiche.immobilier[0].commune.label != undefined">{{v[0].commune.label}}</span-->

                  <div
                    class="row ps-0 pe-0 pt-0 pb-0"
                    style="background-color:#fff;padding: 0 15px;margin-top:10px;"
                  >
                    <div
                      class="mt-3 pt-0 pb-"
                      style="padding: 0 20px;!important;"
                    >
                      <div class="h-auto no-down me-0 mb-0 nob hpdf">
                        <chart
                          ref="frequence"
                          v-if="data_chart.length !== 0"
                          :dataChart="data_chart[3]"
                          :id="'chart3'"
                          :width="1000"
                          :height="600"
                          @hoverTiks="openMarker"
                          @showLoader="showLoader"
                          @hideLoader="hideLoader"
                        ></chart>
                      </div>
                      <div class="h-auto no-down me-0 mb-0 nob hpdf">
                        <chart
                          ref="transaction"
                          v-if="data_chart.length !== 0"
                          :dataChart="data_chart[5]"
                          :id="'chart4'"
                          :width="1000"
                          :height="600"
                          @hoverTiks="openMarker"
                          @showLoader="showLoader"
                          @hideLoader="hideLoader"
                        ></chart>
                      </div>
                      <div class="h-auto no-down me-0 mb-0 nob hpdf">
                        <chart
                          ref="surfacet"
                          v-if="data_chart.length !== 0"
                          :dataChart="data_chart[4]"
                          :id="'chart10'"
                          :width="1000"
                          :height="600"
                          @hoverTiks="openMarker"
                          @showLoader="showLoader"
                          @hideLoader="hideLoader"
                        ></chart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="row page page-4 ps-0 pe-0 pt-0 pb-0"
          id="page-5"
          style="background-color:#fff;min-height: 1157px;height:1157px;padding-bottom:0!important;"
        >
          <div
            class="white-container wt-c ps-0 pe-0 pt-0 pb-0"
            aria-label="pdf-page-3"
            style="background-color:#fff;background-size: cover;min-height:1157px;width: 100%;height: 1157px;"
          >
            <img
              src="../assets/Image2.png"
              class="img-responsive w100"
              style="object-fit: cover;min-height:1157px;"
            />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<style scoped>
.wt-c {
  width: 100%;
  height: auto;
  min-height: auto;
}

#corps {
  overflow: visible !important;
}

#md {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: 20px;
}
#md div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  margin: 8px;
  border: 2px solid #383366;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #383366 transparent transparent transparent;
}
#md div:nth-child(1) {
  animation-delay: -0.45s;
}
#md div:nth-child(2) {
  animation-delay: -0.3s;
}
#md div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.nob .bar {
  margin-bottom: 5px !important;
}
.hpdf .bar {
  height: 365px;
}
</style>
<script>
import menu from "../components/screen/menu/menu";
import sauvegarde from "../components/screen/save-search";
import markerpopup from "../components/screen/markerPopup";
import download from "../components/screen/download";
import map from "../components/screen/map/map";
//import checkbox from "./template/checkbox";
import Swipper from "./template/Swipper";
import ModalTypeDeBien from "./template/ModalTypeDeBien";
import analyse from "./screen/analyse";
import html2PDF from "jspdf-html2canvas";

import axios from "axios";
const isVisible = (elem) =>
  !!elem &&
  !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

export default {
  components: {
    "menu-item": menu,
    sauvegarde,
    download,
    Swipper,
    ModalTypeDeBien,
    "i-map": map,
    analyse,
    markerpopup,
    chart: require("./template/chart/chart").default,
  },
  data() {
    return {
      //GLOBAL VARIABLE
      save: false,
      down: false,
      haveToSave: false,
      oSearch: false,
      interact: false,
      analyse: false,
      oStat: false,
      loader: false,
      hasSearch: false,
      menuClass: "",
      changePage: false,
      loadPdf: false,

      //SEARCH VARIABLE
      type: false,
      modalType: false,
      dataSelected: "",
      swipper: false,
      typeOf: "",
      transitions: true,
      offres: false,
      valeur: 0,
      //type: "",
      deduction: "",
      bien: "all",
      unique: "all",
      typeBien: "",
      adresse: "",
      codePostal: "",
      ville: "",
      rayon: "",
      sansLimit: 0,
      surfaceMin: "",
      surfaceMax: "",
      prixMin: "",
      prixMax: "",
      anneeMin: "",
      anneeMax: "",
      etageMin: "",
      etageMax: "",
      bienType: "",
      piscine: "",
      balcon: "",
      interphone: "",
      parking: "",
      terrasse: "",
      jardin: "",
      cave: "",
      adresseError: false,
      villeError: false,
      codeError: false,

      latitude: "",
      longitude: "",

      //analyse VARIABLE
      analyseSplit: false,
      max: {},

      //MAP VARIABLE
      drawerEnable: false,
      polygon: [],
      adresseHidden: "",
      center: [],

      //global
      user: {},
      data_details: [],
      data_chart: [],
      data_map: [],
      showPopup: false,
      dataToShownInModal: null,
      deactivatedIds: [],
      options: [
        {
          id: 1,
          label: "Maison",
          value: "Maison",
          flag: null,
          checked: false,
        },
        {
          id: 2,
          label: "Appartement",
          value: "Appartement",
          flag: null,
          checked: false,
        },
        {
          id: 3,
          label: "Locaux professionnels",
          value: "Locaux professionnels",
          flag: "Transactions",
          checked: false,
        },
        {
          id: 4,
          label: "Commerces",
          value: "Commerces",
          flag: "Offres",
          checked: false,
        },
        {
          id: 5,
          label: "Bureaux",
          value: "Bureaux",
          flag: "Offres",
          checked: false,
        },
        {
          id: 6,
          label: "Bâtiments d'activités",
          value: "Bâtiments d'activités",
          flag: "Offres",
          checked: false,
        },
      ],
      searchTitle: "",
      pointFromPlaceId: [],
    };
  },
  watch: {
    offres: function(newOffres) {
      if (newOffres == false) {
        this.anneeMax = "";
        this.anneeMin = "";
        this.etageMax = "";
        this.etageMin = "";
        this.bienType = "";
        this.piscine = "";
        this.balcon = "";
        this.interphone = "";
        this.parking = "";
        this.terrasse = "";
        this.cave = "";
        this.jardin = "";
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasSearch == true) {
      this.save = true;
      this.changePage = false;
      localStorage.setItem("to", to.path);
      localStorage.setItem("from", from.path);
    } else {
      next();
    }
  },
  mounted() {
    if (localStorage.getItem("uitoken") != null) {
      const user = JSON.parse(localStorage.getItem("uitoken"));
      this.user = user;
    }
    const self = this;
    //CHECK IF PARAMS SEARCH EXIST AND GET IT FROM THE DATABASE TO AUTOFILL THE RESEARCH
    const query = this.$route.query.search;
    if (query != null || query != undefined) {
      this.loader = true;
      axios({
        method: "GET",
        url: "https://api.exval.wertkt.com/api/v1/bien/data-map/search/" + query,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: this.user.access_token,
        },
      })
        .then(function(response) {
          self.storeDataFromApi(response);
          localStorage.setItem("search", response.data.title);
          self.searchTitle = response.data.title;
          setTimeout(function() {
            self.search();
            self.hasSearch = true;
          }, 2000);
        })
        .catch(function(err) {
          console.log(err);
          self.loader = false;
        });
    }
    const edit = this.$route.query.edit;
    if (edit != null || edit != undefined) {
      axios({
        method: "GET",
        url: "https://api.exval.wertkt.com/api/v1/bien/data-map/search/" + edit,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: this.user.access_token,
        },
      })
        .then(function(response) {
          self.storeDataFromApi(response);
          localStorage.setItem("edit", response.data.title);
          self.searchTitle = response.data.title;

          self.openSearch();
          self.hasSearch = false;
        })
        .catch(function(err) {
          console.log(err);
        });
    }
  },
  methods: {
    storeDataFromApi(response) {
      const self = this;
      const type = response.data.type;
      self.center = {
        lat: response.data.latitude,
        lng: response.data.longitude,
      };
      self.$refs.iMap.updateCenter({
        lat: response.data.latitude,
        lng: response.data.longitude,
      });

      if (response.data.point != undefined) {
        self.pointFromPlaceId = response.data.point;
      }

      if (response.data.type != undefined) {
        self.dataSelected = typeof type === "string" ? type : type.join(",");
      }
      if (self.dataSelected != "") {
        const options = self.options.map(function(item) {
          if (type.includes(item.value)) {
            if (item.checked == false) item.checked = true;
            else item.checked = false;
          }
          return item;
        });
        self.options = [...options];
      }
      self.swipper = response.data.mode == "vv" ? false : true;
      self.ville = response.data.ville;
      self.codePostal = response.data.postal_code;
      self.adresse = response.data.adresse;
      self.transitions = response.data.source == "dvf" ? true : false;
      self.offres = response.data.source == "offres" ? true : false;
      if (response.data.rayon != undefined) {
        self.rayon = response.data.rayon;
      }
      if (response.data.surfaceMin != undefined) {
        self.surfaceMin = response.data.surfaceMin;
      }
      if (response.data.surfaceMax != undefined) {
        self.surfaceMax = response.data.surfaceMax;
      }

      if (response.data.sellingPriceMinBySquare != undefined) {
        self.prixMin = response.data.sellingPriceMinBySquare;
      }

      if (response.data.sellingPriceMaxBySquare != undefined) {
        self.prixMax = response.data.sellingPriceMaxBySquare;
      }

      if (response.data.unlimitedRadius != undefined) {
        self.sansLimit = response.data.unlimitedRadius == true ? "1" : "0";
      }

      if (response.data.anneeMin != undefined) {
        self.anneeMin = response.data.anneeMin;
      }

      if (response.data.anneeMax != undefined) {
        self.anneeMax = response.data.anneeMax;
      }

      if (response.data.etageMin != undefined) {
        self.etageMin = response.data.etageMin;
      }

      if (response.data.etageMax != undefined) {
        self.etageMax = response.data.etageMax;
      }

      if (response.data.bienType != undefined) {
        self.bienType = response.data.bienType;
      }

      if (response.data.piscine != undefined) {
        self.piscine = response.data.piscine;
      }

      if (response.data.balcon != undefined) {
        self.balcon = response.data.balcon;
      }

      if (response.data.interphone != undefined) {
        self.interphone = response.data.interphone;
      }

      if (response.data.parking != undefined) {
        self.parking = response.data.parking;
      }

      if (response.data.cave != undefined) {
        self.cave = response.data.cave;
      }

      if (response.data.terrasse != undefined) {
        self.terrasse = response.data.terrasse;
      }

      if (response.data.jardin != undefined) {
        self.jardin = response.data.jardin;
      }

      if (response.data.same_type != undefined) {
        self.bien = response.data.same_type;
      }

      if (response.data.no_limit != undefined) {
        self.valeur = response.data.no_limit == true ? 1 : 0;
      }

      if (response.data.uniq != undefined) {
        self.unique = response.data.uniq;
      }

      if (response.data.geoJSON != undefined) {
        const polygon = response.data.geoJSON.map(function(item) {
          return [item[1], item[0]];
        });
        self.polygon = polygon;

        const polyJson = response.data.geoJSON.map(function(item) {
          return {
            lat: item[1],
            lng: item[0],
          };
        });
        self.$refs.iMap.setPolygonFromAPI(polyJson);
      }
    },
    updatePoint(point) {
      this.pointFromPlaceId = point;
    },
    filterMax(datasets) {
      const tempMax = {};
      for (let d = 0; d < datasets.length; d++) {
        const month = datasets[d].id.split("-")[0];
        if (tempMax[month] !== undefined) {
          if (tempMax[month] < datasets[d].y) {
            tempMax[month] = datasets[d].y;
          }
        } else {
          tempMax[month] = datasets[d].y;
        }
      }
      this.max = tempMax;
    },
    hideOnClickOutside(element) {
      const outsideClickListener = (event) => {
        if (element.contains(event.target) == false && isVisible(element)) {
          this.hideModal();
          removeClickListener();
        }
      };
      const removeClickListener = () => {
        document.removeEventListener("click", outsideClickListener);
      };
      document.addEventListener("click", outsideClickListener);
    },
    openSauvegarde() {
      if (this.data_chart.length > 0) {
        this.save = true;
        this.changePage = true;
      }
    },
    closeModal() {
      this.save = false;
      // this.hasSearch = false
    },
    download() {
      if (this.data_chart.length > 0) {
        this.down = true;
      }
    },
    closeDown() {
      this.down = false;
    },
    hasValue(value) {
      this.save = value;
    },
    mustSave() {
      this.save = true;
    },
    showLoader() {
      this.loader = true;
    },
    hideLoader() {
      this.loader = false;
    },
    validateSearch(title) {
      this.save = false;
      this.changePage = false;
      this.loader = true;
      const filter = this.buildUrl();
      filter["title"] = title;
      filter["uid"] = this.user;
      filter["center"] =
        this.center != [] ? this.center : this.$refs.iMap.getCenter();
      filter["point"] = this.pointFromPlaceId;

      filter["adresse"] = this.adresse;

      const today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      let heure =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      filter["created_at"] = date + " " + heure;
      const self = this;

      axios({
        method: "GET",
        url:
          "https://api.exval.wertkt.com/api/v1/bien/data-map/save?params=" +
          JSON.stringify(filter),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: this.user.access_token,
        },
        params: {
          token: this.user.value,
        },
      })
        .then(function() {
          self.hasSearch = false;
          self.loader = false;
        })
        .catch(function(err) {
          console.log(err);
          self.hasSearch = false;
        });
    },
    validateSearchTitle(title) {
      this.save = false;
      this.changePage = false;
      this.loader = true;
      const filter = this.buildUrl();
      filter["title"] = title;
      filter["uid"] = this.user;
      filter["center"] =
        this.center != [] ? this.center : this.$refs.iMap.getCenter();
      filter["point"] = this.pointFromPlaceId;

      filter["adresse"] = this.adresse;

      const today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      let heure =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      filter["created_at"] = date + " " + heure;
      const self = this;

      const edit = this.$route.query.edit;
      const search = this.$route.query.search;

      if (edit != null || edit != undefined) {
        filter["id"] = edit;
        axios({
          method: "PUT",
          url:
            "https://api.exval.wertkt.com/api/v1/bien/data-map/save?params=" +
            JSON.stringify(filter),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: this.user.access_token,
          },
        })
          .then(function() {
            self.hasSearch = false;
            self.loader = false;
          })
          .catch(function(err) {
            console.log(err);
            self.hasSearch = false;
            self.loader = false;
          });
      } else if (search != null || search != undefined) {
        filter["id"] = search;
        axios({
          method: "PUT",
          url:
            "https://api.exval.wertkt.com/api/v1/bien/data-map/save?params=" +
            JSON.stringify(filter),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: this.user.access_token,
          },
        })
          .then(function() {
            self.hasSearch = false;
            self.loader = false;
          })
          .catch(function(err) {
            console.log(err);
            self.hasSearch = false;
            self.loader = false;
          });
      } else {
        axios({
          method: "GET",
          url:
            "https://api.exval.wertkt.com/api/v1/bien/data-map/save?params=" +
            JSON.stringify(filter),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: this.user.access_token,
          },
        })
          .then(function() {
            self.hasSearch = false;
            self.loader = false;
          })
          .catch(function(err) {
            console.log(err);
            self.hasSearch = false;
          });
      }
    },
    validateDowload(obj) {
      //generate url here
      const { content, format } = obj;
      if (format.value == "excel") {
        const uri = this.buildUrl();
        uri["level"] = 1;

        if (this.valeur == 1) {
          uri["no_limit"] = true;
        } else if (this.valeur == 0) {
          uri["no_limit"] = false;
        }
        uri["source"] = [uri.source];

        const filter = {
          deactivatedIds: [],
          filter: uri,
          //"max" : this.max
          max: {},
        };

        if (content.value == "tableur_graphique") {
          const self = this;
          //this.oStat = true
          this.openAnalyse();
          this.loader = true;
          setTimeout(function() {
            if (self.$refs.analyse != undefined) {
              const analyseRefs = self.$refs.analyse.getRefs();
              self.$refs.analyse.openGraph();

              setTimeout(function() {
                analyseRefs.correlation.exportOnly();
                analyseRefs.surface.exportOnly();
                analyseRefs.frequence.exportOnly();
                analyseRefs.transaction.exportOnly();
                analyseRefs.surfacet.exportOnly();
                analyseRefs.segmentation.exportOnly();

                self.$notify({
                  group: "foo",
                  title: "",
                  text: "Exportation réussie",
                  classes: "elegant",
                });
              }, 1000);

              const url =
                "https://api.exval.wertkt.com/api/v1/bien/data-map/export?params=" +
                JSON.stringify(filter);
              const win = window.open(url, "_blank");
              win.focus();
              self.loader = false;
            }
          }, 1000);
        } else {
          this.loader = true;
          const url =
            "https://api.exval.wertkt.com/api/v1/bien/data-map/export?params=" +
            JSON.stringify(filter);
          const win = window.open(url, "_blank");
          win.focus();
          this.loader = false;
          this.$notify({
            group: "foo",
            title: "",
            text: "Exportation réussie",
            classes: "elegant",
          });
        }
      } else if (format.value == "pdf") {
        this.loader = true;
        const self = this;
        if (content.value == "tableur_graphique") {
          this.openAnalyse();
          setTimeout(function() {
            if (self.$refs.analyse != undefined) {
              const analyseRefs = self.$refs.analyse.getRefs();
              self.$refs.analyse.openGraph();

              setTimeout(function() {
                analyseRefs.correlation.exportOnly();
                analyseRefs.surface.exportOnly();
                analyseRefs.frequence.exportOnly();
                analyseRefs.transaction.exportOnly();
                analyseRefs.surfacet.exportOnly();
                analyseRefs.segmentation.exportOnly();
                self.loader = false;
                self.loadPdf = true;

                const elementHTML = document.getElementsByClassName("page");
                html2PDF(elementHTML, {
                  jsPDF: {
                    format: "a4",
                  },
                  imageQuality: 1,
                  margin: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  },
                  html2canvas: {
                    scrollX: 0,
                    scrollY: -2,
                    scale: 1.4,
                    dpi: 72,
                  },
                  imageType: "image/png",
                  output: `${this.adresse}_${this.codePostal}_${
                    this.ville
                  }_${new Date().getTime()}.pdf`,
                  success: function(pdf) {
                    pdf.save(this.output);
                    self.loadPdf = false;
                  },
                });
                self.$notify({
                  group: "foo",
                  title: "",
                  text: "Exportation réussie",
                  classes: "elegant",
                });
              }, 1000);
            }
          }, 1000);
        } else {
          self.loader = false;
          self.loadPdf = true;
          const elementHTML = document.getElementsByClassName("page");
          html2PDF(elementHTML, {
            jsPDF: {
              format: "a4",
            },
            imageQuality: 1,
            margin: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            html2canvas: {
              scrollX: 0,
              scrollY: -2,
              scale: 1.5,
              dpi: 72,
            },
            imageType: "image/png",
            output: `${this.adresse}_${this.codePostal}_${
              this.ville
            }_${new Date().getTime()}.pdf`,
            success: function(pdf) {
              pdf.save(this.output);
              self.loadPdf = false;
            },
          });
        }
      }
      this.down = false;
    },
    toggleModalType() {
      this.modalType = !this.modalType;
      if (this.modalType == true) {
        const element = document.getElementById("modal-container");
        this.hideOnClickOutside(element);
      }
    },
    hideModal() {
      this.modalType = false;
    },
    showModal() {
      this.modalType = true;
    },
    onChange(value) {
      const options = this.options.map(function(item) {
        if (value.includes(item.value)) {
          if (item.checked == false) item.checked = true;
          else item.checked = false;
        }
        return item;
      });
      this.options = [...options];
      let filter = this.options.filter(function(item) {
        return item.checked == true;
      });
      filter = filter.map(function(item) {
        return item.label;
      });
      this.dataSelected = filter.join(",");
    },
    openSearch() {
      this.oSearch = true;
      this.oStat = false;

      if (this.analyseSplit == true) {
        this.analyseSplit = false;

        this.$refs.analyse.closeSplit();
      }
    },
    clearPoly() {
      this.polygon = [];
    },
    savePolyJson(polygon) {
      const polyJson = polygon.map(function(item) {
        return [item[1], item[0]];
      });
      this.polygon = polyJson;
    },
    savePolygon(polygon) {
      if (polygon != null) {
        this.polygon = polygon.map(function(item) {
          const cons = [item.lng, item.lat];
          return cons;
        });
      } else {
        this.polygon = [];
      }
    },
    openMap() {
      //hide search and show map
      this.oSearch = false;
      this.oStat = false;

      if (this.analyseSplit == true) {
        this.analyseSplit = false;

        this.$refs.analyse.closeSplit();
      }
    },
    closeSplit() {
      this.analyseSplit = false;
    },
    openAnalyse() {
      //enable stat and analyse
      this.oStat = true;

      this.oSearch = false;

      if (this.drawerEnable == true) {
        this.drawerEnable = false;
        this.$refs.iMap.closeDrawingManager();
      }
    },
    adressUpdate({ location, pos }) {
      this.adresse = location.adresse;
      this.codePostal = location.code;
      this.ville = location.ville;
      this.center = pos;
    },
    openInteraction() {
      this.oStat = false;

      if (this.analyseSplit == true) {
        this.analyseSplit = false;
        this.$refs.analyse.closeSplit();
      }

      if (this.drawerEnable == false) {
        this.oSearch = true;

        this.drawerEnable = true;
        this.onSearch = true;
        this.$refs.iMap.loadMapDrawingManager();
        setTimeout(function() {
          var el = document.querySelector(
            ".leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polygon"
          );
          if (el != null) {
            el.click();
          }
        }, 300);
      } else {
        this.drawerEnable = false;
        this.onSearch = false;
        this.oSearch = false;
        this.$refs.iMap.closeDrawingManager();
      }
    },
    openTransitions() {
      this.transitions = !this.transitions;
      this.offres = false;
      if (this.transitions == false) {
        this.unique = "all";
        this.valeur = "all";
        this.bien = "all";
      }
    },
    openOffres() {
      this.transitions = false;
      this.offres = !this.offres;
    },
    onAdresseChange() {
      if (this.adresse != "") {
        this.$emit("hasValue", true);
      } else {
        this.$emit("hasValue", false);
      }
    },
    onCodeChange() {
      if (this.codePostal != "") {
        this.$emit("hasValue", true);
      } else {
        this.$emit("hasValue", false);
      }
    },
    onVilleChange() {
      if (this.ville != "") {
        this.$emit("hasValue", true);
      } else {
        this.$emit("hasValue", false);
      }
    },
    splitScreen() {
      this.analyseSplit = !this.analyseSplit;
      this.$refs.iMap.resize();
    },
    buildUrl() {
      const filter = {};
      if (this.dataSelected != []) {
        filter["type"] =
          typeof this.dataSelected === "string"
            ? this.dataSelected.split(",")
            : this.dataSelected;
      }
      filter["mode"] = this.swipper == true ? "vl" : "vv";
      if (this.transitions == true) {
        filter["source"] = "dvf";
      }
      if (this.offres == true) {
        filter["source"] = "offres";
      }
      if (this.center != []) {
        if (this.polygon.length == 0) {
          filter["latitude"] = this.pointFromPlaceId.lat;
          filter["longitude"] = this.pointFromPlaceId.lng;
        } else {
          filter["latitude"] = this.center.lat;
          filter["longitude"] = this.center.lng;
        }
      }
      if (this.valeur != null) {
        if (this.valeur == 1) {
          filter["no_limit"] = true;
        } else if (this.valeur == 0) {
          filter["no_limit"] = false;
        }
      }
      if (this.bien != null && this.bien != "") {
        filter["same_type"] = this.bien;
      }
      if (this.unique != null && this.unique != "") {
        filter["uniq"] = this.unique;
      }
      filter["postal_code"] = this.codePostal;
      if (this.polygon.length == 0) {
        filter["adresse"] = this.adresse;
      }
      filter["ville"] = this.ville;
      if (this.rayon != "") {
        filter["rayon"] = this.rayon;
      }
      if (this.sansLimit != "" && this.sansLimit != null) {
        filter["unlimitedRadius"] = this.sansLimit == "1" ? true : false;
      }
      if (this.surfaceMin != "") {
        filter["surfaceMin"] = this.surfaceMin;
      }
      if (this.surfaceMax != "") {
        filter["surfaceMax"] = this.surfaceMax;
      }
      if (this.prixMin != "") {
        filter["sellingPriceMinBySquare"] = this.prixMin;
      }
      if (this.prixMax != "") {
        filter["sellingPriceMaxBySquare"] = this.prixMax;
      }
      if (this.anneeMin != "") {
        filter["anneeMin"] = this.anneeMin;
      }
      if (this.anneeMax != "") {
        filter["anneeMax"] = this.anneeMax;
      }
      if (this.etageMin != "") {
        filter["etageMin"] = this.etageMin;
      }
      if (this.etageMax != "") {
        filter["etageMax"] = this.etageMax;
      }
      if (this.bienType != "") {
        filter["bienType"] = this.bienType;
      }
      if (this.piscine != "") {
        filter["piscine"] = this.piscine;
      }
      if (this.balcon != "") {
        filter["balcon"] = this.balcon;
      }
      if (this.interphone != "") {
        filter["interphone"] = this.interphone;
      }
      if (this.parking != "") {
        filter["parking"] = this.parking;
      }
      if (this.cave != "") {
        filter["cave"] = this.cave;
      }
      if (this.terrasse != "") {
        filter["terrasse"] = this.terrasse;
      }
      if (this.terrasse != "") {
        filter["jardin"] = this.jardin;
      }
      if (this.polygon.length > 0) {
        filter["geoJSON"] = this.polygon;
      }

      return filter;
    },

    search() {
      if (this.ville === "") {
        this.villeError = true;
      } else {
        this.villeError = false;
      }
      if (this.adresse === "" && this.polygon === []) {
        this.adresseError = true;
      } else {
        this.adresseError = false;
      }
      if (this.codePostal === "") {
        this.codeError = true;
      } else {
        this.codeError = false;
      }

      if (
        this.villeError == false &&
        this.adresseError == false &&
        this.codeError == false
      ) {
        this.loader = true;
        const filter = this.buildUrl();
        filter["level"] = 1;

        this.$refs.iMap.clearMarkers();
        let url = JSON.stringify(filter);
        const self = this;
        axios
          .get("https://api.exval.wertkt.com/api/v1/bien/data-map", {
            params: {
              filter: url,
              token: this.user.value,
            },
            headers: {
              Accept: "application/json",
              Authorization: this.user.access_token,
            },
          })
          .then(function(data) {
            self.loader = false;

            if (data.data.tooManyData != undefined) {
              self.hasSearch = true;
              self.$notify({
                group: "foo",
                title: "Attention",
                type: "warn",
                text: data.data.message,
                classes: "elegant",
              });
            } else {
              self.hasSearch = true;
              setTimeout(function() {
                self.$refs.iMap.setMarker(data.data.data.data_map);
              }, 300);
              self.data_chart = data.data.data.data_chart;
              self.data_details = data.data.data.data_details;
              self.data_map = data.data.data.data_map;
              const ct = self.$refs.iMap.getCenterCentroid();
              if (ct.lat == 0 && ct.lng == 0) {
                self.$refs.iMap.updateCenterOnly(self.pointFromPlaceId, 15);
              } else {
                self.$refs.iMap.updateCenterOnly(ct, 16);
              }
              if (self.data_chart[1].regressions != undefined) {
                self.filterMax(self.data_chart[1].regressions);
              }
              const ref = document.getElementById("ref");
              if (self.data_details.length != 0) {
                ref.innerHTML = self.data_details.nb_ref;
              } else {
                ref.innerHTML = "0";
              }
            }
          })
          .catch(function(err) {
            self.loader = false;
            const ref = document.getElementById("ref");
            ref.innerHTML = "0";

            console.log(err);
          });
      }
    },
    onSwippe() {
      this.swipper = !this.swipper;
    },
    clearForm() {
      this.villeError = false;
      this.adresseError = false;
      this.codeError = false;
      this.swipper = false;
      this.transitions = true;
      this.offres = false;
      this.typeOf = "";
      this.valeur = 0;
      this.dataSelected = "";
      //type: "",
      this.deduction = "";
      this.bien = "all";
      this.unique = "all";
      this.typeBien = "";
      this.adresse = "";
      this.codePostal = "";
      this.ville = "";
      this.rayon = "";
      this.sansLimit = "";
      this.surfaceMin = "";
      this.surfaceMax = "";
      this.prixMin = "";
      this.prixMax = "";
      this.anneeMin = "";
      this.anneeMax = "";
      this.etageMin = "";
      this.etageMax = "";
      this.bienType = "";
      this.piscine = "";
      this.balcon = "";
      this.interphone = "";
      this.parking = "";
      this.terrasse = "";
      this.jardin = "";
      this.cave = "";

      const options = this.options.map(function(item) {
        item.checked = false;
        return item;
      });
      this.options = [...options];
    },
    clearPolygon() {
      if (this.drawerEnable == true) {
        this.$refs.iMap.clearPolygon();
        this.polygon = [];
      }
    },
    openPopup(index) {
      const mark = this.data_map.filter(function(item) {
        return item.id == index;
      }, []);
      if (mark.length != 0) {
        this.showPopup = true;
        this.dataToShownInModal = mark[0];
      }
    },
    closeModalPopup() {
      this.showPopup = false;
      this.dataToShownInModal = null;
    },
    desactiver(data) {
      const f = this.buildUrl();
      this.deactivatedIds.push(data.id);
      this.showPopup = false;
      this.dataToShownInModal = null;
      this.loader = true;
      const self = this;
      let deactivatedStr = "";
      for (let i = 0; i < this.deactivatedIds.length; i++) {
        deactivatedStr +=
          (i != 0 ? "&" : "") + "deactivatedIds[]=" + this.deactivatedIds[i];
      }
      axios
        .get(
          `https://api.exval.wertkt.com/api/v1/bien/data-map?${deactivatedStr}&filter=${JSON.stringify(
            f
          )}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: this.user.access_token,
            },
          }
        )
        .then(function(data) {
          self.loader = false;

          if (data.data.tooManyData != undefined) {
            self.hasSearch = true;
            self.$notify({
              group: "foo",
              title: "Attention",
              type: "warn",
              text: data.data.message,
              classes: "elegant",
            });
          } else {
            self.hasSearch = true;

            self.$refs.iMap.setMarker([
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
            ]);
            self.data_chart = [];
            self.data_details = {};
            self.data_map = [];

            setTimeout(function() {
              self.$refs.iMap.setMarker(data.data.data.data_map);
            }, 300);
            setTimeout(function() {
              self.data_chart = data.data.data.data_chart;
              self.data_details = data.data.data.data_details;
              self.data_map = data.data.data.data_map;
              const ct = self.$refs.iMap.getCenterCentroid();
              if (ct.lat == 0 && ct.lng == 0) {
                self.$refs.iMap.updateCenterOnly(self.pointFromPlaceId, 15);
              } else {
                self.$refs.iMap.updateCenterOnly(ct, 16);
              }
              if (self.data_chart[1].regressions != undefined) {
                self.filterMax(self.data_chart[1].regressions);
              }
              const ref = document.getElementById("ref");
              if (self.data_details.length != 0) {
                ref.innerHTML = self.data_details.nb_ref;
              } else {
                ref.innerHTML = "0";
              }
            }, 200);
          }
        })
        .catch(function(err) {
          self.loader = false;
          const ref = document.getElementById("ref");
          ref.innerHTML = "0";

          console.log(err);
        });
    },
    buildFilter() {
      let filter = "";

      if (this.dataSelected != []) {
        filter +=
          "filter[type][]=" +
          (typeof this.dataSelected === "string"
            ? this.dataSelected.split(",")
            : this.dataSelected);
      }
      if (this.valeur != null) {
        if (this.valeur == 1) {
          filter += "&filter[no_limit]=true";
        } else if (this.valeur == 0) {
          filter += "&filter[no_limit]=false";
        }
      }
      filter += "&filter[level] = 1";
      if (this.transitions == true) {
        filter += "&filter[source][]=dvf";
      }
      if (this.offres == true) {
        filter += "&filter=[source][]=offres";
      }
      if (this.center != []) {
        if (this.polygon.length == 0) {
          filter += "&filter[latitude]=" + this.pointFromPlaceId.lat;
          filter += "&filter[longitude]=" + this.pointFromPlaceId.lng;
        } else {
          filter += "&filter[latitude]=" + this.center.lat;
          filter += "&filter[longitude]=" + this.center.lng;
        }
      }
      if (this.bien != null && this.bien != "") {
        filter += "&filter[same_type]=" + this.bien;
      }
      if (this.unique != null && this.unique != "") {
        filter += "&filter[uniq]=" + this.unique;
      }
      filter += "&filter[postal_code]=" + this.codePostal;
      filter += "&filter[adresse]=" + this.adresse;
      filter += "&filter[ville]= " + this.ville;
      if (this.rayon != "") {
        filter += "&filter[rayon]=" + this.rayon;
      }
      if (this.sansLimit != "" && this.sansLimit != null) {
        filter +=
          "&filter[unlimitedRadius]=" + this.sansLimit == "1" ? true : false;
      }

      if (this.surfaceMin != "") {
        filter += "&filter[surfaceMin]=" + this.surfaceMin;
      }
      if (this.surfaceMax != "") {
        filter += "&filter[surfaceMax]=" + this.surfaceMax;
      }
      if (this.prixMin != "") {
        filter += "&filter[sellingPriceMinBySquare]=" + this.prixMin;
      }
      if (this.prixMax != "") {
        filter += "&filter[sellingPriceMaxBySquare]=" + this.prixMax;
      }

      if (this.anneeMin != "") {
        filter += "&filter[anneeMin]=" + this.anneeMin;
      }
      if (this.anneeMax != "") {
        filter += "&filter[anneeMax]=" + this.anneeMax;
      }
      if (this.etageMin != "") {
        filter += "&filter[etageMin]=" + this.etageMin;
      }
      if (this.etageMax != "") {
        filter += "&filter[etageMax]=" + this.etageMax;
      }
      if (this.bienType != "") {
        filter += "&filter[bienType]=" + this.bienType;
      }
      if (this.piscine != "") {
        filter += "&filter[piscine]=" + this.piscine;
      }
      if (this.balcon != "") {
        filter += "&filter[balcon]=" + this.balcon;
      }
      if (this.interphone != "") {
        filter += "&filter[interphone]=" + this.interphone;
      }
      if (this.parking != "") {
        filter += "&filter[parking]=" + this.parking;
      }
      if (this.cave != "") {
        filter += "&filter[cave]=" + this.cave;
      }
      if (this.terrasse != "") {
        filter += "&filter[terrasse]=" + this.terrasse;
      }
      if (this.terrasse != "") {
        filter += "&filter[jardin]=" + this.jardin;
      }

      if (this.polygon.length > 0) {
        for (let i = 0; i < this.polygon.length; i++) {
          filter += "&filter[geoJSON][" + i + "]=" + this.polygon[i][0];
          filter += "&filter[geoJSON][" + i + "]=" + this.polygon[i][1];
        }
      }
      return filter;
    },
    openMarker(index) {
      if (this.analyseSplit == true) {
        const marker = this.$refs.iMap.getMarkers(index);
        const refMarker = this.$refs.iMap.getSpecialRefs();
        this.$refs.iMap.showSpecialMarker();
        this.$refs.iMap.updateCenterSpecial(marker.position);
        refMarker.mapObject.openPopup();
        this.$refs.iMap.toggleInfoWindow(marker, index);
      }
    },
    updateCenter(center) {
      this.center = center;
    },
  },
};
</script>
