<template>
  <div id="corps">
    <div id="header">
      <nav class="navbar navbar-expand-lg navbar-light bg-light 373563" v-if="path !== '/login'">
        <router-link class="navbar-brand" to="/">
          <svg width="96" height="20" viewBox="0 0 96 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.1769 19.5863H22.6219L30.0489 12.4L22.8585 5.42468H29.8096L33.5368 9.13427L37.264 5.42468H43.8657L36.7359 12.2959L44.1105 19.5863H37.4483L33.2837 15.5343L29.1769 19.5863Z" fill="#363660"/>
            <path d="M60.5295 19.5863H52.8633L42.6361 0H49.6119L56.8546 14.4L63.6488 0H70.047L60.5295 19.5863Z" fill="#363660"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M79.1766 19.1534C80.2329 18.6913 80.9811 18.1863 81.4212 17.6384C81.6247 18.937 82.3702 19.5826 83.6575 19.5753H88.4383V15.7397H87.6736C87.1784 15.7397 86.9336 15.5041 86.9336 15.0301V9.60548C86.9402 9.17379 86.9043 8.7425 86.8263 8.31781C86.7143 7.87832 86.5068 7.46869 86.2184 7.11781C85.8512 6.64152 85.3698 6.2647 84.8183 6.02192C83.9887 5.67525 83.1179 5.43586 82.2271 5.30959C80.8805 5.10051 79.5188 5.00337 78.1561 5.01918H76.7037C75.3876 5.00248 74.0729 5.11351 72.7784 5.35068C71.91 5.49123 71.0679 5.76187 70.2808 6.15342C69.7471 6.44094 69.2862 6.84568 68.9329 7.33699C68.6459 7.70718 68.4389 8.13254 68.325 8.5863C68.2497 8.99012 68.2138 9.40025 68.2177 9.81096V9.86301H73.6917C73.7017 9.7475 73.721 9.63298 73.7494 9.52055C73.8209 9.37613 73.9136 9.24316 74.0245 9.12603C74.177 8.93665 74.3773 8.79104 74.6049 8.70411C74.9566 8.57863 75.3195 8.48689 75.6887 8.43014C76.2604 8.33555 76.8394 8.29154 77.4189 8.29863H77.5509C78.3808 8.27842 79.2105 8.34453 80.0266 8.49589C80.607 8.63014 80.9563 8.76438 81.0691 8.90411C81.1892 9.06106 81.2505 9.25487 81.2424 9.45205C81.2424 9.87123 80.9426 10.1178 80.3429 10.189L73.8402 10.7945C71.7093 10.9881 70.1808 11.4183 69.2548 12.0849C68.3287 12.7516 67.8656 13.7342 67.8656 15.0329V15.2164C67.8502 16.5162 68.4531 17.7466 69.4913 18.5342C70.5751 19.411 72.2365 19.8493 74.4756 19.8493H74.8195C76.6679 19.8475 78.1203 19.6155 79.1766 19.1534ZM81.2644 12.8466V13.2959H81.2534C81.2607 14.1781 80.7793 14.9616 79.8093 15.6466C78.8392 16.3315 77.5968 16.674 76.082 16.674C75.1651 16.674 74.505 16.516 74.1015 16.2C73.727 15.943 73.5005 15.5214 73.4936 15.0685V15.0329C73.4741 14.6805 73.6591 14.3482 73.9695 14.1781C74.4938 13.9308 75.0566 13.7742 75.6337 13.7151L81.2644 12.8466Z" fill="#363660"/>
            <path d="M95.7387 0V19.5863H89.8439V0H95.7387Z" fill="#363660"/>
            <g style="mix-blend-mode:multiply">
            <path d="M56.8546 14.4L60.5296 19.5863L63.9707 12.5041L58.5518 10.8082L56.8546 14.4Z" fill="url(#paint0_linear)"/>
            </g>
            <rect width="20.7239" height="4.43288" fill="#363660"/>
            <rect y="15.1562" width="20.7239" height="4.43288" fill="#363660"/>
            <rect y="7.57806" width="20.7239" height="4.43288" fill="#363660"/>
            <rect x="17.7971" y="4.43286" width="4.45066" height="4.43288" transform="rotate(180 17.7971 4.43286)" fill="#50518E"/>
            <rect x="13.3492" y="4.43286" width="4.45066" height="4.43288" transform="rotate(180 13.3492 4.43286)" fill="#E94A73"/>
            <rect x="8.89856" y="4.43286" width="4.45066" height="4.43288" transform="rotate(180 8.89856 4.43286)" fill="#363660"/>
            <rect x="4.45068" y="4.43286" width="4.45066" height="4.43288" transform="rotate(180 4.45068 4.43286)" fill="#1D1D33"/>
            <rect y="7.57806" width="4.45066" height="4.43288" fill="#50518E"/>
            <rect x="4.45068" y="7.57806" width="4.45066" height="4.43288" fill="#4CBFDE"/>
            <rect x="8.89856" y="7.57806" width="4.45066" height="4.43288" fill="#363660"/>
            <rect x="13.3492" y="7.57806" width="4.45066" height="4.43288" fill="#1D1D33"/>
            <rect x="17.7971" y="19.5863" width="4.45066" height="4.43288" transform="rotate(180 17.7971 19.5863)" fill="#F7AC25"/>
            <rect x="13.3492" y="19.5863" width="4.45066" height="4.43288" transform="rotate(180 13.3492 19.5863)" fill="#3E3F71"/>
            <rect x="8.89856" y="19.5863" width="4.45066" height="4.43288" transform="rotate(180 8.89856 19.5863)" fill="#363660"/>
            <rect x="4.45068" y="19.5863" width="4.45066" height="4.43288" transform="rotate(180 4.45068 19.5863)" fill="#1D1D33"/>
            <defs>
            <linearGradient id="paint0_linear" x1="59.7643" y1="12.0799" x2="56.382" y2="14.1988" gradientUnits="userSpaceOnUse">
            <stop stop-opacity="0.01"/>
            <stop offset="1"/>
            </linearGradient>
            </defs>
          </svg>
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto">
            <li class="nav-item active">
              <router-link class="nav-link" to="/">Références</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/analyse-marche"
                >Analyse de marché</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/recherches">Mes recherches</router-link>
            </li>
          </ul>
          <div>
            <ul class="navbar-nav my-2 my-lg-0">
              <li class="nav-item active">
                <a class="nav-link" href="#">FAQ</a>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/dashboard">Dashboard</router-link>
              </li>
              
              <li class="nav-item" style="position: relative;">
                <a class="nav-link profil dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" href="#" v-if="user != null">{{user.firstname.charAt(0)}}{{user.lastname.charAt(0)}}</a>
                <ul class="dropdown-menu">
                  <li><router-link class="dropdown-item" to="/mon-compte">Mon compte</router-link></li>
                  <li><a class="dropdown-item  dropdown-menu-end" href="@" @click.prevent="logout">Se déconnecter</a></li>
              </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div>
      <div class="container-fluid ps-0 pe-0">
        <router-view/>
      </div>
    </div>
    <a id="target" style="width:1024px;height:730px;    padding: 24px;"></a>
    <div class="top"></div>
    <div id="chartCont" style="padding:10px;"><div></div></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      path: this.$route.path,
      user: null,
    }
  },
  mounted() {
    if(localStorage.getItem('uitoken') != null) {
        const user = JSON.parse(localStorage.getItem('uitoken'))
        this.user = user
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('uitoken')
      window.location.href = '/login';
    }
  }
}
</script>


<style>
.bg-light {
  background: transparent!important;
}
body {
  background-color: #24242417;
  background-image: url('./assets/shape.png');
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 100vh;

}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
#navbarNav li a {
  font-family: Prompt;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.19px;
  color: #373563;
  margin: 7px 24px 7px 0;
}
.profil {
  padding: 6px 14px 7px;
  border-radius: 17.5px;
  background-color: #373563;
  margin-right: 0;
  color: #fff !important;
  width: 51px;
  text-align: center;
  text-transform: uppercase;
}
.map {
    width: 100%;
    height: 100%;
    max-height: 80vh;
    position: relative;
    z-index: 1;
}
.body {
}
.bg-copy {
  width: 72px;
  height: 272px;
  margin: 0 20px 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0.5px 2px 0 rgba(96, 97, 112, 0.16);
  background-color: #ffffff;
  position: relative;
  z-index: 9;
}
.bg {
  width: 32px;
  height: 32px;
  margin: 0 0 8px;
  padding: 4px;
  border-radius: 8px;
  border: solid 1px #e6e6f4;
}
.last-child {
  width: 72px;
  height: 62px;
  margin: 0 20px 0 auto;
  padding: 15px 12px 11px;
  border-radius: 10px;
  box-shadow: 0 0.5px 2px 0 rgba(96, 97, 112, 0.16);
  background-color: #3d42df;
  position: relative;
  top: -12px;
  text-align: center;
}
.bg a {
    position: relative;
  left: -1px;
  top: -2px;
}
.ref {
  width: 100%;
  height: 28px;
  margin: 0 auto 8px auto;
  font-family: Prompt;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.rfrences {
  width: 48px;
  height: 12px;
  margin: 24px 0 0;
  font-family: Prompt;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  position: relative;
  top: -11px;
}
.white-container {
 width: 440px;
  height: 80vh;
  min-height: 640px;
  margin: 0 0 ;
  padding: 24px 24px 24px 23px;
  border-radius: 6px;
  box-shadow: 0 0.5px 2px 0 rgba(96, 97, 112, 0.16);
  background-color: #ffffff;
  
  position: relative;
  z-index: 8;
}
.white-title {
    width: 249px;
    height: 30px;
    margin: 0px 4px 0 0px;
    font-family: Prompt;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #363660;
}
.selector {
  max-width: 229px;
  height: 39px;
  margin: 10px auto 16px 0;
  padding: 0px;
  border-radius: 10px;
  box-shadow: 0 1px 10px 5px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
}
.selector_child {
  width: 76px;
  height: 24px;
  margin: 5px 0 5px 20px;
  font-family: Prompt;
  font-size: 14px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.01px;
  color: #1c1c28;
}
.selector_visual.actif {
  background-color: #4cbfde !important;
}
.selector_visual.actif .content {
  color: #fff;
}
.selector_visual {
  width: 114px;
  height: 34px;
  margin: 0 0 0 0;
  padding: 5px 20px;
  border-radius: 8px;
  background-color: #fff;
  background: #fff;
  font-family: "Prompt";
  position: relative;
  top: 6px;
  cursor:pointer;
      margin-left: 3px;
}
.selector_visual.not {
  padding-left: 9px;
  padding-top: 7px;
  padding-bottom: 7px;
      margin-left: 3px;
}
.selector_visual .content {
  color: #fff;
  font-family: "Prompt";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: -0.01px;
  color: #ffffff;
}
.selector_visual.not .content {
  color: #000;
    font-family: Prompt;
  font-size: 14px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.01px;
  color: #1c1c28;
}
.flex {
  display: flex;
}
.rect {
  width: 30%;
  /*height: 155px;*/
  margin: 0 16px 20px 0;
  padding: 19px;
  border-radius: 8px;
  border: solid 1px #e6e6f4;
  background-color: #ffffff;
}
.oval {
  width: 60px;
  height: 60px;
  margin: 0 38px 2px 34px;
  padding: 6px;
  border: solid 1px #ffaa00;
  border-radius: 50%;
  margin: 0 auto;
}
.oval_inner {
  width: 47px;
  height: 47px;
  padding: 12px;
  background-color: #ffaa00;
  border-radius: 50%;
  position: relative;
  left: -0.5px;
}
.oval_inner svg {
  position: relative;
  left: -2px;
  top: -2px;
}
.ref_value .value {
  margin: 10px auto 2px auto;
  font-family: Prompt;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.02px;
  text-align: right;
  color: #363660;
  display: block;
  text-align: center;
}
.ref_value .nb {
  margin: 29px 0 0;
  font-family: Prompt;
  font-size: 12px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363660;
}
.top {
  font-family: Prompt;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363660;
  text-align: left;
  margin-bottom: 2px;
}
.sub-top {
  font-family: Prompt;
  font-size: 10px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.01px;
  color: #363660;
  text-align: left;
  margin-bottom: 10px;
}
.mid {
  margin-top: 10px;
}
.val_R {
  font-family: Prompt;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.01px;
  color: #363660;
}
.price {
  font-family: Prompt;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.01px;
  color: #363660;
  text-align: left;
  margin-bottom: 3px;
}
.ecart {
  font-family: Prompt;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.01px;
  color: #099fb9;
  text-align: left;
  margin-bottom: 3px;
}
.sep {
  height: 1px;
  background-color: #f1f1f1;
  margin-bottom: 10px;
}
#map {
  width: 100%;
  height: 100%;
}
.message {
  width: 40px;
  height: 40px;
  padding: 8px;
  background-color: #363660;
  position: fixed;
    bottom: 9%;
    border-radius: 50%;
    left: 3.6%;
}
.doted {
    padding: 5px 74px 5px 75px;
    border-radius: 4px;
    border: dashed 1px #cecfd9;
    width: 392px;
    height: 32px;
    margin: 0 0;
    padding: 5px 25px 5px 25px;
    border-radius: 4px;
}
.valeurVenal {
    width: 97px;
    height: 18px;
    margin: 2px 16px 2px 0;
    font-family: Prompt;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999999;
}
.clickSep {
  padding: 6px 9px 6px 27px;
  border-radius: 12px;
  border: solid 1px #00c1e2;
  background-color: #ffffff;
  margin: 0 10px;
}
.flex-row .doted {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: space-around;
  justify-content: center;
}
.valeurVenal.active {
  color: "#00c1e2" !important;
}
.choiceSep {
  width: 4px;
  height: 10px;
  border-radius: 1px;
  background-color: #00c1e2;
}
.customCheck {
  width: 16px;
  height: 16px;
  margin: 1px 8px 11px 0;
  padding: 1px 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(96, 97, 112, 0.16);
  border: solid 1px #00c4e6;
  background-color: #ffffff;
  background-color: #ffffff;
}
.active .checked {
  width: 5px;
  height: 3px;
  padding: 0px 4px;
  border-radius: 2px;
  background-color: #00c1e2;
}
.chooseBetween {
    margin-top: 11px;
    padding-left: 4px;
}
.prompt {
  font-family: "Prompt";
}
.chooseBetween .active .prompt {
  color: #00c1e2 !important;
}
.flow {
  margin-top: 10px;
}
.flow-container {
  margin: 5px auto;
  padding: 5px 10px;
  /* opacity: 0.1; */
  border-radius: 4px;
  background-color: #00c1e23d;
}
.mqt {
  height: 28px;
  padding-top:0!important;
  padding-bottom:0!important;
  margin-top:0!important;
  margin-bottom:0!important;
}
.violet {
  color: #3d42df;
}
.justifyRight {
  justify-content: flex-end;
}

.justifyLeft {
  justify-content: flex-start;
}
.align-left {
  text-align: left;
}
.white-bg {
  background-color: #fff;
}
.visual-input {
    width: 100%;
    height: 45px;
    margin: 10px 8px 3px 0;
    padding: 13px 16px 14px 16px;
    border-radius: 4px;
    border: solid 1px #e3e3e3;
    background-color: #ffffff;
}
.btn-visual {
    padding: 4px 24px;
    border-radius: 3px;
    background-color: #363660;
    border: none;
    color: #fff;
    width: 163px;
    height: 25px;
    margin: 0px 19px 0 0px;
    padding: 4px 24px;
    border-radius: 3px;
    background-color: #363660;
    font-family: Prompt;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
.alignRight {
  text-align: right;
}
.limit {
    margin: 0;
    padding: 13px 3px 14px;
    text-align: left;
    font-family: Prompt;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #807f99;
}
.small {
  font-family: Prompt;
  font-size: 14px;
  font-weight: 600;
  color: #363660;
}
.white-title-child {
  font-family: Prompt;
  font-size: 10px!important;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373563;
  text-align: left;
}
.offres {
  margin: 1px 0 0;
  padding: 1px 6px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16);
  background-color: #ff7f89;
  color:#fff;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  min-height: 640px;
}
.options .oval {
  width: 8px;
  height: 8px;
  margin: 4px 8px 3px 0;
  position: relative;
  top: -3px;
}
.options {
  display: flex;
  margin-top: 20px;
}
.oval.blue {
  background-color: #4b45b2;
}
.oval.orange {
  background-color: #ff7f89;
}
.oval.green {
  background-color: #00c4e6;
}
.options .prompt {
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373563;
}
.infos {
  position: absolute;
  top: 0;
  z-index: 1999;
  width: 100%;
}
.btnOptions {
  display: flex;
  position: absolute;
  right: 30px;
  top: 30px;
}
.btnOptions .double {
  width: 40px;
  height: 42px;
  padding: 8px;
  border-radius: 8px;
  border: solid 1px #e6e6f4;
  margin: 0 10px;
}
.btnOptions .double.active {
  border-width: 3px;
}
.m-chckbox--label {
  margin-bottom: 0;
}
.input-choose-type {
  border-width: 0px;
  border: none;
  outline: none;
  width: 100%;
}
.modal-type-bien {
  padding: 13px 16px 14px;
  border-radius: 4px;
  background-color: #ffffff;
  display: block;
  position: absolute;
  z-index: 1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
.transactions {
  margin: 1px 0 0;
  padding: 1px 6px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16);
  background-color: #3d42df;
  color: white;
}
.toolTip {
  position: relative;
}

.toolTip:after {
  content: attr(data-tooltip);
  width: 117px;
  height: 33px;
  margin: 0 auto;
  padding: 9px 16px 10px 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16);
  background-color: #3d42df;
  position: absolute;
  /* width: 85px; */
  left: 52px;
  /* height: 13px; */
  top: 0;
  font-family: Prompt;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  z-index: 90;
}
.toolTip:hover:after {
  visibility: visible;
  opacity: 1;
}
.trans {
width: 199px;
    height: 28px;
    margin: 0 4px 0 0;
    font-family: Prompt;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.55;
    letter-spacing: normal;
    color: #363660;

}
.oui {
    width: 19px;
    height: 28px;
    margin: 0 8px 0 6px;
    font-family: Prompt;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.55;
    letter-spacing: normal;
    color: #363660;
    display:flex;
    justify-content: center;
    align-items: center;
}
.oui label {
    margin: 0;
    margin-left: 7px;
}
.type_label {
    font-family: Prompt;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #807f99;
    width: 74px;
    height: 18px;
    margin: 0 0 0 8px;
}
.visual-input-two {
      width: 192px;
    height: 45px;
    margin: 10px 8px 3px 0;
    padding: 13px 16px 14px 16px;
    border-radius: 4px;
    border: solid 1px #e3e3e3;
    background-color: #ffffff;
}
.rect_right {
    width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 10px;
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
  position:absolute;
    right: 12px;
    top: 6px;
}
.rect_right svg{
    width: 16px;
    height: 16px;
    object-fit: contain;
    position: relative;
    top: -6px;
    left: -1px;
}
.relative {
  position: relative;
}
.flex-row {
  display: flex;
}
.flex-row .white-container{
  margin: 0;
      position: relative;
    z-index: 5;
    display: block!important;
}
.m4 {
  margin-left: 4px;
}
.criteria {
  height: 21px;
    margin: 0 12px 4px 0;
    font-family: Prompt;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #373563;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-family: Prompt;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #999999;
}
::-moz-placeholder { /* Firefox 19+ */
  font-family: Prompt;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #999999;
}
:-ms-input-placeholder { /* IE 10+ */
  font-family: Prompt;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #999999;
}
:-moz-placeholder { /* Firefox 18- */
  font-family: Prompt;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #999999;
}
.justifyLeft label {
  margin-left: 10px;
}
.rect_right_two {
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 10px;
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
  position: absolute;
  right: 12px;
  top: 6px;
}

.floating-label label {
    position: absolute;
    font-size: 14px;
    top: 8px;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: margin-top 300ms ease, -webkit-transform 300ms ease;
    transition: margin-top 300ms ease, -webkit-transform 300ms ease;
    -o-transition: transform 300ms ease, margin-top 300ms ease;
    transition: transform 300ms ease, margin-top 300ms ease;
    transition: transform 300ms ease, margin-top 300ms ease, -webkit-transform 300ms ease;
    background-color: #fff;
    z-index: 2;
    margin-bottom: 0;
}

.floating-label {
    position: relative;
    min-height: auto;
}
.floating-label--bordered {
    padding: 0;
    border-radius: 4px;
    min-height: auto;
    background-color: #fff;
}
.floating-label--bordered label {
     top: 8px;
    background-color: transparent;
    transform: none !important;
    left: 16px;
    color: #999999;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    top: 23px;
}
.visual-input-two:focus, .visual-input:focus {
  border: solid 1px #e3e3e3;
  outline: none;
}
.floating-label:focus label {
  top: 8px;
  color: #4b45b2;
  font-family: Prompt;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.checkbox-switch {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.field-group .checkbox-switch {
    height: 50px;
}

input[type="radio"], input[type="checkbox"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}
.checkbox-switch input[type="checkbox"] {
    position: absolute;
    z-index: 2;
    width: 35px;
    height: 20px;
    opacity: 0;
    cursor: pointer;
}
.checkbox-switch span {
    cursor: pointer;
    width: 35px;
    height: 20px;
    background: #bae7ee;
    display: block;
    border-radius: 100px;
    position: relative;
}

.checkbox-switch input:checked + span {
    background: #4c46b2;
}
.checkbox-switch span:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 2px;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 90px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.checkbox-switch input:checked + span:after {
    left: calc(100% - 2px);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}
.floating-label--bordered:focus-within {
  outline: none;
}
.floating-label--bordered:focus-within label {
  opacity: 1;
}
.map-container {
    position: absolute;
    width: 98%;
    height: 80vh;
    z-index: 1;
    padding-left: 8.5%;
    transition: all .5s;
    left:0;
}
.switch_swipper-off {
    border: 1px solid #00c1e2;
}
.circle-off {
    background: #00c1e2;
}
.leaflet-popup-tip-container {
  display: none;
}
.textBold {
  font-weight: 600;
}
.noborder-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.noborder-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.analyse {
  width: 670px;
  display:block!important;
  overflow-y: scroll;
}
.cursor {
  cursor: pointer;
}
.ref_value {
  text-align: center;
}
.visual-input-two, .visual-input {
      font-family: Prompt;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    color: #363660;
    margin: 2px 0 0;
}
.pretty-radio input, .pretty-checkbox input {
    display: none;
}
.pretty-radio label, .pretty-checkbox label {
     margin-bottom: 0;
    position: relative;
    padding-left: 24px;
    width: 19px;
    height: 28px;
    margin: inherit;
    font-family: Prompt;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.55;
    letter-spacing: normal;
    color: #363660;
}
.pretty-radio label:before, .pretty-radio label:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    left: 0;
}
.pretty-checkbox label:before, .pretty-checkbox label:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 0;
    left: 0;
}

.pretty-radio label:after, .pretty-checkbox label:after {
    top: 7px;
    width: 8px;
    height: 8px;
    left: 4px;
}

.pretty-radio label:after {
    top: 7px;
    width: 8px;
    height: 8px;
    left: 4px;
}

.pretty-radio input:checked ~ label:after, .pretty-checkbox input:checked ~ label:after {
    background-color: #00c4e6;
}
.pretty-radio input:checked ~ label:before, .pretty-checkbox input:checked ~ label:before {
    border-color: #00c4e6;
}

.pretty-radio label:before, .pretty-checkbox label:before {
    border: solid 1px #b2b2b2;
    -webkit-box-shadow: 0 2px 4px 0 rgba(96, 97, 112, 0.16);
    box-shadow: 0 2px 4px 0 rgba(96, 97, 112, 0.16);
    top: 3px;
    background-color: #fff;
}
.navbar-light .navbar-brand {
      position: relative;
    top: -6px;
}
.trans svg {
}
.insvg {
  cursor: pointer;
}
.tooltip-custom {
position: absolute;
    right: -212px;
max-width: 255px;
    min-width: 255px;
    height: auto;
    margin: 3px 0 3px 156px;
    padding: 10px 16px 10px 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16);
    background-color: #ffffff;
    z-index: 10;
    /* margin: 0 0 0 12px; */
    font-family: Prompt;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #3a3169;
    top: 0px;
    display: none;
}
.infos-svg {
  position: relative;
  z-index: 9;
  cursor: pointer;
}
#toolinfos-3 .infos-svg:hover #toolchild-3{
  display: block;
}
#toolchild-3 {
  right: -265px;
    top: -21px;
}
#toolchild-1 {
    top: -24px;
  right: -260px;
}

#toolinfos-1 .infos-svg:hover #toolchild-1{
  display: block;
}

#toolinfos-2 .infos-svg:hover #toolchild-2{
  display: block;
}

#toolchild-2 {
      right: -262px;
    top: -22px;
}

#toolinfos-4 .infos-svg:hover #toolchild-4{
  display: block;
}
#toolchild-4 {
    right: -260px;
    top: -20px;
}
.form-floating>.form-control, .form-floating>.form-select {
      height: 45px;
    padding: 1rem 0.75rem;
    margin: 0 0 10px;
    font-family: Prompt;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #363660;
}
.form-floating>label {
      padding: 0.8rem .75rem 0.7rem;
      font-family: Prompt;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #999999;
}
.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
      padding-top: 1.025rem;
    padding-bottom: .625rem;
}
.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
      font-family: Prompt;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b45b2;
    opacity: 1;
}
.form-control:focus {
  box-shadow: none;
}
.tags {
      margin: 1px 0 0;
    padding: 1px 6px;
    border-radius: 4px;
    font-family: Prompt;
    font-size: 8px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #363660;
}
.tag-red {
    box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16);
    background-color: #ff7f89;
}

.tag-blue {
    color:#fff;
    box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16);
    background-color: #3d42df;
}
.vue-map-container {
  width:98%!important;
  height: 80vh!important;
}
.card-content {
  padding: 5px;
}
.map .card-content strong {
  font-family: Prompt;
  font-size: 12px;
  color:#373563;
      font-weight: 600;
}
.map .card-content p {
   font-family: Prompt;
  font-size: 12px;
  font-weight: 200;
      margin-bottom: 2px;
}
.map .card-content img {
  width: 100%;
  margin-top:10px;
}
.absoluteBottom {
  position: absolute;
  bottom: 27px;
  width: 100%;
  right: 17px;
}
.save-content {
      width: 470px;
    min-height: 220px;
    height: auto;
    margin: 44px 0 61px 0;
    padding: 5px 24px 10px 4px;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgb(96 97 112 / 16%);
    background-color: #ffffff;
    padding: 10px 20px;
}
.save-content h2 {
      font-family: Prompt;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #363660;
    padding: 0;
    margin-bottom: 10px;
    position: static;
    margin-bottom: 0;
}
.save-content h4 {
      font-family: Prompt;
    font-size: 14px!important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.2px;
    color: #808097;
    margin-top: 6px;
    position: static;
    margin-bottom: 6px;
}
.save-content input {
      margin-top: 0;
      font-family: Prompt;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal; 
    line-height: normal;
    letter-spacing: -0.2px;
    color: #363660;
        height: 45px;
}
.save-content .modal-footer {
  padding-right: 0;
  padding-bottom: 0!important;
  padding-top: 0px;
  border-top: none;
}
.save-content .valid_btn {
      margin-right: 0;
    margin-left: 15px;
}
.navbar {
  margin-left: 25px;
}
.simulator {
  margin-top: 15px;
}
.oGraph {
  width: 100%;
}
.mr-2 {
  margin-right: 20px;
  width: 100%;
}
.triple {
      width: 33.3%;
}
.double{
      width: 50%;
}
.canvas,.chartjs-render-monitor {
  max-width: 100%!important;
}
.oSearch {
  display: block;
}
.oHide {
  display: none;
}
.splitted {
    width: 60%;
}
.full {
  width: 50%;
}
.white-container::-webkit-scrollbar {
  width: 2px;
  display: none;
}
.split {
    width: 67%;
    left: 32%;
}
#vs2__listbox li, #vs1__listbox li {
    font-family: Prompt;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.33;
  letter-spacing: normal;
  color: #363660;
}
.w100 {
  width: 100%;
}
.pa-left {
  position: absolute;
  right: 0px;
  top: 8px;
}
.modal-type-bien label {
      font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #807f99;
}
.square-checkbox {
      top: 1px;
}
.active svg {
  top: -4px!important;
  left: -2px!important;
}
.vs__selected {
      margin-left: 4px!important;
}
.clearPoly {
      position: absolute;
    z-index: 10;
    right: 95px;
    top: 10px;
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 3px;
}
.grey {
      display: flex;
    background: #f5f5f5;
    align-items: center;
}
.blue-bg {
  background:url('./assets/bg.png');
  min-height:100vh;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}
.blue-bg svg {
      position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
}
.form-login {
    width: 430px;
  height: 486px;
  margin: auto;
  padding: 31px 40px 40px;
  border-radius: 2px;
  box-shadow: 0 0.5px 2px 0 rgba(96, 97, 112, 0.16);
  background-color: #ffffff;
}
.form-title {
    width: auto;
  height: 36px;
  margin: 0 0 7px 0;
  font-family: Prompt;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363660;
}
.form-login h3 {
    width: 350px;
  height: 60px;
  margin: 7px 0 16px;
  font-family: Prompt;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.2px;
  color: #808097;
}
.form-login .type_label {
      width: 100%;
    font-family: Prompt;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #807f99;
}
.forget {
      margin-top: 33%;
}
.forget span {
      width: 125px;
    height: 18px;
    margin: 4px 0 2px 8px;
    font-family: Prompt;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    color: #363660;
}
.login-btn {
  width: 133px;
    height: 25px;
    /* margin: 143px 0 0 60px; */
    padding: 4px 24px;
    opacity: 1;
    border-radius: 3px;
    background-color: #363660;
    border: none;
    background: #363660;
    font-family: Prompt;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
.sub-form-title {
   font-family: Prompt;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #363660;
}
.suc-btn {
  font-family: Prompt;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #363660;
}
.spec-btn {
  padding: 4px 24px;
  border-radius: 3px;
  background-color: #363660;
    font-family: Prompt;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
   margin: 52px 0 0 0;
}
.lds-ripple {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #0000008c;
  z-index: 99;
  top: 0;
}
.vue-notification-group {
  top: 6%!important;
  right: 4%!important;
}

.clearPoly {
  right: 7px;
  top: 7px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polyline,.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-marker,.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-rectangle,.leaflet-control-toolbar>li:last-child>.leaflet-toolbar-icon {
  display: none;
}
.leaflet-toolbar-0 {
      border: none!important;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
    display: block;
    width: 40px!important;
    height: 42px!important;
    line-height: 30px;
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
    text-align: center;
    text-decoration: none;
    background-color: #fff;
    background-position: 10% 10%;
}
.leaflet-control-toolbar>li>.leaflet-toolbar-icon {
    border-bottom: 1px solid #ccc0!important;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
    background-position: -24px 4px!important;
}
.leaflet-control-toolbar .leaflet-toolbar-1 {
  display: none!important;
}
.card-number {
      height: 12px;
    font-family: Prompt;
    font-size: 8px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    display: block;
}
.card-number-2 {
  height: 23px;
    font-family: Prompt;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: left;
    color: #ffffff;
    display: block;
}
.card-name {
      height: 15px;
    font-family: Prompt;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}
#card .card {
  position: relative;
  z-index: 3;
}
#card .card:before {
  content: ' ';
  display: block;
  position:absolute;
  width: 100%;
  height: 100%;
  left:0;
  top:0;
  border-radius: 12px;
  background-color: #363660;
  background-image: url('./assets/Active-virtual-card.png');
  background-repeat: no-repeat;
  background-size: cover;
  z-index:2;
}
#card .card:after {
  content: ' ';
  display: block;
  position:absolute;
  width: 100%;
  height: 100%;
  left:15%;
  top:30%;
  background-size: contain;
  background-image: url('./assets/shadow.png');
  background-repeat: no-repeat;
  z-index:1;
}
.card-bottom {
      position: absolute;
    bottom: 40px;
        z-index: 9;
}
.card-right {
      position: absolute;
    right: 30px;
    bottom: 18px;
        z-index: 9;
}
.card-right .month {
  height: 9px;
    font-family: Prompt;
    font-size: 6px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    display: block;
}
.card-right .date {
  height: 22px;
  font-family: Prompt;
  font-size: 7px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
  width: 33px;
  text-align: left;
}
.card-right .date_v {
  height: 24px;
  font-family: Prompt;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-left: 8px;
}
.dropdown-menu.show {
  left: -145%!important;
}
.dropdown-toggle::after {
  display: none!important;
}
.bg svg {
  position: relative;
  left: -1px;
  top: -2px;
}
#corps {
  max-height: 100%;
  overflow: hidden;
}
.leaflet-draw-actions-top.leaflet-draw-actions-bottom a {
  height: 26px;
  line-height: 26px;
  top: 8px;
  position: relative;
  padding-bottom: 10px!important;
  right: 9px;
}
#optionsM .flex {
  padding: 0;
  align-items: center;
}
.clearPolygon.toolTip:after {
  left: inherit!important;
  right: 36px!important;
  top: 4px;
  width: 125px!important;
  height: auto;
}

.no-down .down-c {
    display:none;
}

#navbarNav li a.router-link-exact-active {
  color: #00C1E2!important;
}
</style>
