<template>
  <div class="c">
    <!-- Button trigger modal -->
    <button type="button" id="openmodal" class="btn btn-primary" data-bs-toggle="modal" data-bs-backdrop="static" data-bs-keyboard="false" data-bs-target="#exampleModalLong">
    Launch demo modal
    </button>

    <!-- Modal -->
    <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content save-content">
            <h2 class="white-title white-title-save">Titre de la recherche</h2>
            <h4 class="white-title-child white-title-child-save">
                Donner un titre à la recherche afin de ne pas vous y perdre !
            </h4>
            <form action="" @submit="validateModal">
                <div class="form-group">
                    <input type="text" class="form-control form-control-lg input" v-model="title" placeholder="Titre">
                </div>
            </form>
            <h4 class="white-title-child white-title-child-save">
                Souhaitez-vous enregistrer votre recherche ?
            </h4>
            <div class="modal-footer">
                <button v-if="changePage == true" type="button" class="btn btn-secondary save-cancel_btn" @click="closeModal" id="close_save" data-bs-dismiss="modal">Non</button>
                <button v-if="changePage == false" type="button" class="btn btn-secondary save-cancel_btn" @click="closeModal" id="close_save" >Non</button>
                <button v-if="show == true" type="button" class="btn btn-primary save-valid_btn" @click.prevent="createNewSearch" data-bs-dismiss="modal">Nouvelle recherche</button>
                <button type="button" class="btn btn-primary save-valid_btn" @click.prevent="validateModal" data-bs-dismiss="modal">Oui</button>
            </div>
        </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            title: "",
            show: false,
        }
    },
    props: ['changePage','router'],
    mounted() {
        const search = this.$route.query.search
        const edit = this.$route.query.edit
        if(search != undefined || search != null) {
            this.title = localStorage.getItem('search')
            this.show = true
        }

        if(edit != undefined || edit != null) {
            this.title = localStorage.getItem('edit')
            this.show = true
        }
        
        document.getElementById('openmodal').click()
    },
    methods: {
        closeModal() {
            if(this.changePage == true) {
                this.$emit('closeModal')
            } else {
               // window.location.href = "/recherches"  
                const to = localStorage.getItem('to')
                window.location.href = to
              // this.router.push(to)
            }
            
        },
        validateModal() {
            //document.getElementById('close_save').click()

            this.$emit('validateModal', this.title)
        },
        setTitle(title) {
            this.titlle = title
        },
        createNewSearch() {
            this.$emit('createNew', this.title)
        }
    }
}
</script>
<style>
#openmodal {
    display: none;
}
.white-title-child-save {
    font-size: 14px!important;
    padding: 8px 0 12px 0;
    position: absolute;
    top: 46px;
}
.save-content {
    padding: 17px;
    padding-left: 25px!important;
    padding-right: 25px!important;
}
.save-content .save-cancel_btn {
    margin-right: 15px;
    padding: 5px 24px;
    border-radius: 3px;
    border: solid 1px #00c1e2;
    background: #fff;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #373563;
    font-weight: 700;
}
.save-content .save-valid_btn {
    padding: 5px 24px;
    border-radius: 3px;
    background-color: #363660;
    font-family: Prompt;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    font-weight: 550;
}
.footer{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-right: 15px;
}
.modal-body-save{
    padding-bottom: 0; 
}
</style>
