import Vue from 'vue'
import App from './App.vue'
import router from './router'
//import * as VueGoogleMaps from 'vue2-google-maps'
import 'leaflet/dist/leaflet.css';
import 'vue-select/dist/vue-select.css';
import {Vue2Storage} from 'vue2-storage'

import ScrollLoader from 'vue-scroll-loader'
import Notifications from 'vue-notification'

import LDrawToolbar from 'vue2-leaflet-draw-toolbar';

import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant'
import VueHtml2pdf from 'vue-html2pdf'
Vue.component('v-tilelayer-googlemutant', Vue2LeafletGoogleMutant)
// ...
Vue.component('l-draw-toolbar', LDrawToolbar);

Vue.use(ScrollLoader)
Vue.use(Notifications)
Vue.use(VueHtml2pdf)
// You can pass options
Vue.use(Vue2Storage, {
  prefix: 'ltness_',
  driver: 'local',
  ttl: 60 * 60 * 24 * 1000 // 24 hours
})
/*
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCqlAJEiiRBDAC1za-62wIg9enRTlx3lqY',
    libraries: 'places,drawing,visualization,geometry', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})
*/
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
