<template>
  <div class="switch-component-swipper" @click="changeSwipperValue">
      <div class="switch-swipper" :class="swipperValue == true ? 'switch_swipper-on' : 'switch_swipper-off'">
          <div class="circle" :class="swipperValue == true ? 'circle-on' : 'circle-off'"></div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Swipper',
  props: ["swipperValue"],
  methods: {
      changeSwipperValue() {
          this.$emit("swipperValueChange")
      }
  }
}
</script>

<style>
.switch-component-swipper {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
}

.switch-swipper {
    width: 44px;
    height: 20px;
    display: flex;
    cursor: pointer;
    border-radius: 22px;
    align-items: center;
    padding: 2px;
    background: white;
    border: 1px solid #00c1e2;
    transition: all 0.6s;
}

.switch_swipper-on {
   border: 1px solid #00c1e2;
   padding-left: 25px;
}

.switch_swipper-off {
    border: 1px solid #00c1e2;
    padding-left: 2px;
}

.circle {
    margin: 3px;
    background: #00c1e2;
    width: 7px;
    height: 102%;
    border-radius: 28px;
}

.circle-on {
    background: #00c1e2;
}

.circle-off {
    background: #00c1e2;
}

</style>
