<template>
  <div class="white-container analyse" v-bind:class="[graph == true ? 'oGraph' : 'oGraph',splitted == true ? 'splitted': ''] ">

    <h2 class="white-title">Analyse des références</h2>
    <div class="simulator">
      <div class="selector">
        <span class="selector_visual" v-bind:class="stat == true ? 'actif' :'not'" @click="openStat"
          ><span class="content">Statistiques</span></span
        >
        <span class="selector_visual " v-bind:class="graph == true ? 'actif' : 'not'" @click="openGraph"
          ><span class="content">Graphiques</span></span
        >
      </div>
    </div>
    <div class="btnOptions">
      <div class="double cursor" @click="openDoubleGraph" v-bind:class="double == true ? 'active' : ''"  v-if="graph == true">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="26"
          viewBox="0 0 25 26"
            style="position: relative;top: -1px;left: -1px;"

        >
          <g fill="none" fill-rule="evenodd">
            <g>
              <g>
                <g>
                  <g
                    transform="translate(-1296 -115) translate(132 86) translate(1156 21) translate(8 8)"
                  >
                    <path d="M0 0H24V25H0z" />
                    <rect
                      width="7"
                      height="7.292"
                      x="4"
                      y="4.167"
                      fill="#363660"
                      rx="1.5"
                    />
                    <path
                      fill="#00C1E2"
                      d="M9.5 13.542c.828 0 1.5.671 1.5 1.5v4.291c0 .829-.672 1.5-1.5 1.5h-4c-.828 0-1.5-.671-1.5-1.5v-4.291c0-.829.672-1.5 1.5-1.5h4zm9 0c.828 0 1.5.671 1.5 1.5v4.291c0 .829-.672 1.5-1.5 1.5h-4c-.828 0-1.5-.671-1.5-1.5v-4.291c0-.829.672-1.5 1.5-1.5h4zm0-9.375c.828 0 1.5.671 1.5 1.5v4.291c0 .829-.672 1.5-1.5 1.5h-4c-.828 0-1.5-.671-1.5-1.5V5.667c0-.829.672-1.5 1.5-1.5h4z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="double cursor" v-bind:class="triple == true ? 'active' : ''" @click="openTripleGraph"  v-if="graph == true && splitted == false">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="26"
          viewBox="0 0 25 26"
          style="position: relative;top: -3px;left: -2px;"

        >
          <g fill="none" fill-rule="evenodd">
            <g>
              <g>
                <g>
                  <g
                    transform="translate(-1344 -115) translate(132 86) translate(1204 21) translate(8 8)"
                  >
                    <path d="M0 0H24V25H0z" />
                    <rect
                      width="4"
                      height="4.167"
                      x="4"
                      y="4.167"
                      fill="#363660"
                      rx="1"
                    />
                    <path
                      fill="#00C1E2"
                      d="M7 16.667c.552 0 1 .447 1 1v2.166c0 .553-.448 1-1 1H5c-.552 0-1-.447-1-1v-2.166c0-.553.448-1 1-1h2zm6 0c.552 0 1 .447 1 1v2.166c0 .553-.448 1-1 1h-2c-.552 0-1-.447-1-1v-2.166c0-.553.448-1 1-1h2zm6 0c.552 0 1 .447 1 1v2.166c0 .553-.448 1-1 1h-2c-.552 0-1-.447-1-1v-2.166c0-.553.448-1 1-1h2zm-12-6.25c.552 0 1 .447 1 1v2.166c0 .553-.448 1-1 1H5c-.552 0-1-.447-1-1v-2.166c0-.553.448-1 1-1h2zm6 0c.552 0 1 .447 1 1v2.166c0 .553-.448 1-1 1h-2c-.552 0-1-.447-1-1v-2.166c0-.553.448-1 1-1h2zm6 0c.552 0 1 .447 1 1v2.166c0 .553-.448 1-1 1h-2c-.552 0-1-.447-1-1v-2.166c0-.553.448-1 1-1h2zm-6-6.25c.552 0 1 .447 1 1v2.166c0 .553-.448 1-1 1h-2c-.552 0-1-.447-1-1V5.167c0-.553.448-1 1-1h2zm6 0c.552 0 1 .447 1 1v2.166c0 .553-.448 1-1 1h-2c-.552 0-1-.447-1-1V5.167c0-.553.448-1 1-1h2z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      
      <div class="double cursor" @click="splitScreen">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style="position: relative;top: -1px;left: -1px;"
          >
            <g fill="none" fill-rule="evenodd">
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        d="M0 0H24V24H0z"
                        transform="translate(-64 -150) translate(40 86) translate(20 60) translate(4 4)"
                      />
                      <path
                        fill="#363660"
                        d="M12 3c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm2.47 4.273c-.107-.006-.214.022-.305.082l-4.554 3.007c-.132.087-.215.23-.224.388l-.327 5.448c-.017.276.193.512.47.529.107.006.214-.022.305-.082l4.554-3.007c.132-.087.215-.23.224-.388l.327-5.448c.017-.276-.193-.512-.47-.529z"
                        transform="translate(-64 -150) translate(40 86) translate(20 60) translate(4 4)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
      </div>
    </div>
    <!-- flex -->
    <div class="flex mt-4" v-if="stat == true && splitted == false">
      <div class="rect">
        <div class="oval">
          <div class="oval_inner" style="left: 0.5px;">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              style="left: -1px;top: -2px;"
            >
              <g fill="none" fill-rule="evenodd">
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              d="M0 0H24V24H0z"
                              transform="translate(-294 -250) translate(132 84) translate(24 124) translate(86.904 24) translate(52 18)"
                            />
                            <path
                              fill="#363660"
                              d="M12.521 3.819l7.522 4.596c.594.363.957 1.01.957 1.707V19c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2v-8.878c0-.697.363-1.344.957-1.707L11.48 3.82c.32-.196.722-.196 1.042 0zM14 13h-4c-.552 0-1 .448-1 1v3c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-3c0-.552-.448-1-1-1z"
                              transform="translate(-294 -250) translate(132 84) translate(24 124) translate(86.904 24) translate(52 18)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <!-- Oval inner -->
        </div>

        <!-- Référence value -->
        <div class="ref_value">
          <span class="value">{{data_details.nb_ref}}</span>
          <span class="nb">Nombres de références</span>
        </div>
        <!-- end reference value-->
      </div>
      <!-- start rect value -->
      <div class="rect">
        <div class="top">Moyenne</div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[1].n_value.value}}
            </p>
            <p class="ecart">( Ecart-type : {{ data_details.length  === 0 ? '' : data_details.details[1].n_value.ecart_type}} )</p>
          </div>
        </div>
        <div class="sep"></div>
        <div class="mid row mt-0 mb-0">
          <div class="col col-lg-3">
            <p class="val_R">N+1:</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
               {{ data_details.length  === 0 ? '' : data_details.details[1].n_1_value.value}}
            </p>
            <p class="ecart">( Ecart-type : {{ data_details.length  === 0 ? '' : data_details.details[1].n_1_value.ecart_type}})</p>
          </div>
        </div>
      </div>
      <!-- end rect value -->
      <div class="rect">
        <div class="top">Moyenne réduite</div>
        <div class="sub-top">{{ data_details.length  === 0 ? '' : data_details.details[2].note}}</div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
               {{ data_details.length  === 0 ? '' : data_details.details[2].n_value.value}}
            </p>
          </div>
        </div>
        <div class="sep"></div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N+1 :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
               {{ data_details.length  === 0 ? '' : data_details.details[2].n_1_value.value}}
            </p>
          </div>
        </div>
      </div>
      <!-- start rect value -->
      <div class="rect  me-0">
        <div class="top">Médiane</div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
               {{ data_details.length  === 0 ? '' : data_details.details[0].n_value.value}}
            </p>
          </div>
        </div>
        <div class="sep"></div>
        <div class="mid row mt-2 mb-2">
          <div class="col col-lg-3">
            <p class="val_R">N+1 :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[0].n_1_value.value}}
            </p>
          </div>
        </div>
      </div>
      <!-- end rect value -->
    </div>
    <!-- end flex -->
    <div class="flex mt-4" v-if="stat == true && splitted == true">
      <div class="rect full">
        <div class="oval">
          <div class="oval_inner">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
            >
              <g fill="none" fill-rule="evenodd">
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              d="M0 0H24V24H0z"
                              transform="translate(-294 -250) translate(132 84) translate(24 124) translate(86.904 24) translate(52 18)"
                            />
                            <path
                              fill="#363660"
                              d="M12.521 3.819l7.522 4.596c.594.363.957 1.01.957 1.707V19c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2v-8.878c0-.697.363-1.344.957-1.707L11.48 3.82c.32-.196.722-.196 1.042 0zM14 13h-4c-.552 0-1 .448-1 1v3c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-3c0-.552-.448-1-1-1z"
                              transform="translate(-294 -250) translate(132 84) translate(24 124) translate(86.904 24) translate(52 18)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <!-- Oval inner -->
        </div>

        <!-- Référence value -->
        <div class="ref_value">
          <span class="value">{{data_details.nb_ref}}</span>
          <span class="nb">Nombres de références</span>
        </div>
        <!-- end reference value-->
      </div>
      <!-- start rect value -->
      <div class="rect full">
        <div class="top">Moyenne</div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[1].n_value.value}}
            </p>
            <p class="ecart">( Ecart-type : {{ data_details.length  === 0 ? '' : data_details.details[1].n_value.ecart_type}} )</p>
          </div>
        </div>
        <div class="sep"></div>
        <div class="mid row mt-0 mb-0">
          <div class="col col-lg-3">
            <p class="val_R">N+1:</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
               {{ data_details.length  === 0 ? '' : data_details.details[1].n_1_value.value}}
            </p>
            <p class="ecart">( Ecart-type : {{ data_details.length  === 0 ? '' : data_details.details[1].n_1_value.ecart_type}})</p>
          </div>
        </div>
      </div>
    
    </div>

    <div class="flex mt-1" v-if="stat == true && splitted == true">
      <!-- end rect value -->
      
      <div class="rect full">
        <div class="top">Moyenne réduite</div>
        <div class="sub-top">{{ data_details.length  === 0 ? '' : data_details.details[2].note}}</div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
               {{ data_details.length  === 0 ? '' : data_details.details[2].n_value.value}}
            </p>
          </div>
        </div>
        <div class="sep"></div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N+1 :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
               {{ data_details.length  === 0 ? '' : data_details.details[2].n_1_value.value}}
            </p>
          </div>
        </div>
      </div>
      <!-- start rect value -->
      
      <div class="rect full">
        <div class="top">Médiane</div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
               {{ data_details.length  === 0 ? '' : data_details.details[0].n_value.value}}
            </p>
          </div>
        </div>
        <div class="sep"></div>
        <div class="mid row mt-2 mb-2">
          <div class="col col-lg-3">
            <p class="val_R">N+1 :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[0].n_1_value.value}}
            </p>
          </div>
        </div>
      </div>
      <!-- end rect value -->
    
    </div>

    <!-- flex -->
    <div class="flex mt-1" v-if="stat == true && splitted == false">
      
       <div class="rect">
        <div class="top">Taux de rendement</div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[4].n_value.value}}
            </p>
          </div>
        </div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N+1 :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[4].n_1_value.value}}
            </p>
          </div>
        </div>
      </div>
      <!-- start rect value -->
      <div class="rect">
        <div class="top">{{ data_details.length  === 0 ? 'Evolution du prix/m² médian' : data_details.details[5].type}}</div>
        <div class="sub-top">{{ data_details.length  === 0 ? '' : data_details.details[5].note}}</div>

        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[5].n_value.value}}
            </p>
          </div>
        </div>
        <div class="sep"></div>
        <div class="mid row mt-2 mb-2">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[5].n_1_value.value}}
            </p>
          </div>
        </div>
      </div>
      <!-- end rect value -->
      <div class="rect">
        <div class="top">{{ data_details.length  === 0 ? 'Evolution du prix/m² médian' : data_details.details[6].type}}</div>
        <div class="sub-top">{{ data_details.length  === 0 ? '' : data_details.details[6].note}}</div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[6].n_value.value}}{{ data_details.length  === 0 ? '' : data_details.details[6].n_value.unit}}
            </p>
          </div>
        </div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N+1 :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[6].n_1_value.value}}{{ data_details.length  === 0 ? '' : data_details.details[6].n_1_value.unit}}
            </p>
          </div>
        </div>
      </div>
      <!-- start rect value -->
      <div class="rect me-0">
        <div class="top">Loyer annuel</div>

        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[3].n_value.value}}
            </p>
          </div>
        </div>
        <div class="sep"></div>
        <div class="mid row mt-2 mb-2">
          <div class="col col-lg-3">
            <p class="val_R">N+1 :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[3].n_1_value.value}}
            </p>
          </div>
        </div>
      </div>
      <!-- end rect value -->
    </div>
    <!-- end flex -->


    <div class="flex mt-1" v-if="stat == true && splitted == true">
      
       <div class="rect full">
        <div class="top">Taux de rendement</div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[4].n_value.value}}
            </p>
          </div>
        </div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N+1 :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[4].n_1_value.value}}
            </p>
          </div>
        </div>
      </div>
      <!-- start rect value -->
      <div class="rect full">
        <div class="top">{{ data_details.length  === 0 ? 'Evolution du prix/m² médian' : data_details.details[5].type}}</div>
        <div class="sub-top">{{ data_details.length  === 0 ? '' : data_details.details[5].note}}</div>

        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[5].n_value.value}}
            </p>
          </div>
        </div>
        <div class="sep"></div>
        <div class="mid row mt-2 mb-2">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[5].n_1_value.value}}
            </p>
          </div>
        </div>
      </div>
      <!-- end rect value -->

    </div>
    <!-- end flex -->

    <div class="flex mt-1" v-if="stat == true && splitted == true">
      
      <div class="rect full">
        <div class="top">{{ data_details.length  === 0 ? 'Evolution du prix/m² médian' : data_details.details[6].type}}</div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[6].n_value.value}}
            </p>
          </div>
        </div>
        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N+1 :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[6].n_1_value.value}}
            </p>
          </div>
        </div>
      </div>
      <!-- start rect value -->
      
      <div class="rect full">
        <div class="top">Loyer annuel</div>

        <div class="mid row">
          <div class="col col-lg-3">
            <p class="val_R">N :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[3].n_value.value}}
            </p>
          </div>
        </div>
        <div class="sep"></div>
        <div class="mid row mt-2 mb-2">
          <div class="col col-lg-3">
            <p class="val_R">N+1 :</p>
          </div>
          <div class="col col-lg-8 ps-0 pe-0">
            <p class="price">
              {{ data_details.length  === 0 ? '' : data_details.details[3].n_1_value.value}}
            </p>
          </div>
        </div>
      </div>
      <!-- end rect value -->
    </div>
    <!-- end flex -->
    <div v-if="graph == true">
      
      <div class="mt-1" v-bind:class="double == true ? 'display' : 'hide' ">
    
        <div class="flex mt-1" >
            <div class="mr-2 double" >
              <chart ref="segmentation" v-if="data_chart.length !== 0" :dataChart="data_chart[0]" :id="'chart11000'"  :width="300" :height="222"  @hoverTiks="openMarker" @showLoader="showLoader" @hideLoader="hideLoader"></chart>

            </div>
            <div class="mr-2 double">
              <chart ref="correlation" v-if="data_chart.length !== 0" :dataChart="data_chart[1]" :id="'chart1'" :width="750" :height="300" @openPopup="openPopup"  @hoverTiks="openMarker" @showLoader="showLoader" @hideLoader="hideLoader"></chart>
            </div>
        </div>

        <div class="flex mt-1" >
            <div class="mr-2 double" >
              <chart ref="surface" v-if="data_chart.length !== 0" :dataChart="data_chart[2]" :id="'chart2'" :width="750" :height="300" @hoverTiks="openMarker" @openPopup="openPopup"  @showLoader="showLoader" @hideLoader="hideLoader"></chart>
            
            </div>
            <div class="mr-2 double">
              <chart ref="frequence" v-if="data_chart.length !== 0" :dataChart="data_chart[3]" :id="'chart3'" :width="750" :height="300" @hoverTiks="openMarker" @showLoader="showLoader" @hideLoader="hideLoader"></chart>
            </div>
        </div>

        <div class="flex mt-1" >
          <div class="mr-2 double">
                <chart ref="transaction" v-if="data_chart.length !== 0" :dataChart="data_chart[5]" :id="'chart4'" :width="750" :height="300" @hoverTiks="openMarker" @showLoader="showLoader" @hideLoader="hideLoader"></chart>
          </div>
          <div class="mr-2 double">
                <chart ref="surfacet" v-if="data_chart.length !== 0" :dataChart="data_chart[4]" :id="'chart10'" :width="750" :height="300" @hoverTiks="openMarker"  @showLoader="showLoader" @hideLoader="hideLoader"></chart>

          </div>
        </div>
      </div>

      <div class="mt-1" v-bind:class="triple == true ? 'display' : 'hide' ">
        
        <div class="flex mt-1"  >
            <div class="mr-2 triple">
              <chart ref="tpsegmentation" v-if="data_chart.length !== 0" :dataChart="data_chart[0]" :id="'chart1000'"  :width="300" :height="222"  @hoverTiks="openMarker" @showLoader="showLoader" @hideLoader="hideLoader"></chart>

            </div>
            <div class="mr-2 triple">
              <chart ref="tpcorrelation" v-if="data_chart.length !== 0" :dataChart="data_chart[1]" :id="'chart5'"  :width="300" :height="222"  @hoverTiks="openMarker" @openPopup="openPopup" @showLoader="showLoader" @hideLoader="hideLoader"></chart>
            </div>
            <div class="mr-2 triple">
              <chart ref="tpsurface" v-if="data_chart.length !== 0" :dataChart="data_chart[2]" :id="'chart6'"  :width="300" :height="222"  @hoverTiks="openMarker" @openPopup="openPopup" @showLoader="showLoader" @hideLoader="hideLoader"></chart>
            </div>
        </div>  

        <div class="flex mt-1" >
          <div class="mr-2 triple">
              <chart ref="tpfrequence" v-if="data_chart.length !== 0" :dataChart="data_chart[3]" :id="'chart7'"  :width="300" :height="222"  @hoverTiks="openMarker" @showLoader="showLoader" @hideLoader="hideLoader"></chart>
            
          </div>
          <div class="mr-2 triple">
              <chart ref="tptransaction" v-if="data_chart.length !== 0" :dataChart="data_chart[5]" :id="'chart8'"  :width="300" :height="222" @hoverTiks="openMarker" @openPopup="openPopup" @showLoader="showLoader" @hideLoader="hideLoader"></chart>
          </div>
          <div class="mr-2 triple">
              <chart ref="tpsurfacet" v-if="data_chart.length !== 0" :dataChart="data_chart[4]" :id="'chart9'"   :width="300" :height="222"  @hoverTiks="openMarker" @showLoader="showLoader" @hideLoader="hideLoader"></chart>
          </div>
        </div>
      </div>
    </div>
   
  </div>
</template>

<script>
export default {   
    components: {
      chart : require('../template/chart/chart').default
    },
    props: {
      data_chart: Array,
      data_details: Object
    },
    data() {
       return {
        graph: false,
        stat: true,
        double: false,
        triple: true,
        splitted: false,
      }
    },
    methods: {
      openGraph() {
        this.graph = true;
        this.stat = false;
      },
      openStat() {
        this.graph = false;
        this.stat = true;
      },
      openDoubleGraph() {
        this.double = true 
        this.triple = false

      },
      openTripleGraph() {
        this.double = false
        this.triple = true
 
      },
      openPopup(index) {
        this.$emit('openPopup', index)
      },
      splitScreen() {
        this.splitted = !this.splitted
        this.double = true 
        this.triple = false
       
        this.$emit('splitScreen', this.splitted)
      },
      closeSplit() {
        this.splitted = false
        this.$emit('closeSplit')
      },
      openMarker(index) {
        this.$emit('openMarker', index)
      },
      getRefs() {
        return this.$refs
      },
      showLoader() {
        this.$emit('showLoader')
      },
      hideLoader() {
        this.$emit('hideLoader')
      }
    }
};
</script>
<style>
.bar {
  padding: 20px;
  border-radius: 8px;
  border: solid 1px #e6e6f4;
  background-color: #ffffff;
  margin-bottom: 20px;
  height: 350px;
}
.bar_title {
  width: 100%;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  margin-right: 25px;
  margin-left: 5px;
  margin-bottom: 15px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4c46b2;
}
.Stockholm-icons-Files-Downloaded-file {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.scatterLegend {
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  list-style: none;
}
.scatterLegend li {
  display: inline-flex;
  padding-left: 20px;
  align-items: center;
}
.scatterLegend > li > div {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.scatterLegend > li > span {
  margin-left: 10px;
  font-family: Prompt;
  font-size: 10px;
  color: #363660;
}
.max-map .map {
  width: 578px;
  margin-left: 20px;
}
.spec-bar .bar{
    max-height: 369px;
}
.double .bar {
  height: 400px;
}
.display {
  display: block;
}
.hide {
  display: none;  
}
.top {
  margin-top: 30px;
}
#chartCont {
    /*
    max-height: 1px;
    position: relative;
    overflow: hidden;
    */
    max-height: 0;
    max-width: 0;
    padding: 0!important;
}
</style>
