<template>
    <div>
        
        <div class="loader" v-if="loader == true">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
        
    <div class="flex">

        <div class="bg-search">
            <ul class="search-menu">
                <li class="active">Mes recherches</li>
                <li class="cursor" @click="sortNothing" v-bind:class="sortBy == '' ? 'enabled': ''">Recherches sauvegardées </li>
                <li @click="sortDate" v-bind:class="sortBy == 'date' ? 'enabled' : ''" class="cursor">Dernières recherches</li>
            </ul>
        </div>
        <div class="bg-copy-2" id="wow">
            <router-link class="search-add-btn cursor" style="text-decoration:none;" to="/" v-if="search.length > 0">Nouvelle recherche</router-link>
            <h1 class="search-title" v-if="search.length > 0">Recherches sauvegardées</h1>
            <h2 class="search-sub-title" v-if="search.length > 0">Accédez aux résultats de vos recherches sauvegardées.</h2>

            <div class="list" v-if="search.length > 0 && loader == false">
                <h2 class="list-title">Vous avez {{nbRef}} recherches sauvegardées</h2>

                <div class="list-container">
                    <div class="list-item" v-for="s in search" :key="s._id.$id">
                        <div class="list-header">
                            <div class="me-auto">   
                                <span class="list-number">Recherche n° {{s._id.$id}}</span>
                                <span class="list-date">le {{s.created_at}}</span>
                            </div>
                            <div class="ms-auto">
                                <router-link class="edit" :to="'/?edit='+s._id.$id+'&lat='+s.latitude+'&lng='+s.longitude">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16">
                                        <g fill="none" fill-rule="evenodd">
                                            <g fill="#222B45">
                                                <g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path d="M10.68 7.12L8.881 5.321l1.3-1.299 1.795 1.796-1.298 1.3zm-4.627 4.63l-1.985.18.176-1.97L7.99 6.215l1.797 1.797-3.733 3.738zm6.883-6.858L11.11 3.065c-.494-.493-1.343-.516-1.81-.046L3.301 9.017c-.218.217-.352.505-.38.81l-.253 2.78c-.017.196.053.39.193.53.126.127.296.196.471.196.02 0 .04 0 .06-.003l2.78-.252c.306-.028.593-.162.81-.379l5.998-5.998c.485-.487.465-1.299-.045-1.81z" transform="translate(-1310 -252) translate(270 86) translate(24 24) translate(0 125) translate(1016 17)"/>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </router-link>
                                <span class="delete cursor" @click="deleteSearch(s)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16">
                                        <g fill="none" fill-rule="evenodd">
                                            <g fill="#FC5A5A">
                                                <g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path d="M12 12.667c0 .367-.299.666-.667.666H4.667c-.368 0-.667-.299-.667-.666V5.333h8v7.334zM6.667 2.885c0-.103.142-.218.333-.218h2c.19 0 .333.115.333.218V4H6.667V2.885zM14 4h-3.333V2.885c0-.856-.748-1.552-1.667-1.552H7c-.92 0-1.667.696-1.667 1.552V4H2c-.367 0-.667.3-.667.667 0 .366.3.666.667.666h.667v7.334c0 1.102.897 2 2 2h6.666c1.103 0 2-.898 2-2V5.333H14c.367 0 .667-.3.667-.666 0-.367-.3-.667-.667-.667z" transform="translate(-1336 -252) translate(270 86) translate(24 24) translate(0 125) translate(1016 17) translate(26)"/>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>

                                </span>
                            </div>                    
                        </div>
                        <div class="list-content">
                            <h3 class="list-title-sub">{{s.title}}</h3>
                        </div>
                        <div class="list-lists">
                            <div class="me-auto">
                                <span class="tag-item cursor" v-for="l in s.type" :key="l">{{l}}</span>
                                <span class="tag-item cursor">{{s.mode == 'vv' ? 'Valeur Vénale' :'Valeur Locative'}}</span>
                                <span class="tag-item cursor">{{s.source == 'dvf' ? 'Transactions' :'Offres'}}</span>
                                <span class="tag-item cursor">{{s.postal_code}}</span>
                                <span class="tag-item cursor">{{s.ville}}</span>

                            </div>
                            <div class="ms-auto">
                                <router-link class="see-item cursor" style="text-decoration:none;" :to="'/?search='+ s._id.$id+'&lat='+s.latitude+'&lng='+s.longitude">Voir les annonces</router-link>
                            </div>
                        </div>
                    </div>
                    <nav class="paginate" v-if="search.length != 0 && page.length > 1">
                        <ul class="pagination">
                        <li class="page-item" v-if="currentPage != 1" @click="prev()">
                            <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">Précédent</span>
                            <span class="sr-only">Précédent</span>
                            </a>
                        </li>
                        <li class="page-item" v-for="i in page" :key="i" @click="paginate(i)" v-bind:class="i == currentPage ? 'active' : ''"><a class="page-link" href="#">{{i}}</a></li>
                        <li class="page-item" @click="next()" v-if="currentPage < pages">
                            <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true">Suivant</span>
                            <span class="sr-only">Suivant</span>
                            </a>
                        </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="list" v-if="search.length == 0 && loader == false">
                <h1 class="search-title blck mt-5" v-if="search.length == 0">Recherches sauvegardées</h1>
                <h2 class="search-sub-title blck" v-if="search.length == 0">Vous n’avez aucune recherche sauvegardée.</h2>

                <img src="../assets/illustration-login.png" class="ma-img mt-3"/>       
                <router-link class="search-add-btn cursor middle" style="text-decoration:none;" to="/" v-if="search.length == 0">Nouvelle recherche</router-link>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            search: [],
            user: null,
            loadMore: false,
            loader: false,
            sortBy: "",
            pages: 0,
            page: [],
            perPage: 5,
            nbRef: 0,
            currentPage: 1,
            nextPage: 0
        }
    }, 
    mounted() {
        let user = null
        if(localStorage.getItem('uitoken') != null) {
            user = JSON.parse(localStorage.getItem('uitoken'))
            this.user = user
        }
        if(user != null) {
            this.fetchCount()
            this.fetchData()
        }
    },
    methods: {
        deleteSearch(s) {
            this.loader = true
            const URL = 'https://api.exval.wertkt.com/api/v1/bien/data-map/search/'+s._id.$id
            const myInit = {
                method: 'DELETE',
                //mode: 'no-cors',
                headers: {
                    'Authorization': this.user.access_token
                },
            };
            const self = this
            const myRequest = new Request(URL, myInit);
            fetch(myRequest).then(function(response) {
                return response;
            }).then(function(response) {
                if(response.ok) {
                    self.loader = false
                    self.fetchData()
                    self.loadMore = true
                }
            }).catch(function(e){
                console.log(e);
                self.loader = false
            });
        },
        fetchData(page = 1, limit = 5) {
            const self = this
            this.loader = true

            axios({
                method: 'GET',
                url: 'https://api.exval.wertkt.com/api/v1/bien/data-map/list?uid='+self.user.value,
                headers: {
                    'Authorization': this.user.access_token
                },
                params: {
                    page,
                    limit
                }
            }).then(function(response) {
                let data = response.data.length > 0 ? response.data.map(function(item) {
                    const i = item
                    if(i.type) {
                        if(typeof i.type === "string") {
                            i.type = [i.type]
                        }
                    }
                    return i
                }): []
                self.loader = false
                self.search = data
            }).catch(function(err) {
                console.log(err)
                self.loader = false
            })
            
        },
        fetchCount() {
            const self = this
            axios({
                method: 'GET',
                url: 'https://api.exval.wertkt.com/api/v1/bien/data-map/count',
                params: {
                    token: this.user.value,
                },
                headers: {
                    'Authorization': this.user.access_token
                }
            }).then(function(response) {
                self.nbRef = response.data.count    
                self.pages = Math.round(self.nbRef / self.perPage)       
                for(let i = 1; i <= self.pages; i++) {
                    self.page.push(i)
                }   
            }).catch(function(err) {
                console.log(err)
            })
        },
        sortDate() {
            if(this.sortBy == '') {
                this.sortBy = 'date'
                const list = this.search.sort(function(a, b) {
                    return new Date(a.created_at) - new Date(b.created_at)
                }).reverse()
                this.search = [...list]
            }
        },
        prev() {
            if(this.currentPage > 1) {
                this.currentPage = this.currentPage - 1
                this.fetchData(this.currentPage)
            }
        },
        next() {
            if(this.currentPage <= this.nbRef) {
                this.currentPage = this.currentPage + 1
                this.fetchData(this.currentPage)
            }
        },
        paginate(i) {
            if(this.currentPage != i) {
                this.currentPage = i
                this.fetchData(i)
            }
        },
        sortNothing() {
            if(this.sortBy == 'date') {
                this.sortBy =  ''
                const list = this.search.sort(function(a, b) {
                    return new Date(a.created_at) - new Date(b.created_at)
                })
                this.search = [...list]  
            }
        }
    }
}
</script>
<style>
.flex {
    padding: 0 20px;
}
.ma-img {
        margin: 0 auto;
    display: block;
}
.middle {
    text-decoration: none;
    position: static!important;
    margin: 18px auto;
    display: block;
    max-width: 160px;
}
.blck {
    display:block;
    width: 100%;
    text-align: center;
}
.bg-search {
     width: 210px;
  height: 136px;
  margin: 20px 20px 0 0;
  padding: 24px 17px 24px 24px;
  border-radius: 10px;
  box-shadow: 0 0.5px 2px 0 rgba(96, 97, 112, 0.16);
  background-color: #ffffff;
}
.bg-copy-2 {
    margin: 24px 20px 30px auto;
    width: 95%!important;
    padding: 24px 24px 0;
    border-radius: 10px;
    box-shadow: 0 0.5px 2px 0 rgba(96, 97, 112, 0.16);
    background-color: #ffffff;
    position: relative;

}
#wow {
    height: 80vh;
    overflow-y: scroll;
}
#wow::-webkit-scrollbar {
    width: 2px;
    display: none;
}
.search-add-btn {
        padding: 4px 24px;
    border-radius: 3px;
    background-color: #363660;
    font-family: Prompt;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff!important;
    position: absolute;
    right: 27px;
}
.search-menu {
    padding: 0;
    list-style: none;
}
.list-content {
    margin-top:8px;
}
.list-title-sub {
      font-family: Prompt;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #383366;
}
.list-lists {
    display: flex;
}
.tag-item {
        margin: 0 8px 0 0;
    padding: 4px 8px;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgb(96 97 112 / 16%);
    border: solid 1px #3d42df;
    background-color: #ffffff;
    font-family: Prompt;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3d42df;
}
.see-item {
        padding: 4px 24px;
    border-radius: 3px;
    background-color: #00c1e2;
    font-family: Prompt;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #373563;
}
.search-menu li {
    font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363660;
  margin: 16px 0 0 0;
}
.search-menu li.active {
    font-family: Prompt;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363660;
  margin: 0 0 12px 0;
}
.search-menu li.enabled {
    font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4cbfde;
  margin: 12px 0 16px;

}
.search-title {
        font-family: Prompt;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #363660;
}
.search-sub-title {
        font-family: Prompt;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.2px;
    color: #808097;
}
.list {
    margin-top: 24px;
}
.list-title {
    font-family: Prompt;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #363660;
}
.list-item {
        padding: 16px;
    border-radius: 8px;
    border: solid 1px #e6e6f4;
    margin-bottom: 10px;
}
.list-header {
        display: flex;
}
.list-number {
    margin: 0 16px 0 0;
    font-family: Prompt;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #50c0de;
}
.list-date {
        margin: 2px 0px 1px 16px;
    font-family: Prompt;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999999;
}
.edit {
        margin-right: 10px;
}
.bg-copy-2 {

}
.search-menu li a {
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #363660;
    margin: 16px 0 0 0;
    text-decoration: none;
}
.page-item:first-child .page-link, .page-link {
    
    margin: 0 8px 0 0;
    padding: 4px 8px;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgb(96 97 112 / 16%);
    border: solid 1px #3d42df;
    background-color: #ffffff;
    font-family: Prompt;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3d42df;

}
.active .page-link {
    color:#fff!important;
    background-color: #00c1e2!important;
    border: solid 1px #00c1e2!important;
}
</style>