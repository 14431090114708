import Vue from "vue";
import VueRouter from "vue-router";
import Exval from '../components/Exval.vue';
import Recherche from '../components/Recherche.vue';
import Login from '../components/Login.vue';
import Compte from '../components/Compte.vue';
import Main from '../components/MainProfil.vue';
import Marche from '../components/Marche.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Exval,
  },
  {
    path: "/recherches",
    name: "Recherches",
    component: Recherche,
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/mon-compte",
    name: 'Compte',
    component: Compte
  },
  {
    path: "/dashboard",
    name: 'Main',
    component: Main
  },
  {
    path: "/analyse-marche",
    name: 'Analyse',
    component: Marche
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(function(to,from, next) {
  localStorage.setItem('fromRoute', from.path)
  const token = localStorage.getItem('uitoken');
  if(token === null && to.name != "Login") {    
    window.location.href= "/login"
  } else if(to.name != "Login") {
    const parseToken = JSON.parse(token);
    const now = new Date()
    if(now.getTime() > parseToken.expiry) {
      localStorage.removeItem('uitoken');
      next({ name: 'Login' })
    }
    next();
  } else if (to.name == "Login") {
    next()
  }
})

export default router;
