<template>
    <div style="max-width: 500px" >
        <div class="select">
            <v-select 
                :options="options" 
                label="title" 
                v-model="selectedValue"
                :multiple="multiple" 
                @option:selected="selected"
                @option:deselected="deselected"
                :closeOnSelect="!multiple"
            >
                <template slot="option" slot-scope="option">
                    <CheckBox :label="option.title" :val="option.value" :checked="option.checked"/>
                </template>
                <template #open-indicator="{ attributes }">
                    <img 
                        src="download_modale/arrow-ios-down.png" 
                        srcset="download_modale/arrow-ios-down@2x.png 2x, download_modale/arrow-ios-down@3x.png 3x"
                        class="arrow-ios-down" 
                        v-bind="attributes"/>
                </template>
            </v-select>
            <div :class="{placeholder: true, placeholder_2: model}">
                <div class="placeholder_content">
                    <span :class="{ph1: true, ph1_2: model}">{{placeholderTitle}}</span><br/>
                    <span v-if="!model" class="ph2">{{placeholderContent}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
    nom: "VueSelect",
    components: {
        vSelect,
        CheckBox: require('./checkbox').default
    },
    props: {
        options: {
            type: Array,
            default:  () => 
            [
                {
                title: 'Label exemple 1',
                value: 'value exemple 1',
                checked: false
                },
                {
                title: 'Label exemple 2',
                value: 'value exemple 2',
                checked: false
                }
            ]
        },
        multiple: {
            type: Boolean,
            default: false
        },
        customIndicator: {
            type: Boolean,
            default: false
        },
        placeholderTitle: {
            type: String,
            require: true,
            default: 'Titre du placeholder:'
        },
        placeholderContent: {
            type: String,
            require: true,
            default: 'contenue du placeholder'
        },
    },
    data(){
        return{
            selectedValue: null,
            model: null,
            opt:this.options,
           attributes: {
                'ref': 'openIndicator',
                'role': 'presentation',
                'class': 'vs__open-indicator',
            }
        }
    },
    watch: {
        selectedValue(val){
            if(!val){
                this.model = null;
                this.$emit('input', this.model)

                 this.opt = this.opt.map(item => {
                    item.checked = false
                    return item
                })
                this.$emit('input', this.model)
            }
        }
    },
    methods: {
      selected(val) {
        if(this.multiple){
            this.model = val;
            this.opt = this.opt.map(item => {
                const elem = this.model.find(i => i.title == item.title)
                if(elem) item.checked = true
                return item
            })
          }  else {
              this.opt = this.opt.map(item => {
                 item.checked = false
                return item
                })
                this.opt = this.opt.map(item => {
                if(item.title == val.title) item.checked = true
                return item
            })
              this.model = val
          }
          this.$emit('input', this.model)
      },
      deselected(val){
        if(this.multiple){
            this.opt = this.opt.map(item => {
                if(item.title == val.title) item.checked = false
                return item
            })
            this.model = this.model.filter(item => item.title != val.title)
            if(this.model.length == 0) this.model = null
            this.$emit('input', this.model)
        }
      }
  },
}
</script>

<style>
.vs__selected {
    align-items: center;
    background-color: #f0f0f0;
    color: #3e3e3e!important;
    margin: 0px 0px 0!important;
    height: 30px;
    top: 15px;
    position: relative;
    font-weight: 500;
}
.vs__dropdown-option {
    color: #9292a9!important;
    font-weight: 500!important;
}
.vs__dropdown-option--highlight{
    color: #484848!important;
    background-color: #d2f4fa!important;
}

.vs__dropdown-option--highlight > label .square-checkbox{
    border: 1px solid #00c1e2!important;
    background-color: white!important;
}
.vs__dropdown-option--highlight > label .square-checkbox::before {
    border: 1px solid #00c1e2!important;
    background-color: #00c1e2!important;
}

.vs__search {
    height: 40px;
}
.select {
    padding: 0;
    position: relative
}

.placeholder {
    position: absolute;
    padding-left: 6px;
    padding-top: 3px;
    transform:  translateY(0);
    transition: all 0.2s;
}

.placeholder_2 {
    transform:  translateY(-2px);
    padding-top: 0px!important;
}

.select .placeholder {
    height: 100%;
    margin: 0;
    top: 0px;
    left: 0px;
    text-align: center;
    font-size: 13px;
}

.placeholder_content {
    text-align: left;
    font-size: 1em;
}


.ph1 {
    font-size: 0.85em;;
    color: #605bb5;
    font-weight: 800;
    transition: all 0.2s;
}

.ph1_2 {
    font-size: 0.75em;
}

.ph2 {
    color: #9292a9;
    font-weight: 500;
    padding-left: 4px;
}

.arrow-ios-down {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background: #efefef;
    border-radius: 5px;
    margin-right: 10px;
}
</style>