<template>
    <div>
        <div class="container-fluid">
            <div class="row flex flex-center">
                <div class="main">
                    <!-- rect -->
                    <div class="rect rect_infos">
                        <div class="oval">
                            <div class="oval_inner" style="left: -0.5px;">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                style="left: -1px;top: -2px;"
                                >
                                <g fill="none" fill-rule="evenodd">
                                    <g>
                                    <g>
                                        <g>
                                        <g>
                                            <g>
                                            <g>
                                                <path
                                                d="M0 0H24V24H0z"
                                                transform="translate(-294 -250) translate(132 84) translate(24 124) translate(86.904 24) translate(52 18)"
                                                />
                                                <path
                                                fill="#363660"
                                                d="M12.521 3.819l7.522 4.596c.594.363.957 1.01.957 1.707V19c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2v-8.878c0-.697.363-1.344.957-1.707L11.48 3.82c.32-.196.722-.196 1.042 0zM14 13h-4c-.552 0-1 .448-1 1v3c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-3c0-.552-.448-1-1-1z"
                                                transform="translate(-294 -250) translate(132 84) translate(24 124) translate(86.904 24) translate(52 18)"
                                                />
                                            </g>
                                            </g>
                                        </g>
                                        </g>
                                    </g>
                                    </g>
                                </g>
                                </svg>
                            </div>
                            <!-- Oval inner -->
                            </div>

                            <!-- Référence value -->
                            <div class="ref_value">
                            <span class="value">{{count}}</span>
                            <span class="nb">Nombres de recherches</span>
                        </div>
                        <!-- end reference value-->
                    </div>
                    <!-- rect -->
                    <!-- rect -->

                    <div class="rect rect_infos" style="min-height: 246px;">
                        <div class="rect_infos_header">
                            <span class="label">Utilisateurs de la plateforme</span>
                            <a href="/mon-compte?onglet=gestions" class="purple" v-if="user != null && user.roles.includes('ROLE_RESPONSIBLE')">Gérer la liste des utilisateurs</a>
                        </div>
                        <div class="rect_infos_content">
                            
                            <div class="list" v-for="usr in users" :key="usr.id">
                                <span class="title">{{usr.firstname}}</span>
                                <span class="title">{{usr.lastname}}</span>
                                <router-link :to="'/mon-compte?onglet=gestions&id='+usr.id" class="action" v-if="user != null && user.roles.includes('ROLE_RESPONSIBLE')">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
                                        <path fill="#222B45" fill-rule="evenodd" d="M10.68 7.12L8.881 5.321l1.3-1.299 1.795 1.796-1.298 1.3zm-4.627 4.63l-1.985.18.176-1.97L7.99 6.215l1.797 1.797-3.733 3.738zm6.883-6.858L11.11 3.065c-.494-.493-1.343-.516-1.81-.046L3.301 9.017c-.218.217-.352.505-.38.81l-.253 2.78c-.017.196.053.39.193.53.126.127.296.196.471.196.02 0 .04 0 .06-.002l2.78-.253c.306-.028.593-.162.81-.379l5.998-5.998c.485-.487.465-1.299-.045-1.81z" clip-rule="evenodd"/>
                                        <mask id="4l59mt4c5a" width="12" height="12" x="2" y="2" maskUnits="userSpaceOnUse">
                                            <path fill="#fff" fill-rule="evenodd" d="M10.68 7.12L8.881 5.321l1.3-1.299 1.795 1.796-1.298 1.3zm-4.627 4.63l-1.985.18.176-1.97L7.99 6.215l1.797 1.797-3.733 3.738zm6.883-6.858L11.11 3.065c-.494-.493-1.343-.516-1.81-.046L3.301 9.017c-.218.217-.352.505-.38.81l-.253 2.78c-.017.196.053.39.193.53.126.127.296.196.471.196.02 0 .04 0 .06-.002l2.78-.253c.306-.028.593-.162.81-.379l5.998-5.998c.485-.487.465-1.299-.045-1.81z" clip-rule="evenodd"/>
                                        </mask>
                                    </svg>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <!-- rect -->
                    <!-- rect -->

                    <div class="rect rect_infos" style="min-height: 246px;">
                        <div class="rect_infos_header">
                            <span class="label">Historique de prélèvement</span>
                            <a href="/mon-compte?onglet=facturation" class="purple">Gérer la facturation</a>
                        </div>
                        <div class="rect_infos_content">
                            
                            <div class="list" v-for="f in factures" :key="f.id">
                                <span class="title">{{f.created}}</span>
                                <span class="title">#{{f.number}}</span>
                                <span class="action">
                                    <span class="flex sp">
                                        <span class="label">PDF</span>
                                        <a :href="f.link" target="_blank" class="mh ms-2 cursor">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                <g fill="none" fill-rule="evenodd">
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <g transform="translate(-844 -334) translate(270 86) translate(24 132) translate(0 94) translate(550 22)">
                                                                        <path d="M0 0L16 0 16 16 0 16z"/>
                                                                        <path fill="#00C1E2" d="M3.905 1.333h5.07c.354 0 .697.126.968.355l2.859 2.418c.337.285.531.704.531 1.145v8.138c0 1.194-.013 1.278-1.238 1.278h-8.19c-1.225 0-1.238-.084-1.238-1.278V2.61c0-1.194.013-1.278 1.238-1.278z"/>
                                                                        <rect width="6" height="1.333" x="4" y="7.333" fill="#363660" rx=".667"/>
                                                                        <rect width="3.333" height="1.333" x="4" y="10" fill="#363660" rx=".667"/>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </a>
                                    </span>
                                    
                                </span>
                            </div>



                        </div>
                    </div>

                </div>
                <div class="main">
                    <div class="rect rect_infos ps-0 pe-0 pt-0 pb-0" style="max-height:703px;">
                        <img src="../assets/map.png" alt="MAP" width="100%" class="img-resp" style="min-height: 703px;">
                
                    </div>
                </div>
                <div class="main">
                    <div class="rect rect_infos" id="card" style="min-height: 705px;">
                        <h1 class="search-title" style="text-align:center;">Moyen de paiement</h1>
                        <h2 class="search-sub-title" style="text-align:center;">Saisissez vos informations de carte bancaire :</h2>
                        <div>
                            <div class="sub_info">
                                <h4>Abonnement valable jusqu’au {{period_end}}</h4>
                            </div>

                            <div class="card">
                                <div class="card-top">
                                
                                </div>
                                <div class="card-bottom">
                                    <span class="card-number">NUMÉRO DE CARTE</span>
                                    <span class="card-number-2">{{card_number}}</span>
                                    <span class="card-name">{{card_owner}}</span>
                                </div>
                                <div class="card-right">
                                    <span class="month">MOIS / ANNÉE</span>
                                    <div style="display:flex;">
                                        <span class="date">
                                            <span class="d" style="display:block;">DATE DE</span> 
                                            <span class="v" style="display:block;">VALIDÉE</span>
                                        </span>
                                        <span class="date_v">{{card_date}}</span>
                                    </div>
                                    
                                </div>
                            </div>

                            <div class="flex flex-raw">
                                <router-link type="button" to="/mon-compte?onglet=facturation" id="close_down" data-bs-dismiss="modal" class="btn btn-secondary cancel_btn" >Modifier les informations</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            card_number: "",
            users: [],
            factures: [],
            period_end: "",
            card_date: "",
            card_name: "",
            user: null,
            card_owner: "",
            count: 0,
        }
    },
    mounted() {
        let user = null
        if(localStorage.getItem('uitoken') != null) {
            user = JSON.parse(localStorage.getItem('uitoken'))
            this.user = user
        }
        this.fetchUsers()
        this.fetchCard()
        this.fetchFacturation()
        this.fetchCount()

    },
    methods: {
        
        fetchCard() {
            const self = this
            axios({
                method: 'GET',
                url: 'https://api.exval.wertkt.com/api/v1/card-infos',
                params: {
                    token: this.user.value
                },
                headers: {
                    'Authorization': this.user.access_token
                }
            }).then(function(response) {
                if(response.data.code == "200") {
                    self.card_number = response.data.data.card_number
                    self.card_date =  response.data.data.exp_date  
                    self.period_end = response.data.data.period_end      
                    self.card_owner = response.data.data.card_owner       
                }
            }).catch(function(err) {
            console.log(err)
            })

        },
        fetchUsers() {
            const self = this
            axios({
                method: 'GET',
                url: 'https://api.exval.wertkt.com/api/v1/users',
                params: {
                    token: this.user.value,
                    limit: 3,
                    page: 1
                },
                headers: {
                    'Authorization': this.user.access_token
                }
            }).then(function(response) {
                if(response.data.code == "200") {
                    self.users = response.data.data                
                }
            }).catch(function(err) {
            console.log(err)
            })
        },
        
        fetchFacturation() {
            const self = this
            axios({
                method: 'GET',
                url: 'https://api.exval.wertkt.com/api/v1/payment-history',
                params: {
                    token: this.user.value,
                    limit: 3,
                    page: 1
                },
                headers: {
                    'Authorization': this.user.access_token
                }
            }).then(function(response) {
                if(response.data.code == "200") {
                    self.factures = response.data.data               
                }
            }).catch(function(err) {
                console.log(err)
            })
        },
        
        fetchCount() {
            const self = this
            axios({
                method: 'GET',
                url: 'https://api.exval.wertkt.com/api/v1/bien/data-map/count',
                params: {
                    token: this.user.value,
                },
                headers: {
                    'Authorization': this.user.access_token
                }
            }).then(function(response) {
                self.count = response.data.count               
            }).catch(function(err) {
                console.log(err)
            })
        },
    }
}
</script>
<style>
.flex {
    padding: 0 20px;
}
.flex-center {
    justify-content: center;
}
.img-resp {
    object-fit: cover;
    height: 100%;
    width: 100%;
        max-height: 600px;
}
.main {
        min-width: 440px;
    width: 440px;
}
.rect_infos {
    min-width: auto;
    height: auto;
    margin: 24px 20px 0.3px auto;
    padding: 20px;
    border-radius: 8px;
    border: solid 1px #e6e6f4;
    background-color: #ffffff;
    width: 100%;
}
.rect_infos_header {
    display: flex;
}
.rect_infos_header .label {
      width: 220px;
  margin: 0 11px 9px 0;
  font-family: Prompt;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.09px;
  text-align: left;
  color: #363660;
}
.rect_infos_header .purple {
    width: 161px;
  margin: 2px 0 11px 11px;
  font-family: Prompt;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.24px;
  text-align: right;
  color: #00c1e2;
}
.rect_infos_content .list {
    min-height: 40px;
    height: auto;
  padding: 11.7px 16px 10.3px 19px;
  border-radius: 8px;
  border: solid 1px #e6e6f4;
  display: flex;
      justify-content: space-between;
      margin-top: 10px;
}
.rect_infos_content .list .title {
    width: 49.8px;
    height: 18px;
    margin: 0 62.7px 0 0;
    font-family: Prompt;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #383366;
    max-width: 180px;
}
.rect_infos_content .list .action {
    width: 60%;
    text-align: right;
}
.rect_infos_content .list .action svg{
        position: relative;
    top: -4px;
}
.flex.sp {
    padding: 0;
    justify-content: center;
    align-content: center;
}
#card .search-title {
        font-family: Prompt;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #363660;
}
#card .search-sub-title {
        font-family: Prompt;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.2px;
    color: #808097;
}
#card {
    position: relative;
    min-height: 600px;
}
#card .sub_info {
    margin: 40px 53px 24px 54px;
    padding: 6px 8px 7px;
    border-radius: 6px;
    background-color: #cffff2;
        min-width: 283px;
}
#card .sub_info h4{
    font-family: Prompt;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.11px;
  text-align: center;
  color: #20d1a1;
  margin: 0;
}
#card .card {
      height: 217px;
  margin: 0 0 15px;
  padding: 0 0 0 27px;
  border-radius: 12px;
}
#card .flex-raw {
flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    position: absolute;
    bottom: 13px;
    width: 100%;
    left: 0;
}
#card .flex-raw #close_down {
        height: 25px;
    margin: 0 5px 16px 4px;
    padding: 4px 24px;
    border-radius: 3px;
    background-color: #ffaa00;

    font-family: Prompt;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #373563;
    border: none;
}
#card .flex-raw .valid_btn {
        height: 25px;
    margin: 0 5px 16px 4px;
    padding: 4px 24px;
    border-radius: 3px;
    background-color: #00c1e2;
    
    font-family: Prompt;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #373563;
    border: none;
}
</style>