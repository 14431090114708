<template>
  <div id="modal-bien" class="modal-type-bien">
    <div v-for="option in options" :key="option.id">
      <CheckBox
        :val="option.value"
        :label="option.label"
        v-model="data"
        :flag="option.flag"
        :checked="option.checked"
        @input="input"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalTypeDeBien",
  props: {
    open: Boolean,
    options: Array
  },
  components: { CheckBox: require("./checkbox").default },
  data() {
    return {
      data: null,
    };
  },
  methods: {
    input(model) {
      this.$emit("onChange", model);  
    }
  }
};
</script>

<style scoped>
.modal-type-bien {
  padding: 13px 16px 14px;
  border-radius: 4px;
  background-color: #ffffff;
  display: block;
  position: absolute;
  z-index: 3;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
</style>