<template>
  <div class="map">
    <l-map 
      ref="map"
      :center="mapCenter"
      :zoom="zoom"
      map-type-id="terrain"
      :max-zoom="20"
    >
      <!--l-draw-toolbar ref="toolbar" position="topright" :options="draw" v-if="drawerEnable == true"/-->
      <!--l-tile-layer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"></l-tile-layer-->
      <v-tilelayer-googlemutant @hook:mounted="loadGoogle" ref="mutant" :apikey="apiKey" :options="options" ></v-tilelayer-googlemutant>
      <l-control-zoom position="bottomright"  ></l-control-zoom>
      <l-marker :icon="noIcon"  ref="special" :lat-lng="specialMarker" >
        <l-popup :content="infoContent"/>
      </l-marker>
      <l-polygon :lat-lngs="polyJson" color="#ff7f89" v-if="polyJson != []"></l-polygon>
      <v-marker-cluster :options="{}">
          <l-marker
          :key="index"
          v-for="(m, index) in markers"
          :lat-lng="m.position"
          @click="toggleInfoWindow(m,index)"
          :icon="icon"
          ref="marker" 
        >
        <l-popup ref="popup" :content="infoContent"/>
        </l-marker>
      </v-marker-cluster>
    </l-map>
    <div class="invisible">
      <div class="" id="info-window">
        <div class="card-content">
          <p> <strong>Adresse : </strong> <span id="mark_adresse"></span> </p>
          
          <p> <strong>Date de transaction : </strong> <span id="mark_date"></span> </p>
          
          <p> <strong>Surface : </strong> <span id="mark_surface"></span> </p>
          
          <p> <strong>Prix : </strong> <span id="mark_prix"></span> </p>
          
          <!--p> <strong>Loyer annuel : </strong> <span id="mark_loyer_annuel"></span> </p>
          
          <p> <strong>Loyer annuel par m²: </strong> <span id="mark_annuel_m"></span> </p-->
          <p> <strong>Prix par m²: </strong> <span id="mark_prix_m2"></span></p>

          <!--p> <strong>Date de construction: </strong> <span id="mark_constructiont"></span></p-->

          <p> <strong>Source : </strong> <span id="mark_source_container" class="tags"> <span id="mark_source"></span></span>  </p>
          
          <div id="panorama">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
          </div>
          <div id="not-found" style="text-align:center;display:none;" class="prompt">
            Aucune adresse trouvée dans Street view.
          </div>
        </div>
      </div>
    </div>
    <div class="options pl-2 pr-2" id="optionsM" v-if="oStat != true">
      <div class="flex ms-2 me-2">
        <span class="oval blue"></span>
        <span class="prompt">Transactions</span>
      </div>

      <div class="flex ms-2 me-2">
        <span class="oval orange"></span>
        <span class="prompt">Offres</span>
      </div>

      <div class="flex ms-2 me-2">
        <span class="oval green"></span>
        <span class="prompt">Adresse localisée</span>
      </div>
    </div>
    <div class="infos"></div>
    
  </div>
</template>

<script>
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import { LMap, LMarker,LPopup,
LPolygon ,
LControlZoom } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import L from "leaflet";

export default {
    name: "GoogleMap",
    components: {
      'l-map': LMap,
      'l-marker': LMarker,
      LPopup,
      LPolygon,
      "v-marker-cluster": Vue2LeafletMarkerCluster,
      LControlZoom
    },
    props: ['oStat','drawerEnable'],
    data() {
      return {
        //a default center for the map
        mapCenter: {lat: 48.856613, lng: 2.352222},
        specialMarker: {lat: 48.856613, lng: 2.352222},
        special : false,
        drawingManager: null,
        map: null,
        zoom: 7,
        apiKey: 'AIzaSyCqlAJEiiRBDAC1za-62wIg9enRTlx3lqY',
        options: {
          type: 'roadmap'
        },
        infoContent: '',
        infoWindowPos: {
          lat: 0,
          lng: 0
        },
        polygon: null,
        enableDrawer : false,
        geoJson: null,
        infoWinOpen: false,
        currentMidx: null,
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35
          }
        },
        markers: [],
        paths: null,
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        
        noIcon: L.icon({
          iconUrl: require('../../../assets/marker-violet.svg'),
          iconSize: [0, 0],
          iconAnchor: [20, 28], // point of the icon which will correspond to marker's location
          popupAnchor: [0, -58]
        }),
        icon: L.icon({
          iconUrl: require('../../../assets/marker-violet.svg'),
          iconSize: [48, 48],
          iconAnchor: [20, 28], // point of the icon which will correspond to marker's location
          popupAnchor: [0, -58]
        }),
        staticAnchor: [16, 37],
        customText: "Foobar",
        iconSize: 64,
        draw: {
          position: 'topright',
          draw: {
            polyline: {
              allowIntersection: false,
              showArea: true
            },
            polygon: false,
            rectangle: false,
            circle: false,
            marker: false
          }
        },
        polyJson : [[0,0],[0,0],[0,0],[0,0]] ,
        drawItems: null,
        editableLayers: null,
        drawControl: null,
        mapControl : null,
        roadMutant: null,
        satMutant: null,
        timer: null,
      };
    },
    mounted() {
      const latitude = this.$route.query.lat
      const longitude = this.$route.query.lng

      if(latitude != null || latitude != undefined) {
        this.mapCenter = L.latLng(parseFloat(latitude), parseFloat(longitude))
      }
    },
    methods: {
      loadGoogle() {
        const self = this

        setTimeout(function() {
          
          const adresse = document.getElementById('adresse');
          const options = {
            componentRestrictions: { country: "FR" },
            fields: ["formatted_address", "geometry", "name","address_components","place_id"],
            origin: this.mapCenter,
            strictBounds: false,
            //types: ["addresses"],
          };
          if(window.google != undefined && typeof window.google.maps === 'object') {
              const autocomplete = new window.google.maps.places.Autocomplete(adresse, options);
              //autocomplete.bindTo("bounds", map);
              
              window.google.maps.event.addListener(autocomplete, 'place_changed', function() {
                self.polyJson = [[0,0],[0,0],[0,0],[0,0]] 
                self.savePolygon(null)

                const place = autocomplete.getPlace().address_components;
                const plc = place
                let code = place.filter(function(item) {
                    return item.types.includes('postal_code')
                })
                let ville = place.filter(function(item) {
                  return item.types.includes('locality')
                })
                
                let adresse = autocomplete.getPlace().formatted_address

                const pos = autocomplete.getPlace().geometry.location;

                self.mapCenter = {
                  lat: pos.lat(),
                  lng : pos.lng()
                }
                self.$refs.map.mapObject.panTo(new L.LatLng(pos.lat(), pos.lng()))

                self.$emit('updateCenter', self.mapCenter)
                self.$emit('updatePoint', self.mapCenter)

                if(code.length == 0) {
                  const geocoder = new window.google.maps.Geocoder();
                  geocoder.geocode({ location: self.mapCenter }, (results, status) => {
                    if (status === "OK") {
                      if (results[0]) {
                        const stablePlace = results[0].address_components
                        code = stablePlace.filter(function(item) {
                          return item.types.includes('postal_code')
                        })
                        self.$refs.map.mapObject.setZoom(14)
                        
                        self.$emit('adressUpdate', {adresse: plc, location: {
                            code:  code.length > 0 ? code[0].long_name : '',
                            ville: ville.length > 0 ? ville[0].long_name : '',
                            adresse: adresse
                        }, pos: {
                          lat: pos.lat(),
                          lng : pos.lng()
                        }})
                      } 
                    } 
                  });
                } else {

                  self.$refs.map.mapObject.setZoom(14)
                  
                  self.$emit('adressUpdate', {location: {
                      code:  code.length > 0 ? code[0].long_name : '',
                      ville: ville.length > 0 ? ville[0].long_name : '',
                      adresse: adresse
                  }, pos: {
                    lat: pos.lat(),
                    lng : pos.lng()
                  }})
                }

              })
          }

        }, 3000)              
      },
      setMarker(marker) {

        const newMarker = marker.map(function(item) {
          let it = item
          const lat = item.position != undefined ? item.position.lat : item[0]
          const lng = item.position != undefined ? item.position.lng : item[1]
          const lt = parseFloat(lat)
          const lg = parseFloat(lng)
          it.position = {
            lat: lt,
            lng: lg
          }
          return it
        })

        this.markers = newMarker
        
      },
      getMarkers(index) {
        return this.markers[index]
      },
      getMarkRefs(index) {
        return this.$refs.marker[index]
      },
      getSpecialRefs() {
        return this.$refs.special
      },
      resize() {
        this.$refs.map.mapObject.invalidateSize()
      },
      updateCenterSpecial(position) {
        this.specialMarker = position
        this.$refs.map.mapObject.panTo(new L.latLng(position.lat, position.lng));
        this.$refs.map.mapObject.setZoom(15)
      },
      showSpecialMarker() {
        this.special = true
      },
      setPolygonFromAPI(polygon) {
        const ar = polygon.map(function(item) {
          return [item.lat, item.lng]
        })
        this.polyJson = ar
        const center = this.getCentroid()
        this.$refs.map.mapObject.panTo(new L.LatLng(center[0], center[1]))

        this.$emit('savePolyJson',ar)
        this.$emit('updateCenter', {
          lat: center[0],
          lng: center[1]
        })
        
      },
      getCentroid() { 
        const arr = this.polyJson
        return arr.reduce(function (x,y) {
            return [x[0] + y[0]/arr.length, x[1] + y[1]/arr.length] 
        }, [0,0]) 
      },
      updateCenter(position) {
        const { lat, lng } = position
        this.mapCenter = {
          lat: lat,
          lng: lng
        }
        
        const latitude = this.$route.query.lat

        if(latitude != null && this.polyJson != []) {
          this.$refs.map.mapObject.setZoom(16)
        } else {
          this.$refs.map.mapObject.setZoom(13)
        }
        this.$refs.map.setCenter([lat,lng])

      },
      updateCenterOnly(position, zoom = 13) {
        const { lat, lng } = position
        const self = this          
        self.specialMarker = {
          lat: lat,
          lng: lng
        }
        this.$refs.map.mapObject.setView(L.latLng(lat, lng), zoom);

      },
      updateZoom(zoom) {
        this.$refs.map.setZoom(zoom)
      },
      getCenter() {
        return this.mapCenter
      },
      getCenterCentroid() {
        if(this.polyJson != []) {
          const center = this.getCentroid()
          return {
            lat: center[0],
            lng: center[1]
          }
        } else {
          return []
        }
      },
      toggleInfoWindow: function (marker, idx) {

        this.marker = marker
        const { lat, lng } = marker.position

        this.infoWindowPos = {
          lat: parseFloat(lat),
          lng: parseFloat(lng)  
        };
        
        //document.getElementById('panorama').style.display = 'block';
        //document.getElementById('not-found').style.display = 'none';
        const sourceCls = marker.content['Source'] == "dvf" ? 'tag-blue' : 'tag-red';
        const infos = document.getElementById('info-window')
        document.getElementById('mark_adresse').innerHTML = marker.address
        document.getElementById('mark_surface').innerHTML = marker.content['Surface']
        document.getElementById('mark_date').innerHTML = marker.content['Date de transaction']
        document.getElementById('mark_prix').innerHTML = marker.content['Prix']
       // document.getElementById('mark_loyer_annuel').innerHTML = ""
       // document.getElementById('mark_annuel_m').innerHTML = ""
        document.getElementById('mark_prix_m2').innerHTML = marker.content['Prix au m²']
        //document.getElementById('mark_constructiont').innerHTML = ""
        document.getElementById('mark_source').innerHTML = marker.content['source']
        document.getElementById('mark_source_container').classList.add(sourceCls);
        this.infoContent = infos.innerHTML
        document.querySelector('.lds-ring').style.display = "inline-block"
        document.getElementById('panorama').style.background = "#fff"
        document.getElementById('panorama').innerHTML = "<div class='lds-ring'><div></div><div></div><div></div><div></div></div>"
        
        if(this.timer == null) {
          
          this.timer = setTimeout(function() {
              document.querySelector('.lds-ring').style.display = "none"
              
              const res =new window.google.maps.StreetViewPanorama(
                document.getElementById('panorama'),
                {
                  position: {lat,lng},
                  pov: { heading: 165, pitch: 0 },
                  zoom: 1,
                }
              );
              
              setTimeout(() => {              
                if(res.projection == undefined) {
                  document.getElementById('panorama').style.display = 'none';
                  document.getElementById('not-found').style.display = 'block';
                }
              }, 2000);
              
          }, 1000) 

        } else {
          clearTimeout(this.timer)
          
          this.timer = setTimeout(function() {
              document.querySelector('.lds-ring').style.display = "none"
              const res = new window.google.maps.StreetViewPanorama(
                document.getElementById('panorama'),
                {
                  position: {lat,lng},
                  pov: { heading: 165, pitch: 0 },
                  zoom: 1,
                }
              );
              
              setTimeout(() => {              
                if(res.projection == undefined) {
                  document.getElementById('panorama').style.display = 'none';
                  document.getElementById('not-found').style.display = 'block';
                }
              }, 2000);
              
          }, 1000) 
        }
        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        }
        //if different marker set infowindow to open and reset current marker index
        else {
          this.infoWinOpen = true;
          this.currentMidx = idx;
        }
      },
      arrayCompare(_arr1, _arr2) {
        if (
          !Array.isArray(_arr1)
          || !Array.isArray(_arr2)
          || _arr1.length !== _arr2.length
          ) {
            return false;
          }
        
        // .concat() to not mutate arguments
        const arr1 = _arr1.concat().sort();
        const arr2 = _arr2.concat().sort();
        
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
        
        return true;
      },
      loadMapDrawingManager() {    
        var drawnItems = new L.FeatureGroup();
        this.$refs.map.mapObject.addLayer(drawnItems);

        var polyLayers = [];
      
        var polygon1 = L.polygon(this.polyJson);
        polyLayers.push(polygon1)

        // Add the layers to the drawnItems feature group 
        for(let layer of polyLayers) {
          drawnItems.addLayer(layer);	
        }
        // Set the title to show on the polygon button
        L.drawLocal.draw.toolbar.buttons.polygon = 'Dessiner un polygon ! ';
        L.drawLocal.draw.toolbar.finish.title = 'Terminer'
        L.drawLocal.draw.toolbar.finish.text = 'Terminer'
        L.drawLocal.draw.toolbar.undo.text = 'Supprimer le dernier point'
        L.drawLocal.draw.toolbar.actions.text = 'Annuler (Echap)'
        L.drawLocal.edit.toolbar.actions.save.text = 'Sauvegarder'
        L.drawLocal.edit.toolbar.actions.cancel.text = 'Annuler'

        this.roadMutant = L.gridLayer
          .googleMutant({
            maxZoom: 20,
            type: "roadmap",
          }).addTo(this.$refs.map.mapObject);

        this.satMutant = L.gridLayer.googleMutant({
            maxZoom: 20,
            type: "satellite",
          });
          var grid = L.gridLayer({
            attribution: "Debug tilecoord grid",
          });

          grid.createTile = function (coords) {
            var tile = L.DomUtil.create("div", "tile-coords");
            tile.style.border = "1px solid black";
            tile.style.lineHeight = "256px";
            tile.style.textAlign = "center";
            tile.style.fontSize = "20px";
            tile.innerHTML = [coords.x, coords.y, coords.z].join(", ");

            return tile;
          };
         this.mapControl = L.control
            .layers(
              {
                Roadmap: this.roadMutant,
                Aerial: this.satMutant
              },
              {"Tilecoord grid": grid,},
              {
                collapsed: false,
              }
            )
            .setPosition('bottomright')
            .addTo(this.$refs.map.mapObject);
            
        this.drawControl = new L.Control.Draw({
          position: 'topright',
          draw: {
            polyline: false,
            polygon: {
              allowIntersection: false,
              showArea: true,
              drawError: {
                color: '#ff7f89',
                timeout: 1000
              },
              shapeOptions: {
                color: '#ff7f89'
              },
            },
            circle: false,
            marker: false
          },
          edit: {
            featureGroup: drawnItems,
            remove: false
          }
        });
        this.$refs.map.mapObject.addControl(this.drawControl);

        const self = this
        this.$refs.map.mapObject.on('draw:created', function (e) {
          const layer = e.layer;
          const type = e.layerType
          //self.clearPolygon()

          if (type === 'polygon') {
            // here you got the polygon points
            self.savePolygon(layer)
            // here you can get it in geojson format
            self.geoJson = layer.toGeoJSON();
            self.$refs.map.$forceUpdate()
            
            self.setPolygonFromAPI( self.polygon.editing.latlngs[0][0])
            const center = self.getCentroid()
            self.$emit('updateCenter', {
              lat: center[0],
              lng: center[1]
            })
          }
          drawnItems.addLayer(layer)
        });

        this.$refs.map.mapObject.on('draw:edited', function (e) {
          const layer = e.layers._layers
          const key = Object.keys(layer)[0]
          //self.clearPolygon()

          if(self.polyJson.length > 0) {
            self.polyJson = [[0,0],[0,0],[0,0],[0,0]]
          }
          self.savePolygon(layer[key])
          self.setPolygonFromAPI( self.polygon.editing.latlngs[0][0])
          // here you can get it in geojson format
          self.geoJson = layer[key].toGeoJSON();
         // self.polyJson = []
          drawnItems.addLayer(layer[key])
          const center = self.getCentroid()
          self.$emit('updateCenter', {
            lat: center[0],
            lng: center[1]
          })
        });
      },
      closeDrawingManager() {
        if(this.drawControl != null) {
          this.$refs.map.mapObject.removeControl(this.drawControl)
        }
        if(this.mapControl != null) {
          this.$refs.map.mapObject.removeControl(this.mapControl)
        }
        
        if(this.roadMutant != null) {
          this.$refs.map.mapObject.removeControl(this.roadMutant)
        }
        
        if(this.satMutant != null) {
          this.$refs.map.mapObject.removeControl(this.satMutant)
        }

        this.enableDrawer = false
      },
      savePolygon(paths) {
        this.polygon = paths
        
        if(paths != null ) {
          this.$emit('savePolygon', this.polygon.editing.latlngs[0][0])
        } else {
          this.$emit('savePolygon', null)
        }
      },
      clearPolygon() {
        for(let i in this.$refs.map.mapObject._layers) {
            if(this.$refs.map.mapObject._layers[i]._path != undefined) {
                try {
                    this.$refs.map.mapObject.removeLayer(this.$refs.map.mapObject._layers[i]);
                }
                catch(e) {
                    console.log("problem with " + e + this.$refs.map.mapObject._layers[i]);
                }
            }
        }
        if(this.polyJson.length > 0) {
          this.polyJson = [[0,0],[0,0],[0,0],[0,0]]
        }
        
        if(this.polygon != null ) {
          this.$refs.map.mapObject.removeLayer(this.polygon)

          this.$emit('clearPolygon')
        }
      },
      clearMarkers() {
        this.markers = []
      }
    }
  };
</script>

<style>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
.someExtraClass {
  background-color: aqua;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 0 20px 20px 20px;
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
}
.map .card-content p {
  margin-top: 0;
}
#panorama {
    display: flex;
    width: 100%;
    height: 160px;
    text-align: center;
    justify-content: center;
    align-items: center;
        margin-top: 9px;
}
.invisible {
  display: none;
}
.invisible #panorama {
  display: none;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
      text-align: center;
    margin-top: 17px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4b45b2 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
  display: none;
}
.leaflet-right .leaflet-control {
    position: relative;
    top: 10px;
    right: -3px;
}
.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
      border: none;
}
.leaflet-top {
      top: 40px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit {
    background-position: -144px 6px!important;
    width: 40px!important;
    height: 42px!important;
    line-height: 30px;
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
    text-align: center;
    text-decoration: none;
    background-color: #fff;
} 
.leaflet-draw-actions a {
    border-left: 1px solid #aaa;
    text-decoration: none;
    padding-left: 10px;
    padding-right: 10px;
    height: 28px;
    /* width: 117px; */
    height: 33px;
    margin: 0 auto;
     padding: 9px 16px 10px 16px!important;
    border-radius: 8px;
    background-color: #3d42df!important;

    font-family: Prompt!important;
    font-size: 10px!important;
    font-weight: 500!important;
    font-stretch: normal!important;
    font-style: normal!important;
    line-height: 1.3!important;
    letter-spacing: normal!important;
    text-align: right!important;
    color: #ffffff!important;
    /* visibility: hidden; */
    /* opacity: 0; */
    transition: visibility 0s, opacity 0.5s linear;
}
.leaflet-top.leaflet-left {
    left: 0;
    top: 2px!important;
}
.leaflet-draw-actions a {
border-radius: 0;
}
.leaflet-touch .leaflet-right .leaflet-draw-actions {
    right: 41px;
}
.leaflet-draw-actions-top.leaflet-draw-actions-bottom a  {
  height: auto;
  top: 0;
  right: 0;
}
.leaflet-touch .leaflet-draw-actions a {
      line-height: 26px!important;
    height: 41px!important;
    position: relative;
    right: 10px!important;
}
.leaflet-control-layers-separator {
  display: none;
}
.leaflet-control-layers-overlays label{
  display: none;
}
.leaflet-left .leaflet-control-zoom {
  display: none;
}
</style>
