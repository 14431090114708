<template>
    <div class="bar">
        <div class="bar_title"><div class="small">{{this.dataChart.title + ' ' + (suffix == undefined ? '' : suffix)}}</div><div><img src="chart/stockholm-icons-files-downloaded-file.png" srcset="chart/stockholm-icons-files-downloaded-file@2x.png 2x, chart/stockholm-icons-files-downloaded-file@3x.png 3x" class="Stockholm-icons-Files-Downloaded-file down-c cursor" slot-scope="" @click="download"></div></div>
        <div class="" style="width:100%;height:86%;">
            <canvas class="canv" :id="id"></canvas>
        </div>
        <div class="options pl-2 pr-2 char-options" v-if="string_to_slug(this.dataChart.title) == 'selon-la-date-de-transaction'">
            <div class="flex ms-2 me-2 flex-pad-0" v-for="legend in dataChart.legends" :key="legend.title">
                <span class="oval" :style="'background-color:' + legend.color"></span>
                <span class="prompt">{{legend.title}}</span>
            </div>
        </div>
    </div>
</template>
<style>
.bar {
    position: relative;
}
.char-options{
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: center;
}
.char-options .prompt {
    font-family: Prompt;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #363660;
}
.red {
    background-color: #e94a73;
}
.violet-2 {
    background-color: #363660;
}
.yellow {
    background-color: #f7ac25;
}
.sky {
    background-color: #4cbfde;
}
.char-options .oval {
    width: 5px!important;
    height: 5px!important;
    border: none!important;
}
.bar .flex-pad-0 {
    padding: 0 10px;
}
.bar .options .oval {
        margin: 4px 2px 3px 0;
}
.bar .oval {
        padding: 5px;
}
</style>
<script>
import Chart from 'chart.js';
import html2canvas from "html2canvas";
import moment from 'moment'
Chart.elements.Rectangle.prototype.draw = function () {
    var ctx = this._chart.ctx
    var vm = this._view
    var left, right, top, bottom, signX, signY, borderSkipped, radius
    var borderWidth = vm.borderWidth
    // If radius is less than 0 or is large enough to cause drawing errors a max
    //      radius is imposed. If cornerRadius is not defined set it to 0.
    var cornerRadius = this._chart.config.options.cornerRadius

    if (cornerRadius < 0) {
        cornerRadius = 0
    }
    if (typeof cornerRadius == 'undefined') {
        cornerRadius = 0
    }
    if (this._chart.config.type == "bar") {
        // bar
        left = vm.x - vm.width / 2
        right = vm.x + vm.width / 2
        top = vm.y
        bottom = vm.base
        signX = 1
        signY = bottom > top ? 1 : -1
        borderSkipped = vm.borderSkipped || 'bottom'
    } else {
        // horizontal bar
        left = vm.base
        right = vm.x
        top = vm.y - vm.height / 2
        bottom = vm.y + vm.height / 2
        signX = right > left ? 1 : -1
        signY = 1
        borderSkipped = vm.borderSkipped || 'left'
    }
    // Canvas doesn't allow us to stroke inside the width so we can
    // adjust the sizes to fit if we're setting a stroke on the line
    if (borderWidth) {
        // borderWidth shold be less than bar width and bar height.
        var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom))
        borderWidth = borderWidth > barSize ? barSize : borderWidth
        var halfStroke = borderWidth / 2
        // Adjust borderWidth when bar top position is near vm.base(zero).
        var borderLeft =
            left + (borderSkipped !== 'left' ? halfStroke * signX : 0)
        var borderRight =
            right + (borderSkipped !== 'right' ? -halfStroke * signX : 0)
        var borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0)
        var borderBottom =
            bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0)
        // not become a vertical line?
        if (borderLeft !== borderRight) {
            top = borderTop
            bottom = borderBottom
        }
        // not become a horizontal line?
        if (borderTop !== borderBottom) {
            left = borderLeft
            right = borderRight
        }
    }
    ctx.beginPath()
    ctx.fillStyle = vm.backgroundColor
    ctx.strokeStyle = vm.borderColor
    ctx.lineWidth = borderWidth
    // Corner points, from bottom-left to bottom-right clockwise
    // | 1 2 |
    // | 0 3 |
    var corners = [
        [left, bottom],
        [left, top],
        [right, top],
        [right, bottom],
    ]
    // Find first (starting) corner with fallback to 'bottom'
    var borders = ['bottom', 'left', 'top', 'right']
    var startCorner = borders.indexOf(borderSkipped, 0)
    if (startCorner === -1) {
        startCorner = 0
    }
    function cornerAt(index) {
        return corners[(startCorner + index) % 4]
    }
    // Draw rectangle from 'startCorner'
    var corner = cornerAt(0)
    ctx.moveTo(corner[0], corner[1])
    for (var i = 1; i < 4; i++) {
        corner = cornerAt(i)
        var nextCornerId = i + 1
        if (nextCornerId == 4) {
            nextCornerId = 0
    }
    var width = corners[2][0] - corners[1][0]
    var height = corners[0][1] - corners[1][1]
    var x = corners[1][0]
    var y = corners[1][1]
     radius = cornerRadius
    // Fix radius being too large
    if (radius > Math.abs(height) / 2) {
        radius = Math.floor(Math.abs(height) / 2)
    }
    if (radius > Math.abs(width) / 2) {
        radius = Math.floor(Math.abs(width) / 2)
    }
    if (height < 0) {
        // Negative values in a standard bar chart
        var x_tl = x
        var x_tr = x + width
        var y_tl = y + height
        var y_tr = y + height
        var x_bl = x
        var x_br = x + width
        var y_bl = y
        var y_br = y
        // Draw
        ctx.moveTo(x_bl + radius, y_bl)
        ctx.lineTo(x_br - radius, y_br)
        ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius)
        ctx.lineTo(x_tr, y_tr + radius)
        ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr)
        ctx.lineTo(x_tl + radius, y_tl)
        ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius)
        ctx.lineTo(x_bl, y_bl - radius)
        ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl)
    } else if (width < 0) {
        // Negative values in a horizontal bar chart
        x_tl = x + width
        x_tr = x
        y_tl = y
        y_tr = y
        x_bl = x + width
        x_br = x
        y_bl = y + height
        y_br = y + height
        // Draw
        ctx.moveTo(x_bl + radius, y_bl)
        ctx.lineTo(x_br - radius, y_br)
        ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius)
        ctx.lineTo(x_tr, y_tr + radius)
        ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr)
        ctx.lineTo(x_tl + radius, y_tl)
        ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius)
        ctx.lineTo(x_bl, y_bl - radius)
        ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl)
    } else {
        //Positive Value
        ctx.moveTo(x + radius, y)
        ctx.lineTo(x + width - radius, y)
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
        ctx.lineTo(x + width, y + height - radius)
        ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius,
        y + height
        )
        ctx.lineTo(x + radius, y + height)
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
        ctx.lineTo(x, y + radius)
        ctx.quadraticCurveTo(x, y, x + radius, y)
    }
    }
        ctx.fill()
    if (borderWidth) {
        ctx.stroke()
    }
}
export default {
    props: ["dataChart","double","id", "height","width",'suffix'],
    data() {
        return {
            rand: 0,
            chart: null,
            backgroundColor: ['#4CBFDE', '#E94A73','#F7AC25','#363660','#FF7F89','#3D42DF']
        }
    },
    methods: {
        formatNumber(num) {
            return num && num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
        },
        buildExportCanvasImage: function () {
            return new Promise((resolve) => {
                const div = document.createElement("div");
                const canvas = document.createElement("canvas");
                div.style.background = "white";
                div.style.width = "1024px";
                div.style.height = "768px";
                div.style.padding = "10px";
                div.appendChild(canvas);
                resolve({ div, canvas });
                document.getElementById('chartCont').appendChild(div);
            });
        },
        updateXAxisTicks: function ( datasets, name) {
            const bubble = this.chart;
            console.log(name)
            const ticks = bubble.scales["x-axis-0"].ticksAsNumbers;
            const stepSize = ticks[ticks.length - 1] - ticks[ticks.length - 2];
            bubble.config.options.scales.xAxes[0].ticks.stepSize = stepSize;
            let x = datasets.map((item) => item.x);
            x = Math.min.apply(null, x);
            if (x >= stepSize) {
            x = Math.floor(x / stepSize) * stepSize;
            }
            bubble.config.options.scales.xAxes[0].ticks.min = x;
            bubble.config.options.scales.xAxes[0].ticks.stepSize = stepSize;
            bubble.update();
        },
        saveChartAsImg: function (fontSize) {
            const self = this
            return new Promise((resolve) => {
                const downloadTarget = document.getElementById("target");
                this.buildExportCanvasImage().then(({ div, canvas }) => {
                    
                    const name = self.dataChart.name;
                    const { type } = self.dataChart;
                    canvas.width = 600;
                    canvas.height = 400;
                    if (self.dataChart.legends && self.dataChart.legends.length) {
                        canvas.height = 678;
                    }

                    const title = document.createElement("h1");
                    title.textContent = self.dataChart.title;
                    title.style.fontSize = "18px";
                    title.style.textAlign = "center";
                    div.insertBefore(title, div.querySelector("canvas"));

                    const ctx = canvas.getContext("2d");
                    if(self.dataChart.type == 'bubble') {
                        self.bubble(ctx)
                    } else if (self.dataChart.type == 'horizontalBar') {
                        self.segmentation(ctx)
                    } else if (self.dataChart.type == 'bar') {
                        self.linear(ctx)
                    }
                    console.log(fontSize)
                   // this.buildLegend(div, self.dataChart, fontSize);
                    if (type === "bubble") {
                        const d = self.dataChart.datasets
                       
                        self.updateXAxisTicks( d , name);
                    }
                    setTimeout(async () => {
                        const image2Canvas = await html2canvas(div, { logging: false });
                        const res = await fetch(image2Canvas.toDataURL());
                        const blob = await res.blob();
                        const date = new Date();
                        const url = URL.createObjectURL(blob);
                        downloadTarget.href = url;
                        let name = `chart_${self.dataChart.name}_id_${moment(
                            date
                        ).format("YYYYMMDD_hhmm_s")}.jpg`;
                
                        downloadTarget.download = name;
                        // downloadTarget.click();
                        resolve({ downloadTarget, url });
                        div.parentNode.removeChild(div)
                    }, 1000);
                });
            });
        },
        string_to_slug: function (str) {
            str = str.replace(/^\s+|\s+$/g, ''); // trim
            str = str.toLowerCase();
        
            // remove accents, swap ñ for n, etc
            var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
            var to   = "aaaaeeeeiiiioooouuuunc------";
            for (var i=0, l=from.length ; i<l ; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
            }

            str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                .replace(/\s+/g, '-') // collapse whitespace and replace by -
                .replace(/-+/g, '-'); // collapse dashes

            return str;
        },
        exportOnly: async function() {
            if(this.chart != null) {
                const { downloadTarget } = await this.saveChartAsImg(14);

                downloadTarget.click();
            }
        },
        exportChart: async function () {
            this.$emit('showLoader')
            const { downloadTarget } = await this.saveChartAsImg(14);
            this.$emit('hideLoader')
            this.$notify({
                group: 'foo',
                title: '',
                text: 'Exportation réussie',
                classes: 'elegant',
            });
            downloadTarget.click();
        },
        download() {
            if(this.chart != null) {
               this.exportChart() 
            }
        },
        bubble(ctx) {
            const {
                datasets,
                labels,
                backgroundColor,
                type,
                title,
                regressions,
                axisLabel,
                //tooltipInfo,
                typeAxis,
                xStep,
                min,
                max,
            } = this.dataChart;
            let bg = [];
            if(this.string_to_slug(this.dataChart.title) == 'selon-la-date-de-transaction') {
                bg = backgroundColor    
            } else if( this.string_to_slug(this.dataChart.title) == 'correlation-prix-m-surface')  {
                for(let i = 0; i < backgroundColor.length; i++) {
                    bg.push('#4CBFDE')
                }
            } else {
                for(let i = 0; i < backgroundColor.length; i++) {
                    bg.push('#E94A73')
                }
            }
            this.datasets = datasets
            const xData = datasets.map((item) => item.x);
            const x = Math.min.apply(null, xData);
            const xMax = Math.max.apply(null, xData);
            const xStepSize = Math.round(Math.round(xMax / 4) * 5) / 5;
            const self = this;
            this.chart = new Chart(ctx, {
                type,
                data: {
                    labels,
                    datasets: [{
                        data: datasets,
                        backgroundColor: bg,
                        ...{ radius: 3 },
                        borderWidth:0,
                   
                    },
                    {
                        type: "line",
                        data: regressions,
                        fill: false,
                        borderColor: "#ff9200",
                        borderWidth: 0,
                        pointRadius: 0,
                    },]
                },
                options: {
                    onClick: function(e, chart) {
                        
                        if (chart[0] == undefined) {
                            return;
                        }
                        const { _index } = chart[0];
                    
                        const { id } = self.dataChart.datasets[_index];
                        let i = e 
                        i = id
                        self.$emit('openPopup', i)

                        
                    },
                    legend: {
                        display: false
                    },
                    responsive: true,                     
                    maintainAspectRatio: false, 
                    title: {
                        display: false,
                        text: title
                    },
                    tooltips: {
                        enabled: true,
                        custom: function(tooltipItems) {
                            const idx = tooltipItems
                            if(idx.dataPoints !== undefined) {
                                self.$emit('hoverTiks', idx.dataPoints[0].index)
                            }
                            // Tooltip Element 'none';
                        },
                        callbacks: {
                            label: function (value) {
            
                                const yLabel = self.formatNumber(value.yLabel.toFixed(2));
                                if (type === "month") {
                                return (
                                    "Date " +
                                    moment.unix(value.xLabel).format("MM/YYYY") +
                                    ": " +
                                    yLabel +
                                    " €"
                                );
                                }
                                if (type === "date") {
                                return "Année " + value.xLabel + ": " + yLabel + " €";
                                }
                                if (type === "surface") {
                                return value.xLabel + " m²: " + yLabel + " €";
                                }
                                return value.xLabel + ": " + value.yLabel;
                            },
                        }
                    },
                    scales: {
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: axisLabel[1],
                                fontSize: 14,
                                fontColor: "#363660",
                                fontFamily:'Prompt',
                                ...{fontSize: 12},
                            },
                            ticks: {
                                beginAtZero: false,
                                fontSize: 14,
                                fontColor: "#363660",
                                fontFamily:'Prompt',
                                suggestedMin: min,
                                suggestedMax: max,
                                callback: function (value) {
                                    return value + " €";
                                },
                            ...{fontSize: 12}
                            },
                                gridLines: {
                                drawBorder: false,
                                color: "#f1f1f1",
                            },
                        }],
                    
                        xAxes: [{
                            scaleLabel: {
                            display: true,
                            fontSize: 14,
                            fontColor: "#363660",
                            fontFamily:'Prompt',
                            labelString: axisLabel[0],
                            ...{fontSize: 12}
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 24,
                                fontSize: 14,
                                fontColor: "#363660",
                                fontFamily:'Prompt',
                            ...(xStep
                                ? { stepSize: xStep }
                                : typeAxis === "surface"
                                ? {
                                    stepSize:
                                    xStepSize - 25 / 2 - ((xStepSize + 25 / 2) % 25),
                                }
                                : {}),
                            ...(typeAxis === "surface" ? { min: x } : {}),
                            ...{fontSize: 10},
                            beginAtZero: typeAxis === "surface",
                            callback: (value) => {
                                if (typeAxis === "time") {
                                return moment.unix(value).format("MM/YYYY");
                                }
                                if (typeAxis === "surface") {
                                return value + " m²";
                                }
                                return value;
                            },
                            },
                        }],
                    }
                }
            });
        },
        linear(ctx) {

            const {
                datasets,
                labels,
                type,
                axisLabel,
                backgroundColor,
                borderColor,
                min,
                max,
            } = this.dataChart;
            
            let bg =backgroundColor
            
            bg = this.backgroundColor
            this.chart =  new Chart(ctx, {
                type,
                
                data: {
                    labels,
                    datasets: [
                        {
                            data: datasets,
                            fill: false,
                            backgroundColor: bg,
                            borderColor,
                            lineTension: 0,
                            barThickness: 15,
                            borderWidth: 2,
                        },
                    ],
                },
                options: {
                    responsive: true,                     
                    maintainAspectRatio: false, 
                    cornerRadius: 10,
                    legend: {
                        display: false,
                    },
                    scales: {
                        yAxes: [
                            {
                                scaleLabel: {
                                        display: true,
                                        fontSize: 14,
                                        fontColor: "#363660",
                                        fontFamily:'Prompt',
                                        labelString: axisLabel[1],
                                        ...{fontSize: 12}
                                    },
                                    ticks: {
                                        beginAtZero: false,
                                        fontSize: 14,
                                        fontColor: "#363660",
                                        fontFamily:'Prompt' , 
                                        min,
                                        max,
                                        ...{fontSize: 12},
                                    callback: (value) => {
                                        return value;
                                    },
                                },
                            },
                        ],
                        xAxes: [
                        {
                            scaleLabel: {
                            display: true,
                            fontSize: 14,
                            fontColor: "#363660",
                            fontFamily:'Prompt',
                            labelString: axisLabel[0],
                                ...{fontSize: 12}

                            },
                            ticks: {
                            beginAtZero: true,
                            fontSize: 14,
                            fontColor: "#363660",
                            fontFamily:'Prompt',
                            ...{fontSize: 12}

                            /* callback: (value) => {
                                return moment.unix(value).format("MM/YYYY");
                            }, */
                            },
                        },
                        ],
                    },
                },
            });        
        },
        segmentation(ctx) {
            const {
                datasets,
                labels,
                backgroundColor,
                type,
                //title,
                regressions,
                axisLabel,
                //name,
            } = this.dataChart;
            
            let bg =backgroundColor
            
            bg = this.backgroundColor

            this.chart =  new Chart(ctx, {
                
                type,
                borderRadius: 2,

                data: {
                    labels,
                    datasets: [
                        {
                            data: datasets,
                            backgroundColor: bg,
                            categoryPercentage: 0.4,
                            barThickness: 15,

                        },
                        {
                            type: "line",
                            data: regressions,
                            fill: false,
                            borderColor: "#ff9200",
                            borderWidth: 1,
                            pointRadius: 0,
                            barThickness: 10,

                        },
                    ],
                },
                options: {
                    responsive: true,                     
                    maintainAspectRatio: false, 
                 
                    cornerRadius: 20,
                    legend: {
                        display: false,
                    },
                    scales: {
                        yAxes: [
                        {
                            scaleLabel: {
                            display: true,
                            fontSize: 14,
                            fontColor: "#363660",
                            fontFamily:'Prompt',
                            labelString: axisLabel[1],
                            ...{fontSize: 12},
                            },
                            ticks: {
                            beginAtZero: true,
                            fontSize: 14,
                            fontColor: "#363660",
                            fontFamily:'Prompt',
                            ...{fontSize: 12},
                            },
                        },
                        ],
                        xAxes: [
                        {
                            scaleLabel: {
                            display: true,
                            fontSize: 14,
                            fontColor: "#363660",
                            fontFamily:'Prompt',
                            labelString: axisLabel[0],
                            ...{fontSize: 12},
                            },
                            ticks: {
                            beginAtZero: true,
                            fontSize: 14,
                            fontColor: "#363660",
                            fontFamily:'Prompt',
                            ...{fontSize: 12},
                            },
                            afterFit: function(me) {
                                me.paddingLeft = 0;
                                me.paddingRight = 0;
                            }
                        },
                        ],
                    },
                },
            });
        },
        lineChart(ctx) {
            const {
                datasets,
                labels,
                type,
                axisLabel,
                backgroundColor,
                borderColor,
                min,
                max,
            } = this.dataChart;
            return new Chart(ctx, {
                type,
                borderRadius: 2,
                data: {
                labels,
                datasets: [
                    {
                    data: datasets,
                    fill: false,
                    backgroundColor,
                    borderColor,
                    lineTension: 0,
                    },
                ],
                },
                options: {
                    legend: {
                        display: false,
                    },

                    scales: {
                        yAxes: [
                        {
                            scaleLabel: {
                            display: true,
                            fontSize: 12,
                            fontColor: "#757491",
                            labelString: axisLabel[1],
                            ...{fontSize: 12},

                            },
                            ticks: {
                            beginAtZero: false,
                            fontSize: 12,
                            min,
                            max,
                                ...{fontSize: 12},
                            callback: (value) => {
                                return value + " €";
                            },
                            },
                        },
                        ],
                        xAxes: [
                        {
                            scaleLabel: {
                            display: true,
                            fontSize: 12,
                            fontColor: "#757491",
                            labelString: axisLabel[0],
                                ...{fontSize: 12},

                            },
                            barThickness: 13,
                            ticks: {
                            beginAtZero: true,
                            fontSize: 12,
                                ...{fontSize: 12},
                            },
                        },
                        ],
                    },
                    borderRadius: 4
                },
            });
        }
    },
    mounted() {
        const self = this
        this.rand = Math.floor(Math.random() * 100);
        
        const canvas =  document.getElementById(self.id) 
        const ctx = canvas.getContext('2d');
        if(self.dataChart.type == 'bubble') {
            self.bubble(ctx)
        } else if (self.dataChart.type == 'horizontalBar') {
            self.segmentation(ctx)
        } else if (self.dataChart.type == 'bar') {
            self.linear(ctx)
        } else if(self.dataChart.type == "line") {
            self.lineChart(ctx)
        }
    
    }
}
</script>