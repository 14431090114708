<template>
  <div>
    <div class="bg-copy">
        <div class="bg cursor">
        
          <span @click="openSearch" id="oSearch">
            <!-- bg -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fill-rule="evenodd">
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          d="M0 0H24V24H0z"
                          transform="translate(-64 -110) translate(40 86) translate(20 20) translate(4 4)"
                        />
                        <path
                          fill="#00C1E2"
                          d="M14.293 16.707c-.39-.39-.39-1.024 0-1.414.39-.39 1.024-.39 1.414 0l4 4c.39.39.39 1.024 0 1.414-.39.39-1.024.39-1.414 0l-4-4z"
                          transform="translate(-64 -110) translate(40 86) translate(20 20) translate(4 4)"
                        />
                        <path
                          fill="#363660"
                          d="M11 16c2.761 0 5-2.239 5-5s-2.239-5-5-5-5 2.239-5 5 2.239 5 5 5zm0 2c-3.866 0-7-3.134-7-7s3.134-7 7-7 7 3.134 7 7-3.134 7-7 7z"
                          transform="translate(-64 -110) translate(40 86) translate(20 20) translate(4 4)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </div>
        <div class="bg cursor" id="oMap" @click="openMap">
            
          <!-- bg -->
          <span data-tooltip="Consultez la map" class="toolTip cursor">
            <!-- bg -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fill-rule="evenodd">
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          d="M0 0H24V24H0z"
                          transform="translate(-64 -150) translate(40 86) translate(20 60) translate(4 4)"
                        />
                        <path
                          fill="#363660"
                          d="M12 3c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm2.47 4.273c-.107-.006-.214.022-.305.082l-4.554 3.007c-.132.087-.215.23-.224.388l-.327 5.448c-.017.276.193.512.47.529.107.006.214-.022.305-.082l4.554-3.007c.132-.087.215-.23.224-.388l.327-5.448c.017-.276-.193-.512-.47-.529z"
                          transform="translate(-64 -150) translate(40 86) translate(20 60) translate(4 4)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <!-- bg -->
        </div>
        <div class="bg cursor" id="oAnalyse" @click="openAnalyse">
                    <!-- bg -->
          <span >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fill-rule="evenodd">
                <g>
                  <g>
                    <g>
                      <g
                        transform="translate(-64 -190) translate(40 86) translate(20 100) translate(4 4)"
                      >
                        <path d="M0 0H24V24H0z" />
                        <rect
                          width="3"
                          height="13"
                          x="17"
                          y="4"
                          fill="#00C1E2"
                          rx="1.5"
                        />
                        <rect
                          width="3"
                          height="8"
                          x="12"
                          y="9"
                          fill="#00C1E2"
                          rx="1.5"
                        />
                        <path
                          fill="#363660"
                          d="M5 19h15c.552 0 1 .448 1 1s-.448 1-1 1H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1s1 .448 1 1v15z"
                        />
                        <rect
                          width="3"
                          height="6"
                          x="7"
                          y="11"
                          fill="#00C1E2"
                          rx="1.5"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <!-- bg -->
  
        </div>
        <div class="bg cursor" id="oInteraction" @click="openInteraction" >
                    <!-- bg -->
          <span class="cursor">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fill-rule="evenodd">
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          d="M0 0L24 0 24 24 0 24z"
                          transform="translate(-64 -230) translate(40 86) translate(20 140) translate(4 4)"
                        />
                        <path
                          fill="#00C1E2"
                          d="M18.5 16c1.38 0 2.5 1.12 2.5 2.5S19.88 21 18.5 21 16 19.88 16 18.5s1.12-2.5 2.5-2.5zm-13 0C6.88 16 8 17.12 8 18.5S6.88 21 5.5 21 3 19.88 3 18.5 4.12 16 5.5 16zm13-13C19.88 3 21 4.12 21 5.5S19.88 8 18.5 8 16 6.88 16 5.5 17.12 3 18.5 3z"
                          transform="translate(-64 -230) translate(40 86) translate(20 140) translate(4 4)"
                        />
                        <path
                          fill="#363660"
                          d="M13 18c.552 0 1 .448 1 1s-.448 1-1 1h-2c-.552 0-1-.448-1-1s.448-1 1-1h2zm-8-8c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1s-1-.448-1-1v-2c0-.552.448-1 1-1zm14 0c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1s-1-.448-1-1v-2c0-.552.448-1 1-1zM5.5 3C6.88 3 8 4.12 8 5.5S6.88 8 5.5 8 3 6.88 3 5.5 4.12 3 5.5 3zM13 4c.552 0 1 .448 1 1s-.448 1-1 1h-2c-.552 0-1-.448-1-1s.448-1 1-1h2z"
                          transform="translate(-64 -230) translate(40 86) translate(20 140) translate(4 4)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <!-- bg -->
  
        </div>
        <div class="bg cursor" id="oSauvegarde" @click="openSauvegarde">
            
          <!-- bg -->
         
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fill-rule="evenodd">
                <g>
                  <g>
                    <g>
                      <g
                        transform="translate(-64 -270) translate(40 86) translate(20 180) translate(4 4)"
                      >
                        <path d="M0 0L24 0 24 24 0 24z" />
                        <path
                          fill="#00C1E2"
                          d="M17 4H6c-1.209 0-2 .7-2 2v12c0 1.3.791 2 2 2h12c1.2 0 2-.7 2-2V7.207c0-.133-.053-.26-.146-.353L17 4zm0 7H7V4h10v7z"
                        />
                        <rect
                          width="3"
                          height="5"
                          x="12"
                          y="4"
                          fill="#363660"
                          rx=".5"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
        </div>
          <!-- bg -->
          <!-- bg -->
          <div class="bg cursor" id="oDownload" @click="openDownload">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fill-rule="evenodd">
                <g>
                  <g>
                    <g>
                      <g
                        transform="translate(-64 -310) translate(40 86) translate(20 220) translate(4 4)"
                      >
                        <path d="M0 0H24V24H0z" />
                        <path
                          fill="#363660"
                          d="M2 13c0-.5.5-1 1-1s1 .5 1 1v5c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2v-5c0-.552.448-1 1-1s1 .448 1 1v5c0 2.21-1.79 4-4 4H6c-2.21 0-4-1.79-4-4v-5z"
                        />
                        <rect
                          width="2"
                          height="14"
                          x="11"
                          y="1"
                          fill="#00C1E2"
                          rx="1"
                          transform="rotate(-180 12 8)"
                        />
                        <path
                          fill="#00C1E2"
                          d="M7.707 15.707c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.024 0-1.414l5-5c.376-.376.98-.392 1.376-.036l5 4.5c.41.37.444 1.001.074 1.412-.37.41-1.001.444-1.412.074l-4.295-3.865-4.329 4.33z"
                          transform="rotate(-180 12 12.5)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <!-- bg -->
        </div>

        <!-- bg-last-child -->
        <div class="last-child">
          <span class="ref" id="ref">0</span><br />
          <span class="rfrences ">référence(s)</span>
        </div>
  </div>
</template>

<script>
export default {
  props: {
    down: Boolean,
    save: Boolean,
    menuClass: String
  },
  methods: {
    openSauvegarde() {
      this.$emit('sauvegarde')
    },
    openDownload() {
      this.$emit('download')
    },
    openSearch() {
      this.$emit('openSearch')
    },
    openMap() {
      this.$emit('openMap')
    },
    openAnalyse() {
      this.$emit('openAnalyse')
    },
    openInteraction() {
      this.$emit('openInteraction')
    }
  }
} 
</script>